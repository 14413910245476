import React, { useRef } from "react";
import "./recomended.css";
import Slider from "react-slick";
import RecommendedItem from "./RecommendedItem";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FcNext, FcPrevious } from "react-icons/fc";

const Recomended = ({ similarProducts }) => {
  const { i18n } = useTranslation();
  const currentLang = i18n.language;
  const isArabic = currentLang === "ar";
  var settings = {
    infinite: true,
    speed: 700,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const sliderRef = useRef(null);

  const next = () => {
    sliderRef.current.slickNext();
  };

  const prev = () => {
    sliderRef.current.slickPrev();
  };
  return (
    <div className="slider-container mb-3" dir={isArabic ? "rtl" : "ltr"}>
      {similarProducts.length > 3 ? (
        <div>
          <Slider ref={sliderRef} {...settings}>
            {similarProducts.length > 0 ? (
              similarProducts.map((product) => (
                <RecommendedItem key={product?.slug} product={product} />
              ))
            ) : (
              <p>loading ....</p>
            )}
          </Slider>
          <div className="text-center d-flex align-items-center justify-content-center gap-3">
            {isArabic ? (
              <FcNext
                onClick={next}
                style={{ cursor: "pointer", fontSize: "25px" }}
              />
            ) : (
              <FcPrevious
                onClick={prev}
                style={{ cursor: "pointer", fontSize: "25px" }}
              />
            )}
            {isArabic ? (
              <FcPrevious
                onClick={prev}
                style={{ cursor: "pointer", fontSize: "25px" }}
              />
            ) : (
              <FcNext
                onClick={next}
                style={{ cursor: "pointer", fontSize: "25px" }}
              />
            )}
          </div>
        </div>
      ) : (
        <Row>
          {similarProducts.map((product) => (
            <Col xs={12} md={6} lg={6} xl={4} xxl={4} key={product?.slug}>
              <RecommendedItem key={product?.slug} product={product} />
            </Col>
          ))}
        </Row>
      )}
    </div>
  );
};

export default Recomended;
