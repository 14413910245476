import axios from "axios";
import React from "react";
import { FaSearch } from "react-icons/fa";

const SearchBar = ({ inputSearch, setInputSearch, setResults }) => {
  const fetchData = async (searchValue) => {
    const { data } = await axios.get(
      `https://api.paymenthub.me/api/enduser/products?search=${searchValue}`
    );
    setResults(data.items);
  };
  const handleSearch = (value) => {
    setInputSearch(value);
    if (value.length >= 3) {
      fetchData(value);
    } else {
      setResults([]);
    }
  };
  return (
    <div className="input-wrapper">
      <FaSearch />
      <input
        type="text"
        placeholder="Type To Search..."
        value={inputSearch}
        onChange={(e) => handleSearch(e.target.value)}
      />
    </div>
  );
};

export default SearchBar;
