import React, { useState } from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const Notifications = () => {
  const { t } = useTranslation();
  // const token = localStorage.getItem("paymentHub_token");
  // const [isLoading, setIsLoading] = useState(true);
  const [isLoading1] = useState(false);
  const [check, setCheck] = useState(true);
  const [check1, setCheck1] = useState(false);

  return (
    <div className="user-transactions">
      <Container fluid>
        <h1 className="mb-5">{t("Notification Settings")}</h1>
        <Row>
          <Col lg={11}>
            <div className="notify-section mb-4 d-flex justify-content-between align-items-center">
              <div className="notify-section-details">
                <label>{t("Email Subscriptions")}</label>
                <p
                  style={{
                    color: "#B5B5C3",
                    fontSize: "14px",
                    fontWeight: "bold",
                    textTransform: "capitalize",
                    letterSpacing: "1px",
                  }}
                >
                  Send me updates to info346@gmail.com
                </p>
              </div>
              <div className="form-switch">
                <input
                  type="checkbox"
                  id="custom-switch"
                  className="form-check-input"
                  checked={check}
                  onChange={() => setCheck(!check)}
                />
              </div>
            </div>
            <hr className="my-4" />
          </Col>
          <Col lg={11}>
            <div className="notify-section d-flex justify-content-between align-items-center">
              <div className="notify-section-details">
                <label>{t("Browser Notifications")}</label>
                <p
                  style={{
                    color: "#B5B5C3",
                    fontSize: "14px",
                    fontWeight: "bold",
                    textTransform: "capitalize",
                    letterSpacing: "1px",
                  }}
                >
                  Push notifications to my account{" "}
                </p>
              </div>
              <div className="form-switch">
                <input
                  type="checkbox"
                  id="custom-switch"
                  className="form-check-input"
                  checked={check1}
                  onChange={() => setCheck1(!check1)}
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12} lg={11}>
            <hr />
            <div className="security-btns">
              <button className="signin-btn discard" type="reset">
                {t("Discard")}
              </button>
              <button
                className="signin-btn"
                type="submit"
                // disabled={isLoading}
                // onClick={handleSubmit}
              >
                {isLoading1 ? (
                  <Spinner animation="border" role="status"></Spinner>
                ) : (
                  t("Save Changes")
                )}
              </button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Notifications;
