import React, { useEffect, useState } from "react";
import password_icon from "../../assets/Password.png";
import hidden_icon from "../../assets/hidden_icon.svg";
import visible_icon from "../../assets/visible_icon.svg";
import email_icon from "../../assets/email.png";
import { AuthLogic } from "../../utils/api";
import FormsHeading from "../FormsHeading/FormsHeading";
import { Col, Container, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const NewPassword = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  // store token from url
  const [urlToken, setUrlToken] = useState("");
  // state to store user email
  const [email, setEmail] = useState("");
  // state to toggle password and confirm password
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  // state to store password and confirm password
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  // toggle password function
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  // toggle confirm password function
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const [passwordErrors, setPasswordErrors] = useState({});
  const handleResetPassword = async (e) => {
    e.preventDefault();
    let formData = {
      token: urlToken,
      email: email,
      password: password,
      password_confirmation: confirmPassword,
    };
    try {
      await AuthLogic("reset-password", formData);
      setEmail("");
      setPassword("");
      setConfirmPassword("");
      setPasswordErrors({});
      setTimeout(() => {
        navigate("/successPassword");
      }, 1500);
    } catch (error) {
      setPasswordErrors(error.response.data.errors);
    }
  };
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const myQueryParam = params.get("token");
    setUrlToken(myQueryParam);
  }, [location]);
  return (
    <Container>
      <Row>
        <Col sm={0} md={2} lg={3} xl={3} xxl={4}></Col>
        <Col sm={12} md={8} lg={6} xl={4} xxl={4}>
          <div
            className="signin-form"
            dir={localStorage.getItem("i18nextLng") === "ar" ? "rtl" : "ltr"}
          >
            <FormsHeading
              heading={t("New Password")}
              subHeading={t(
                "Please enter a strong password, then click Confirm."
              )}
            />
            <form onSubmit={handleResetPassword}>
              <div className="input-group mb-3">
                <label htmlFor="email">{t("Email")}</label>
                <div className="form-input">
                  <img src={email_icon} alt="email_icon" />
                  <input
                    autoSave="false"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required={true}
                    id="email"
                    type="email"
                    placeholder={t("Enter Your email")}
                  />
                </div>
              </div>
              <div className="input-group mb-3">
                <label htmlFor="password">{t("Password")}</label>
                <div
                  className="form-input"
                  style={
                    passwordErrors?.password
                      ? { border: "2px solid #EB0000" }
                      : {}
                  }
                >
                  <img src={password_icon} alt="password_icon" />
                  <input
                    style={passwordErrors?.password ? { color: "#EB0000" } : {}}
                    required={true}
                    id="password"
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder={t("Enter Your Password")}
                  />
                  <img
                    onClick={togglePasswordVisibility}
                    className="pass_hidden"
                    src={showPassword ? visible_icon : hidden_icon}
                    alt="hidden_icon"
                  />
                </div>
              </div>
              {passwordErrors?.password ? (
                <div className="alert alert-danger mt-2">
                  {passwordErrors?.password.map((error, index) => (
                    <ul>
                      <li style={{ color: "#EB0000" }} key={index}>
                        {error}
                      </li>
                    </ul>
                  ))}
                </div>
              ) : (
                ""
              )}
              <div className="input-group mb-3">
                <label htmlFor="confirmPassword">{t("Confirm Password")}</label>
                <div className="form-input">
                  <img src={password_icon} alt="password_icon" />
                  <input
                    required={true}
                    id="confirmPassword"
                    type={showConfirmPassword ? "text" : "password"}
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    placeholder={t("Retype Your Password")}
                  />
                  <img
                    onClick={toggleConfirmPasswordVisibility}
                    className="pass_hidden"
                    src={showConfirmPassword ? visible_icon : hidden_icon}
                    alt="hidden_icon"
                  />
                </div>
              </div>
              <button className="signin-btn mb-4" type="submit">
                {t("Confirm")}
              </button>
            </form>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default NewPassword;
