import React, { useState } from "react";
import FormsHeading from "../FormsHeading/FormsHeading";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import phone from "../../assets/phone.png";
import { IoClose } from "react-icons/io5";
import { sendUserOtp, updateUserPhone } from "../../utils/api";

const ChangePhone = ({ handleClose, handleShow2 }) => {
  const { t } = useTranslation();
  const [newPhone, setNewPhone] = useState("");
  const [setResult] = useState([]);
  const [successMsg, setSuccessMsg] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const sendOtp = async () => {
    try {
      await sendUserOtp({ phone: newPhone });
      setSuccessMsg("verification code sent to your phone");
      setTimeout(() => {
        handleClose();
        handleShow2();
      }, [1500]);
    } catch (error) {
      setErrMsg(error.response.data.error);
      setSuccessMsg("");
    }
  };
  const updatePhone = async () => {
    try {
      const response = await updateUserPhone("change-phone", {
        phone: newPhone,
      });

      setResult(response);
      setErrMsg("");
      sendOtp();
    } catch (error) {
      setErrMsg(error.response.data.message);
      setSuccessMsg("");
    }
  };
  return (
    <Container>
      <div className="signin-form">
        <span className="modal-close-btn" onClick={handleClose}>
          <IoClose />
        </span>
        <FormsHeading
          heading={t("Change Phone Number")}
          subHeading={t(
            "Enter the mobile number to receive the verification code, then press Send Code"
          )}
        />
        {successMsg ? (
          <div className="alert alert-success text-center">{successMsg}</div>
        ) : (
          ""
        )}
        <form
          onSubmit={(e) => {
            e.preventDefault();
            updatePhone();
          }}
        >
          <div className="input-group mb-2">
            <div
              className="form-input"
              style={
                errMsg
                  ? { border: "2px solid #FF2D2D" }
                  : { border: "2px solid #898990" }
              }
            >
              <img className="mx-2" src={phone} alt="phone" />
              <input
                style={errMsg ? { color: "#FF2D2D" } : { color: "#898990" }}
                value={newPhone}
                onChange={(e) => {
                  setNewPhone(e.target.value);
                  setErrMsg("");
                }}
                required={true}
                id="tel"
                type="text"
                placeholder={t("Enter Your Phone Number")}
              />
            </div>
            <span className="text-danger mt-2">{errMsg}</span>
          </div>
          <button className="signin-btn mb-4" type="submit">
            {t("Send Code")}
          </button>
        </form>
      </div>
    </Container>
  );
};

export default ChangePhone;
