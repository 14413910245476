import React, { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Heading from "../Heading/Heading";
import ReviewItem from "./ReviewItem";
import { useParams } from "react-router-dom";
import { getProductReviews } from "../../utils/api";
import { FaPlus } from "react-icons/fa";
import big_star from "../../assets/recomended/big_star.png";
import "./reviewsSection.css";
import AddReview from "../Modals/AddReview";
import Rating from "react-rating";
import { FaStar } from "react-icons/fa";
import { CiStar } from "react-icons/ci";

const ReviewsSection = ({ productDetails }) => {
  const [show, setShow] = useState(false);
  const [reload, setReload] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { t } = useTranslation();
  const { productId } = useParams();
  const [productReviews, setProductReviews] = useState([]);
  const [productRating, setProductRating] = useState({});
  let currentLanguage = localStorage.getItem("i18nextLng");
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  // const [per, setPer] = useState(0);

  const getReviews = async (productId, page) => {
    const response = await getProductReviews(productId, currentLanguage, page);
    setProductReviews(response.data.items);
    setProductRating(response.data);
    setTotal(response.data.total);
  };

  // handle view more
  const handleViewMore = async (page) => {
    setPage((prevPage) => prevPage + 1);
    getReviews(productId, page);
    const response = await getProductReviews(productId, currentLanguage, page);
    setProductReviews((prevItems) => [...prevItems, ...response.data.items]);
  };

  useEffect(() => {
    getReviews(productId, page);
  }, [productId, reload, page]);

  return (
    <div className="reviews-section">
      <Heading title={t("Reviews")} />
      <Row>
        <Col md={12} lg={10}>
          <div className="reviews-heading-section d-flex">
            <div className="rating-count">
              <img src={big_star} alt="big_star" />
              <div className="rating-count-info">
                <h3 className="text-center">
                  {parseFloat(productRating?.average_rating).toFixed(1)}{" "}
                  {t("Rating")}
                </h3>
                <p className="mb-0 text-center">
                  {t("Based on")} {productRating?.reviews_count} {t("reviews")}
                </p>
              </div>
            </div>
            <div className="rating-stars">
              <div className="stars">
                <span>
                  <Rating
                    className="rating"
                    initialRating={5}
                    readonly
                    emptySymbol={<CiStar className="empty-star" />}
                    fullSymbol={<FaStar className="fill-star" />}
                    direction={
                      localStorage.getItem("i18nextLng") === "ar"
                        ? "rtl"
                        : "ltr"
                    }
                  />
                </span>
                <span>({productRating?.five_star})</span>
                <div className="rating-progress-section">
                  <span
                    style={{
                      width: `${productRating?.five_star * 10}%`,
                      left:
                        localStorage.getItem("i18next") === "en" || "en-US"
                          ? "0px"
                          : "",
                      right:
                        localStorage.getItem("i18next") === "ar" ? "" : "0px",
                    }}
                    className="rating-progress"
                  ></span>
                </div>
              </div>
              <div className="stars">
                <span>
                  <Rating
                    className="rating"
                    initialRating={4}
                    readonly
                    emptySymbol={<CiStar className="empty-star" />}
                    fullSymbol={<FaStar className="fill-star" />}
                    direction={
                      localStorage.getItem("i18nextLng") === "ar"
                        ? "rtl"
                        : "ltr"
                    }
                  />
                </span>
                <span>({productRating?.four_star})</span>
                <div className="rating-progress-section">
                  <span
                    style={{
                      maxWidth: `100%`,
                      width: `${productRating?.four_star * 10}%`,
                      left:
                        localStorage.getItem("i18next") === "en" || "en-US"
                          ? "0px"
                          : "",
                      right:
                        localStorage.getItem("i18next") === "ar" ? "" : "0px",
                    }}
                    className="rating-progress"
                  ></span>
                </div>
              </div>
              <div className="stars">
                <span>
                  <Rating
                    className="rating"
                    initialRating={3}
                    readonly
                    emptySymbol={<CiStar className="empty-star" />}
                    fullSymbol={<FaStar className="fill-star" />}
                    direction={
                      localStorage.getItem("i18nextLng") === "ar"
                        ? "rtl"
                        : "ltr"
                    }
                  />
                </span>
                <span>({productRating?.three_star})</span>
                <div className="rating-progress-section">
                  <span
                    style={{
                      width: `${productRating?.three_star * 10}%`,
                      left:
                        localStorage.getItem("i18next") === "en" || "en-US"
                          ? "0px"
                          : "",
                      right:
                        localStorage.getItem("i18next") === "ar" ? "" : "0px",
                    }}
                    className="rating-progress"
                  ></span>
                </div>
              </div>
              <div className="stars">
                <span>
                  <Rating
                    className="rating"
                    initialRating={2}
                    readonly
                    emptySymbol={<CiStar className="empty-star" />}
                    fullSymbol={<FaStar className="fill-star" />}
                    direction={
                      localStorage.getItem("i18nextLng") === "ar"
                        ? "rtl"
                        : "ltr"
                    }
                  />
                </span>
                <span>({productRating?.two_star})</span>
                <div className="rating-progress-section">
                  <span
                    style={{
                      width: `${productRating?.two_star * 10}%`,
                      left:
                        localStorage.getItem("i18next") === "en" || "en-US"
                          ? "0px"
                          : "",
                      right:
                        localStorage.getItem("i18next") === "ar" ? "" : "0px",
                    }}
                    className="rating-progress"
                  ></span>
                </div>
              </div>
              <div className="stars">
                <span>
                  <Rating
                    className="rating"
                    initialRating={1}
                    readonly
                    emptySymbol={<CiStar className="empty-star" />}
                    fullSymbol={<FaStar className="fill-star" />}
                    direction={
                      localStorage.getItem("i18nextLng") === "ar"
                        ? "rtl"
                        : "ltr"
                    }
                  />
                </span>
                <span>({productRating?.one_star})</span>
                <div className="rating-progress-section">
                  <span
                    style={{
                      width: `${productRating?.one_star * 10}%`,
                      left:
                        localStorage.getItem("i18next") === "en" || "en-US"
                          ? "0px"
                          : "",
                      right:
                        localStorage.getItem("i18next") === "ar" ? "" : "0px",
                    }}
                    className="rating-progress"
                  ></span>
                </div>
              </div>
            </div>
          </div>
        </Col>
        {productReviews.length > 0
          ? productReviews.map((review) => (
              <ReviewItem
                key={review.id}
                review={review}
                setReload={setReload}
                reload={reload}
              />
            ))
          : ""}
        <Col lg={8}>
          <div className="add-review">
            {productReviews.length < total ? (
              <span onClick={() => handleViewMore(page)} className="read">
                {t("Read All Reviews")}
              </span>
            ) : null}
            {!productDetails?.has_review ? (
              <span className="add" onClick={handleShow}>
                <FaPlus />
                {t("Add Review")}
              </span>
            ) : null}

            <Modal show={show}>
              <AddReview
                handleClose={handleClose}
                setReload={setReload}
                reload={reload}
              />
            </Modal>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ReviewsSection;
