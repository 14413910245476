import React from "react";
import check from "../../assets/check.png";
import { IoClose } from "react-icons/io5";

const TicketSuccess = ({ handleClose }) => {
  return (
    <div className="signin-form text-center">
      <span
        className="modal-close-btn"
        onClick={() => {
          handleClose();
        }}
      >
        <IoClose />
      </span>
      <img className="check" src={check} alt="check" />
      <p className="success-text">
        Your Ticket Has Been <br /> Successfully Submitted!
      </p>
    </div>
  );
};

export default TicketSuccess;
