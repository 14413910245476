import React from "react";
import { Row } from "react-bootstrap";
import "./popularGmaes.css";
import MerchantGameItem from "../MerchantGameItem/MerchantGameItem";
import { useTranslation } from "react-i18next";

const PopularGmaes = ({ popularProducts }) => {
  const { i18n } = useTranslation();
  const currentLang = i18n.language;
  const isArabic = currentLang === "ar";
  return (
    <Row className="mb-3" dir={isArabic ? "rtl" : "ltr"}>
      {popularProducts?.map((item, index) => (
        <MerchantGameItem key={index} lgWidth={4} item={item} />
      ))}
    </Row>
  );
};

export default PopularGmaes;
