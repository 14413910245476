import React from "react";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ element: Component, ...rest }) => {
  const userToken = localStorage.getItem("paymentHub_token");
  const userId = localStorage.getItem("user_id");

  return userToken && userId ? (
    <Component {...rest} />
  ) : (
    <Navigate to="/signin" />
  );
};

export default ProtectedRoute;
