import star from "../assets/recomended/star.png";
import empty_star from "../assets/recomended/empty_star.png";
import { formatDistanceToNow } from "date-fns";

// get date function
export function getDateOnly(dateString) {
  const dateObject = new Date(dateString);
  const year = dateObject.getFullYear();
  const month = String(dateObject.getMonth() + 1).padStart(2, "0"); // Add leading zero if needed
  const day = String(dateObject.getDate()).padStart(2, "0"); // Add leading zero if needed
  return `${year}-${month}-${day}`;
}
// get short date
export function getDate(dateStringAPI) {
  const dateString = dateStringAPI;
  const dateObject = new Date(dateString);

  // Formatting the date as "Nov 01, 2020"
  const formattedDate = dateObject.toLocaleDateString("en-US", {
    month: "short", // Short month name (e.g., "Nov")
    day: "2-digit", // Two-digit day of the month (e.g., "01")
    year: "numeric", // Full year (e.g., "2020")
  });
  return formattedDate;
}

// get stars function
export function getStars(counter) {
  const stars = [];
  for (let index = 0; index < counter; index++) {
    stars.push(<img key={index} src={star} alt="star" />);
  }
  return stars;
}
export function getemptyStars(counter) {
  const emptyStars = [];
  for (let index = 0; index < 5 - counter; index++) {
    emptyStars.push(<img key={index} src={empty_star} alt="star" />);
  }
  return emptyStars;
}

export function getStarsProduct(rating) {
  const filledStarsCount = Math.floor(rating); // Number of filled stars
  const emptyStarsCount = 5 - filledStarsCount; // Number of empty stars
  const fractionalPart = rating - filledStarsCount; // Fractional part of the rating

  const filledStars = Array.from(
    { length: filledStarsCount },
    (_, index) => index + 1
  ); // Array of filled star indices
  const emptyStars = Array.from(
    { length: emptyStarsCount },
    (_, index) => index + 1
  ); // Array of empty star indices

  return { filledStars, emptyStars, fractionalPart };
}

// make phone number and email last 3 digits as ***
export const maskString = (str, strLength) => {
  if (str.length <= 3) {
    return str;
  }
  const maskedLength = str.length - strLength;
  return "*".repeat(maskedLength) + str.slice(maskedLength);
};

/**
 * Formats a given date string into "MMM DD, YYYY HH:MM AM/PM" format.
 *
 * @param {string} dateStringAPI - The date string to format.
 * @returns {string} - The formatted date and time string.
 */
export function getDateAndTime(dateStringAPI) {
  const dateObject = new Date(dateStringAPI);

  // Formatting the date as "MMM DD, YYYY"
  const formattedDate = dateObject.toLocaleDateString("en-US", {
    month: "short", // Short month name (e.g., "Nov")
    day: "2-digit", // Two-digit day of the month (e.g., "01")
    year: "numeric", // Full year (e.g., "2020")
  });

  // Formatting the time as "HH:MM AM/PM"
  const formattedTime = dateObject.toLocaleTimeString("en-US", {
    hour: "2-digit", // Two-digit hour (e.g., "08")
    minute: "2-digit", // Two-digit minute (e.g., "00")
    hour12: true, // 12-hour time format with AM/PM
  });

  return `${formattedDate} ${formattedTime}`;
}

/**
 * Converts a date string to a relative time format.
 *
 * @param {string} dateString - The date string to convert.
 * @returns {string} - The formatted relative time string.
 */
export function formatDateToRelativeTime(dateString) {
  const date = new Date(dateString);
  return formatDistanceToNow(date);
}
