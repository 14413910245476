import React, { useEffect, useState } from "react";
import "./productDetails.css";
import product from "../../assets/prdocut.png";
import { Col, Container, Nav, Row, Tab } from "react-bootstrap";
import PlayerModal from "../../Components/PlayerModal/PlayerModal";
import { FaHeart, FaRegHeart } from "react-icons/fa";
import Heading from "../../Components/Heading/Heading";
import Recomended from "../../Components/Recomended/Recomended";
import {
  addToWishlist,
  getProducts,
  getSimilarProducts,
} from "../../utils/api";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ReviewsSection from "../../Components/Reviews/ReviewsSection";
import LoaderSpinner from "../../Components/LoaderSpinner/LoaderSpinner";
import { FaStar } from "react-icons/fa";
import { CiStar } from "react-icons/ci";
import Rating from "react-rating";

const ProductDetails = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  const isArabic = currentLang === "ar";
  const params = useParams();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // get product details & similar products
  const [productDetails, setProductDetails] = useState({});
  const [similarProducts, setSimilarProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const userToken = localStorage.getItem("paymentHub_token");
  const storedCurrency = JSON.parse(localStorage.getItem("selectedCurrency"));
  const currency = storedCurrency ? storedCurrency.symbol : "$";
  const currencyCode = storedCurrency ? storedCurrency.code : "USD";
  let currentLanguage = localStorage.getItem("i18nextLng");

  const getProductsDetails = async (prodName) => {
    try {
      const { data } = await getProducts(
        prodName,
        currencyCode,
        currentLanguage
      );
      setProductDetails(data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };
  const getSimilarProductsFun = async (prodName) => {
    try {
      const { data } = await getSimilarProducts(
        prodName,
        currencyCode,
        currentLanguage,
        userToken
      );
      setSimilarProducts(data);
    } catch (error) {}
  };
  const wishlistFun = async (productId) => {
    try {
      if (userToken) {
        await addToWishlist(productId, userToken);
        // Optimistically update the wishlist state
        setProductDetails((prevDetails) => ({
          ...prevDetails,
          wishlisted_by_user: !prevDetails.wishlisted_by_user,
        }));
      }
    } catch (error) {}
  };

  // get similar products
  useEffect(() => {
    getProductsDetails(params.productId);
    getSimilarProductsFun(params.productId);
  }, [params.productId]);
  return (
    <Container
      fluid
      dir={isArabic ? "rtl" : "ltr"}
      style={{ marginTop: "100px" }}
    >
      <div className="product-details">
        {isLoading ? (
          <LoaderSpinner />
        ) : (
          <>
            <Row className="mb-3">
              <Col lg={5}>
                <div className="productDetails-img">
                  <img
                    className="w-100"
                    src={product ? productDetails?.image : product}
                    alt="product"
                  />
                  {productDetails.wishlisted_by_user ? (
                    <FaHeart
                      className="heart"
                      color="#D21813"
                      onClick={() => wishlistFun(params.productId)}
                    />
                  ) : (
                    <FaRegHeart
                      className="heart"
                      onClick={() => wishlistFun(params.productId)}
                    />
                  )}
                </div>
              </Col>
              <Col lg={7}>
                <div className="merchant-heading h-100 d-flex flex-column justify-content-evenly">
                  <div className="heading">
                    <h1>{productDetails?.name}</h1>
                    <div className="price d-flex flex-row gap-2">
                      <span>{t("Price")} :</span>
                      <div className="old-new">
                        <div>
                          <span
                            style={
                              productDetails?.discount !== 0
                                ? {
                                    textDecoration: "line-through",
                                    color: "#1C2A53",
                                  }
                                : { color: "#1C2A53" }
                            }
                          >
                            {parseFloat(productDetails?.price).toFixed(2)}{" "}
                            {currency}
                          </span>
                          <br />
                          {productDetails.discount !== 0 ? (
                            <span className="new-price">
                              {productDetails.discount_type !== 0
                                ? (
                                    (productDetails?.price *
                                      (100 - productDetails?.discount)) /
                                    100
                                  ).toFixed(2)
                                : (
                                    productDetails?.price -
                                    productDetails?.discount
                                  ).toFixed(2)}{" "}
                              {currency}
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="reviews-heading-section mobile-view d-flex gap-3 align-items-center">
                    <div className="rating-stars w-auto">
                      <div className="stars mb-0">
                        <span>
                          <Rating
                            className="rating"
                            initialRating={productDetails?.reviews_average}
                            readonly
                            emptySymbol={<CiStar className="empty-star" />}
                            fullSymbol={<FaStar className="fill-star" />}
                            direction={
                              localStorage.getItem("i18nextLng") === "ar"
                                ? "rtl"
                                : "ltr"
                            }
                          />
                        </span>
                      </div>
                    </div>
                    <div className="rating-count p-0">
                      <div className="rating-count-info">
                        <h3
                          className="text-center mb-0"
                          style={{ fontSize: "16px" }}
                        >
                          {parseFloat(productDetails?.reviews_average).toFixed(
                            1
                          )}{" "}
                          {t("Rating")}
                        </h3>
                        <p className="mb-0" style={{ fontSize: "16px" }}></p>
                      </div>
                    </div>
                  </div>
                  <p>{(productDetails?.description).slice(0, 450)}...</p>

                  <div className="merchant-actions-buttons">
                    <div className="product-merchant">
                      <span>{t("Sold By")} : </span>
                      <span className="name">
                        <img
                          width={35}
                          height={35}
                          className="border rounded-circle mx-2"
                          src={productDetails?.merchant?.logo}
                          alt="logo"
                        />
                        <Link to={`/merchant/${productDetails?.merchant?.id}`}>
                          {productDetails?.merchant?.name}
                        </Link>
                      </span>
                    </div>
                    <div className="merchant-actions">
                      <button
                        onClick={() => {
                          if (userToken) {
                            if (productDetails?.player_id === null) {
                              handleShow();
                            } else {
                              navigate(`/${productDetails?.slug}/partners`);
                            }
                          } else {
                            handleShow();
                          }
                        }}
                      >
                        {t("Buy Now")}
                      </button>
                    </div>
                  </div>
                  <PlayerModal
                    show={show}
                    handleClose={handleClose}
                    item={productDetails}
                  />
                </div>
              </Col>
            </Row>

            <Tab.Container id="left-tabs-example" defaultActiveKey="second">
              <Row>
                <Col sm={12}>
                  <div className="tabs">
                    <Nav variant="pills" className="flex-column">
                      <Nav.Item>
                        <Nav.Link eventKey="second">
                          {t("Terms of use")}
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={12} lg={10}>
                  <Tab.Content>
                    <Tab.Pane eventKey="second">
                      <p>{productDetails?.terms}</p>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
            <ReviewsSection productDetails={productDetails} />
            <Heading title={t("Similar Products")} />
            <Recomended similarProducts={similarProducts} />
          </>
        )}
      </div>
    </Container>
  );
};

export default ProductDetails;
