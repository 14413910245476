import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import router from "./router";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "bootstrap/dist/css/bootstrap.min.css";
// Import Swiper styles
import { ToastContainer } from 'react-toastify';
import "swiper/css";
import "swiper/css/pagination";
import "./i18n";
import  { useEffect } from 'react';
import { messaging, getToken, onMessage,onMessageListner} from './firebase';
import "./index.css";
import 'react-toastify/dist/ReactToastify.css';
import { toast } from "react-toastify"; // Import toast for notification pop-up


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<RouterProvider router={router} />);

  if ('serviceWorker' in navigator) {
    navigator.serviceWorker
      .register('/firebase-messaging-sw.js', {
        scope: "/firebase-cloud-messaging-push-scope",
      })
      .then(function (registration) {
        console.log('Service Worker registered with scope:', registration.scope);
      })
      .catch(function (err) {
        console.log('Service Worker registration failed:', err);
      });
  }
  // App.js


function App() {
  toast.info("This is a test notification to check if Toast works", {
    position: "bottom-right",
    autoClose: 5000,
  });
  useEffect(() => {
    // Request permission and get FCM token
    // getToken(messaging, { vapidKey: 'BCLchPmyK9pCpuoN4GdWJsbZ3ZdfXGpDDC3GU-w-0AF6VTVFEXfwZpFXxWBOIGkBsFpyd7xqBoXAPxHyPgrq_G0' })
    //   .then((currentToken) => {
    //     if (currentToken) {
    //       console.log('FCM Token:', currentToken);
    //     } else {
    //       console.log('No FCM token available');
    //     }
    //   })
    //   .catch((err) => {
    //     console.log('Error getting token:', err);
    //   });

    // Listen for foreground notifications
    // onMessageListner()
    onMessage(messaging, (payload) => {
      console.log('Message received from index. ', payload);
   
    });
  }, []);
  return (
    <div className="App">
      {/* Ensure the ToastContainer is in your app */}
      <ToastContainer />


      {/* Your other components */}
    </div>
  );

}

export default App;
