import React, { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import "./merchantPage.css";
import MerchantHeading from "../../Components/MerchantHeading/MerchantHeading";
import { useParams } from "react-router-dom";
import { getMerchantDetails, getMerchantsProducts } from "../../utils/api";
// import { t } from "i18next";
import LoaderSpinner from "../../Components/LoaderSpinner/LoaderSpinner";
import PaginationControls from "../../Components/PaginationControls";
import { useTranslation } from "react-i18next";
import MerchantGameItem from "../../Components/MerchantGameItem/MerchantGameItem";

const MerchantPage = () => {
  const userToken = localStorage.getItem("paymentHub_token");
  const { i18n } = useTranslation();
  const currentLang = i18n.language;
  const isArabic = currentLang === "ar";
  const param = useParams();
  const [merchantDetails, setMerchantDetails] = useState([]);
  const [merchantProducts, setMerchantProducts] = useState([]);
  const [playerId, setPlayerId] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [pagination, setPagination] = useState({
    from: 1,
    to: 5,
    per: 10,
    total: 5,
    current: 1,
  });
  const storedCurrency = JSON.parse(localStorage.getItem("selectedCurrency"));
  const currencyCode = storedCurrency ? storedCurrency.code : "USD";
  const getMerchantProductsFun = async (merchantId, page, token) => {
    try {
      const { data } = await getMerchantsProducts(
        merchantId,
        page,
        token,
        currencyCode
      );
      setMerchantProducts(data.items);
      setIsLoading(false);
      setPagination({
        from: data.from,
        to: data.to,
        per: data.per,
        total: data.total,
        current: data.current,
      });
    } catch (error) {
      setIsLoading(false);
    }
  };
  const getMerchantDetailsFun = async (merchantId) => {
    try {
      const { data } = await getMerchantDetails(merchantId);
      setMerchantDetails(data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getMerchantProductsFun(param.merchantName, pagination.current, userToken);
    getMerchantDetailsFun(param.merchantName);
  }, [param.merchantName, pagination.current]);
  const handlePageChange = (newPage) => {
    getMerchantProductsFun(param.merchantName, newPage, userToken);
  };
  return (
    <Container fluid>
      {isLoading ? (
        <LoaderSpinner />
      ) : (
        <div className="mb-5" dir={isArabic ? "rtl" : "ltr"}>
          <MerchantHeading
            merchantDetails={merchantDetails}
            merchantName={param.merchantName}
            playerId={playerId}
            setPlayerId={setPlayerId}
          />
          <Row className="mb-3">
            {merchantProducts.map((item, index) => (
              <MerchantGameItem
                key={index}
                item={item}
                pagination={pagination}
                playerId={playerId}
                setPlayerId={setPlayerId}
              />
            ))}
          </Row>
          {/* ) : (
            <h2 className="text-centerd-block" style={{ height: "20vh" }}>
              {t("No Products For This Merchant")}
            </h2>
          )} */}
          <PaginationControls
            pagination={pagination}
            onPageChange={handlePageChange}
          />
        </div>
      )}
    </Container>
  );
};

export default MerchantPage;
