import React, { useRef } from "react";
import num_img1 from "../../assets/top_ten/1.png";
import num_img2 from "../../assets/top_ten/2.png";
import num_img3 from "../../assets/top_ten/3.png";
import num_img4 from "../../assets/top_ten/4.png";
import num_img5 from "../../assets/top_ten/5.png";
import num_img6 from "../../assets/top_ten/6.png";
import num_img7 from "../../assets/top_ten/7.png";
import num_img8 from "../../assets/top_ten/8.png";
import num_img9 from "../../assets/top_ten/9.png";
import num_img10 from "../../assets/top_ten/10.svg";
import "./topSales.css";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FcNext, FcPrevious } from "react-icons/fc";

const TopSales = ({ topSales }) => {
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const currentLang = i18n.language;
  const isArabic = currentLang === "ar";
  const storedCurrency = JSON.parse(localStorage.getItem("selectedCurrency"));
  const currency = storedCurrency ? storedCurrency.symbol : "$";
  const arrNumbers = [
    num_img1,
    num_img2,
    num_img3,
    num_img4,
    num_img5,
    num_img6,
    num_img7,
    num_img8,
    num_img9,
    num_img10,
  ];
  var settings = {
    infinite: true,
    speed: 700,
    slidesToShow: 5,
    slidesToScroll: 5,
    initialSlide: 0,
    autoplay: true,

    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 375,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const sliderRef = useRef(null);

  const next = () => {
    sliderRef.current.slickNext();
  };

  const prev = () => {
    sliderRef.current.slickPrev();
  };
  return (
    <div className="slider-container mb-3" dir={isArabic ? "rtl" : "ltr"}>
      {topSales.length > 4 ? (
        <div>
          <Slider ref={sliderRef} {...settings}>
            {topSales.map((item, index) => (
              <div key={index} className="top-sale">
                <img
                  style={{ width: "20%", height: "60px" }}
                  src={arrNumbers[index]}
                  alt="num_img1"
                />
                <div
                  style={{ width: "80%" }}
                  className="top-game"
                  onClick={() => navigate(`/productDetails/${item.slug}`)}
                >
                  <img
                    style={{ width: "100%", height: "100%" }}
                    src={item.image}
                    alt="game_1"
                  />
                  <div className="overlay" style={{ cursor: "pointer" }}>
                    <div className="overlay-2">
                      {item.discount !== 0 ? (
                        <div>
                          <p>UP TO</p>
                          <div className="discount">
                            <span className="discount-text">
                              {(item.discount * 1).toFixed(0)}
                              {item?.discount_type === 1 ? "%" : "$"}
                            </span>
                            <span className="discount-off">OFF</span>
                          </div>
                        </div>
                      ) : (
                        <>
                          <p className="text-center">
                            {item?.name.toUpperCase()}
                          </p>
                          <p className="text-center">
                            {parseFloat(item?.price).toFixed(2)} {currency}
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
          <div className="text-center d-flex align-items-center justify-content-center gap-3">
            {isArabic ? (
              <FcNext
                onClick={next}
                style={{ cursor: "pointer", fontSize: "25px" }}
              />
            ) : (
              <FcPrevious
                onClick={prev}
                style={{ cursor: "pointer", fontSize: "25px" }}
              />
            )}
            {isArabic ? (
              <FcPrevious
                onClick={prev}
                style={{ cursor: "pointer", fontSize: "25px" }}
              />
            ) : (
              <FcNext
                onClick={next}
                style={{ cursor: "pointer", fontSize: "25px" }}
              />
            )}
          </div>
        </div>
      ) : (
        <Row>
          {topSales.map((item, index) => (
            <Col xs={12} md={4} lg={3} xl={2} xxl={2} key={index}>
              <div className="top-sale">
                <img src={arrNumbers[index]} alt="num_img1" />
                <div
                  className="top-game"
                  onClick={() => navigate(`/productDetails/${item.slug}`)}
                >
                  <img
                    style={{ width: "100%", height: "100%" }}
                    src={item.image}
                    alt="game_1"
                  />
                  <div className="overlay" style={{ cursor: "pointer" }}>
                    <p className="text-center">{item?.name}</p>
                    <p className="game-price">
                      {item?.price} {currency}
                    </p>
                    <div className="overlay-2">
                      {item.discount !== "0.00" ? (
                        <>
                          <p>UP TO</p>
                          <div className="discount">
                            <span className="discount-text">
                              {(item.discount * 1).toFixed(0)}
                              {item?.discount_type === 1 ? "%" : currency}
                            </span>
                            <span className="discount-off">OFF</span>
                          </div>
                        </>
                      ) : (
                        <p className="text-danger fw-bold">No Discount</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      )}
    </div>
  );
};

export default TopSales;
