import React from "react";
import check from "../../assets/check.png";
import { IoClose } from "react-icons/io5";

const UpdateProfileSuccess = ({ handleClose3, setIsLoading, isLoading }) => {
  return (
    <div className="signin-form text-center">
      <span
        className="modal-close-btn"
        onClick={() => {
          handleClose3();
          setIsLoading(!isLoading);
        }}
      >
        <IoClose />
      </span>
      <img className="check" src={check} alt="check" />
      <p className="success-text">
        Profile has been updated <br />
        successfully
      </p>
    </div>
  );
};

export default UpdateProfileSuccess;
