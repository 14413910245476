import React, { useState } from "react";
import "./merchantGameItem.css";
import { Col, Row } from "react-bootstrap";
import { FaHeart, FaRegHeart } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import PlayerModal from "../PlayerModal/PlayerModal";
import { useTranslation } from "react-i18next";
import { addToWishlist } from "../../utils/api";
import Rating from "react-rating";
import { FaStar } from "react-icons/fa";
import { CiStar } from "react-icons/ci";

const MerchantGameItem = ({ lgWidth, item, xxlWidth }) => {
  const navigate = useNavigate();
  const [wishlisted, setWishlisted] = useState(item.wishlisted_by_user);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  const isArabic = currentLang === "ar";
  const userToken = localStorage.getItem("paymentHub_token");
  const storedCurrency = JSON.parse(localStorage.getItem("selectedCurrency"));
  const currency = storedCurrency ? storedCurrency.symbol : "$";
  const wishlistFun = async (productId) => {
    try {
      if (userToken) {
        await addToWishlist(productId, userToken);
        setWishlisted(!wishlisted);
      }
    } catch (error) {
      console.error("Error adding to wishlist:", error);
    }
  };

  return (
    <Col
      xs={12}
      md={6}
      lg={lgWidth}
      xl={4}
      xxl={xxlWidth ? 4 : 3}
      dir={isArabic ? "rtl" : "ltr"}
    >
      <div
        style={{ backgroundImage: `url(${item?.image})` }}
        className="popular-game merchant-game"
      >
        {wishlisted ? (
          <FaHeart
            className="heart"
            color="#D21813"
            style={isArabic ? { left: "10px" } : { right: "10px" }}
            onClick={() => wishlistFun(item.slug)}
          />
        ) : (
          <FaRegHeart
            style={isArabic ? { left: "10px" } : { right: "10px" }}
            className="heart"
            onClick={() => wishlistFun(item.slug)}
          />
        )}
        <div className="overlay">
          <Row className="w-100">
            <Col xs={12} md={12} lg={12}>
              <div className="star mb-3">
                <Link to={`/productDetails/${item?.slug}`}>{item?.name}</Link>
                <div className="d-flex flex-column">
                  <span
                    style={
                      item?.discount !== 0
                        ? {
                            textDecoration: "line-through",
                          }
                        : {}
                    }
                  >
                    {parseFloat(item?.price).toFixed(1)} {currency}
                  </span>
                  {item.discount !== 0 ? (
                    <span className="new-price">
                      {item.discount_type !== 0
                        ? (
                            (item?.price * (100 - item?.discount)) /
                            100
                          ).toFixed(1)
                        : (item?.price - item?.discount).toFixed(1)}{" "}
                      {currency}
                    </span>
                  ) : null}
                </div>
              </div>
              <Rating
                className="product-rating mb-3"
                initialRating={item?.reviews_average}
                readonly
                emptySymbol={<CiStar className="empty-star" />}
                fullSymbol={<FaStar className="fill-star" />}
                direction={isArabic ? "rtl" : "ltr"}
              />
            </Col>
            <Col xs={12} md={12} lg={10}>
              {item?.description ? (
                <p className="merchant-description">
                  {item?.description ? item?.description : item?.name}
                </p>
              ) : (
                <p>No Description</p>
              )}
            </Col>
          </Row>
          <button
            onClick={() => {
              if (userToken) {
                if (item?.player_id === null) {
                  handleShow();
                } else {
                  navigate(`/${item?.slug}/partners`);
                }
              } else {
                handleShow();
              }
            }}
            type="submit"
          >
            {t("Buy Now")}
          </button>
          <PlayerModal show={show} handleClose={handleClose} item={item} />
        </div>
      </div>
    </Col>
  );
};

export default MerchantGameItem;
