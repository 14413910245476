import React from "react";
import check from "../../assets/check.png";
import { Link } from "react-router-dom";

const SignUpSuccess = () => {
  return (
    <div className="signin-form text-center">
      <img className="check" src={check} alt="check" />
      <p className="success-text">
        Thank you <br />
        You have verified your account
      </p>
      <Link to="/signIn" className="signin-btn mt-3" type="submit">
        Continue
      </Link>
    </div>
  );
};

export default SignUpSuccess;
