import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import "./LanguageSelector.css";

const LanguageSelector = ({ languages }) => {
  const { i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(null);

  useEffect(() => {
    const storedLanguage =
      localStorage.getItem("i18nextLng") === "en-US"
        ? "en"
        : localStorage.getItem("i18nextLng") === "ar"
        ? "ar"
        : "en";
    if (storedLanguage) {
      setSelectedLanguage(
        languages.find((lang) => lang.code === storedLanguage)
      );
    } else {
      setSelectedLanguage(languages[0]);
    }
  }, [languages]);

  const handleChange = (selectedOption) => {
    const newLanguage = languages.find(
      (lang) => lang.code === selectedOption.value
    );
    setSelectedLanguage(newLanguage);
    localStorage.setItem("i18nextLng", newLanguage.code);
    i18n.changeLanguage(newLanguage.code);
    window.location.reload();
  };

  const options = languages.map((language) => ({
    value: language.code,
    label: (
      <div className="language-option">
        <img
          src={language.flag}
          alt={`${language.code} flag`}
          className="language-flag"
        />
        {language.code}
      </div>
    ),
  }));

  const customSingleValue = ({ data }) => (
    <div className="language-single-value">
      <img
        src={data.flag}
        alt={`${data.value} flag`}
        className="language-flag"
      />
      {data.value}
    </div>
  );

  const customStyles = {
    control: (provided) => ({
      ...provided,
      display: "flex",
      alignItems: "center",
    }),
    singleValue: (provided) => ({
      ...provided,
      display: "flex",
      alignItems: "center",
    }),
    option: (provided) => ({
      ...provided,
      display: "flex",
      alignItems: "center",
    }),
  };

  return (
    <Select
      options={options}
      value={
        selectedLanguage
          ? {
              value: selectedLanguage.code,
              label: selectedLanguage.code,
              flag: selectedLanguage.flag,
            }
          : null
      }
      onChange={handleChange}
      styles={customStyles}
      components={{ SingleValue: customSingleValue }}
      className="language-selector"
    />
  );
};

export default LanguageSelector;
