// import React, { useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import BankCardHeadingBox from "../BankCardHeadingBox/BankCardHeadingBox";
import pay_2 from "../../assets/payment/pay_2.png";
import progress_2 from "../../assets/payment/progress_2.png";
import PaymentProduct from "../PaymentProduct/PaymentProduct";
import PaymentProgress from "../PaymentProgress/PaymentProgress";
import { useState } from "react";
import PaymentInstructions from "../PaymentInstructions/PaymentInstructions";
import { payment } from "../../utils/api";

const PaymentPos = () => {
  const userToken = localStorage.getItem("paymentHub_token");
  const [disabled, setDisabled] = useState(false);
  const [showQr, setShowQr] = useState(false);
  const [paymentData, setPaymentData] = useState([]);
  const [guestName, setGuestName] = useState("");
  const [guestEmail, setGuestEmail] = useState("");
  const [guestPhone, setGuestPhone] = useState("");
  const [errMsg, setErrMsg] = useState("");

  const payWithPos = async (itemId, paymentId, playerId) => {
    try {
      const result = await payment(itemId, paymentId, playerId, userToken);
      setPaymentData(result);
    } catch (error) {}
  };
  return (
    <div className="payment-methods">
      <Container>
        <PaymentProgress progress_img={progress_2} />
        <h1 className="payment-title">Payment Method</h1>
        <Row>
          <Col md={12} lg={8} xl={9}>
            <BankCardHeadingBox bankCardImg={pay_2} bankCardTitle={"POS"} />
            {showQr ? (
              <PaymentInstructions
                title={"How to use Code"}
                setShowQr={setShowQr}
                pay_title={"get reference code"}
                pay_subTitle={"Copy this  code to verify your payment"}
                pay_code={"123456"}
                paymentData={paymentData}
              />
            ) : (
              <>
                {disabled && userToken ? (
                  <h1 className="payment-title">Details</h1>
                ) : (
                  <h1 className="payment-title">Details</h1>
                )}
                <Row>
                  <Col xs={12} md={12} lg={10} xl={6}>
                    {disabled && userToken ? (
                      <>
                        <Form.Label htmlFor="Name">Name</Form.Label>
                        <Form.Control
                          type="text"
                          id="Name"
                          aria-describedby="passwordHelpBlock"
                          placeholder="Name"
                          value={guestName}
                          onChange={(e) => setGuestName(e.target.value)}
                        />
                        <br />
                        <Form.Label htmlFor="Email">Email</Form.Label>
                        <Form.Control
                          type="email"
                          id="Email"
                          aria-describedby="passwordHelpBlock"
                          placeholder="Email"
                          value={guestEmail}
                          onChange={(e) => setGuestEmail(e.target.value)}
                        />
                        <br />
                        <Form.Label htmlFor="phone">Phone</Form.Label>
                        <Form.Control
                          type="phone"
                          id="phone"
                          aria-describedby="passwordHelpBlock"
                          placeholder="phone"
                          value={guestPhone}
                          onChange={(e) => setGuestPhone(e.target.value)}
                        />
                        <br />
                      </>
                    ) : !userToken ? (
                      <>
                        <Form.Label htmlFor="Name">Name</Form.Label>
                        <Form.Control
                          type="text"
                          id="Name"
                          aria-describedby="passwordHelpBlock"
                          placeholder="Name"
                          value={guestName}
                          onChange={(e) => setGuestName(e.target.value)}
                        />
                        <br />
                        <Form.Label htmlFor="Email">Email</Form.Label>
                        <Form.Control
                          type="email"
                          id="Email"
                          aria-describedby="passwordHelpBlock"
                          placeholder="Email"
                          value={guestEmail}
                          onChange={(e) => setGuestEmail(e.target.value)}
                        />
                        <br />
                        <Form.Label htmlFor="phone">Phone</Form.Label>
                        <Form.Control
                          type="phone"
                          id="phone"
                          aria-describedby="passwordHelpBlock"
                          placeholder="phone"
                          value={guestPhone}
                          onChange={(e) => setGuestPhone(e.target.value)}
                        />
                        <br />
                        {errMsg ? (
                          <p style={{ color: "red" }}>{errMsg}</p>
                        ) : null}
                      </>
                    ) : null}
                    <form>
                      {userToken ? (
                        <Col xs={12}>
                          <Form.Check // prettier-ignore
                            type="checkbox"
                            id="invoice"
                            label="I Want to receive invoice"
                            onChange={() => setDisabled(!disabled)}
                          />
                        </Col>
                      ) : null}
                    </form>
                  </Col>
                </Row>
              </>
            )}
          </Col>
          <Col md={6} lg={4} xl={3}>
            <PaymentProduct
              setErrMsg={setErrMsg}
              showQr={showQr}
              setShowQr={setShowQr}
              payFun={payWithPos}
              guestName={guestName}
              guestEmail={guestEmail}
              guestPhone={guestPhone}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PaymentPos;
