import React from "react";
import "./instructionComp.css";
import { Col } from "react-bootstrap";

const InstructionComp = ({ num, title, desc }) => {
  return (
    <Col xs={12} md={6} lg={6} xl={4} xxl={4}>
      <div className="inst-section">
        <span>{num}</span>
        <h3>{title}</h3>
        <p>{desc}</p>
      </div>
    </Col>
  );
};

export default InstructionComp;
