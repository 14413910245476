import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import "./playerModal.css";
import question from "../../assets/navbar/question.png";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { addProductPlayerId } from "../../utils/api";

const PlayerModal = ({ show, handleClose, item }) => {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  const isArabic = currentLang === "ar";
  const navigate = useNavigate();
  const [playerId, setPlayerId] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const token = localStorage.getItem("paymentHub_token");
  const addPlayerIdApi = async (productId, playerId) => {
    try {
      const response = await addProductPlayerId(
        productId,
        {
          player_id: playerId,
        },
        token
      );
      handleClose();
      navigate(`/${item.slug}/partners`);
    } catch (error) {}
  };
  return (
    <Modal show={show} onHide={handleClose} dir={isArabic ? "rtl" : "ltr"}>
      <Modal.Header>
        <Modal.Title>{t("Enter Your Player ID")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-4">
          <span>{t("Player ID Verification")}</span>
          <img
            className="mx-2"
            style={{ cursor: "pointer" }}
            src={question}
            alt="question"
          />
        </div>
        <input
          type="text"
          className="mb-0"
          placeholder={t("Please enter your ID")}
          value={playerId}
          onChange={(e) => setPlayerId(e.target.value)}
        />
        <span style={{ color: "red", fontSize: "15px", marginTop: "5px" }}>
          {errMsg}
        </span>
      </Modal.Body>
      <Modal.Footer>
        <button
          onClick={() => {
            if (token) {
              addPlayerIdApi(item.slug, playerId);
            } else {
              localStorage.setItem("playerId", playerId);
              navigate(`/${item.slug}/partners`);
            }
          }}
        >
          {t("OK")}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default PlayerModal;
