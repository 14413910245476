import React, { useState } from "react";
import FormsHeading from "../FormsHeading/FormsHeading";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import email_icon from "../../assets/email.png";
import { IoClose } from "react-icons/io5";
import { updateUserPhone } from "../../utils/api";

const ChangeEmail = ({ handleClose1 }) => {
  const { t } = useTranslation();
  const [newEmail, setNewEmail] = useState();
  const [setResult] = useState([]);
  const [setSuccessMsg] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const updateEmail = async () => {
    try {
      const response = await updateUserPhone("change-email", {
        email: newEmail,
      });

      setResult(response);
      setSuccessMsg("verification code sent to your phone");
      setErrMsg("");
      setTimeout(() => {
        handleClose1();
        // handleShow2();
      }, [1500]);
    } catch (error) {
      setErrMsg(error.response.data.message);
      setSuccessMsg("");
    }
  };
  return (
    <Container>
      <div className="signin-form">
        <span className="modal-close-btn" onClick={handleClose1}>
          <IoClose />
        </span>
        <FormsHeading
          heading={t("Change Email")}
          subHeading={t(
            "Enter the email to receive the verification code, then press Send Code"
          )}
        />
        {/* {successMsg ? (
      <div className="alert alert-success text-center">{successMsg}</div>
    ) : (
      ""
    )} */}
        <form
          onSubmit={(e) => {
            e.preventDefault();
            updateEmail();
          }}
        >
          <div className="input-group mb-2">
            <div
              className="form-input"
              style={
                errMsg
                  ? { border: "2px solid #FF2D2D" }
                  : { border: "2px solid #898990" }
              }
            >
              <img className="mx-2" src={email_icon} alt="phone" />
              <input
                style={errMsg ? { color: "#FF2D2D" } : { color: "#898990" }}
                value={newEmail}
                onChange={(e) => setNewEmail(e.target.value)}
                required={true}
                id="email"
                type="email"
                placeholder={t("Enter Your email")}
              />
            </div>
            <span className="text-danger mt-2">{errMsg}</span>
          </div>
          <button className="signin-btn mb-4" type="submit">
            {t("Send Code")}
          </button>
        </form>
      </div>
    </Container>
  );
};

export default ChangeEmail;
