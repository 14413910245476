import React from "react";
import "./generalCarousel.css";
// import required modules
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

const GeneralCarousel = () => {
  return (
    <Swiper
      // spaceBetween={30}
      pagination={{
        clickable: true,
      }}
      modules={[Pagination]}
      className="myGeneralSwiper"
    >
      <SwiperSlide>
        <div className="overlay"></div>
      </SwiperSlide>
      {/* <SwiperSlide>
        <div className="overlay"></div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="overlay"></div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="overlay"></div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="overlay"></div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="overlay"></div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="overlay"></div>
      </SwiperSlide> */}
    </Swiper>
  );
};

export default GeneralCarousel;
