import React, { useState } from "react";
import "./reviewItem.css";
import setting_icon from "../../assets/navbar/setting.png";
import { Col, Modal } from "react-bootstrap";
import { getDateOnly } from "../../utils/helpers";
import edit_icon from "../../assets/edit.png";
import delete_icon from "../../assets/delete.png";
// import share_icon from "../../assets/share.png";
import { useTranslation } from "react-i18next";
import { deleteReview } from "../../utils/api";
import { useParams } from "react-router-dom";
import EditReview from "../Modals/EditReview";
import Rating from "react-rating";
import { FaStar } from "react-icons/fa";
import { CiStar } from "react-icons/ci";

const ReviewItem = ({ review, setReload, reload }) => {
  const user_id = localStorage.getItem("user_id");
  const { t } = useTranslation();
  const { productId } = useParams();
  const [showActionList, setShowActionList] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const deleteReviewFun = async (productId, reviewId) => {
    try {
      await deleteReview(productId, reviewId);
      setShowActionList(!showActionList);
      setReload(!reload);
    } catch (error) {
      setReload(!reload);
    }
  };

  return (
    <Col lg={8}>
      <div className="player-review">
        {review.user.id === parseInt(user_id) ? (
          <img
            style={
              localStorage.getItem("i18nextLng") === "ar"
                ? { left: "10px" }
                : { right: "10px" }
            }
            className="edit"
            src={setting_icon}
            alt="setting_icon"
            onClick={() => setShowActionList(!showActionList)}
          />
        ) : null}
        <ul
          className={`action-list ${showActionList ? "show-action-list" : ""}`}
          style={
            localStorage.getItem("i18nextLng") === "ar"
              ? { left: "15px" }
              : { right: "15px" }
          }
        >
          <li
            onClick={() => {
              handleShow();
              setShowActionList(!showActionList);
            }}
          >
            <img width={15} height={15} src={edit_icon} alt="edit" />
            <span>{t("Edit Review")}</span>
          </li>
          <Modal show={show}>
            <EditReview
              review={review}
              handleClose={handleClose}
              setReload={setReload}
              reload={reload}
            />
          </Modal>
          <li onClick={() => deleteReviewFun(productId, review?.id)}>
            <img
              width={15}
              height={15}
              src={delete_icon}
              alt="edit"
              onClick={() => {
                deleteReviewFun(productId, review?.id);
              }}
            />
            <span style={{ color: "#AA3431" }}>{t("Delete Review")}</span>
          </li>
          {/* <li>
            <img width={15} height={15} src={share_icon} alt="edit" />
            <span>{t("Share Review")}</span>
          </li> */}
        </ul>
        <div className="player-info text-center">
          <img
            width={100}
            height={100}
            style={{ borderRadius: "50%" }}
            src={review?.user?.avatar}
            alt="player"
          />
          <h2 className="text-wrap">{review?.user?.name}</h2>
        </div>
        <div className="player-text">
          <div className="star">
            <div className="star-imgs">
              <Rating
                initialRating={review?.rating}
                readonly
                emptySymbol={<CiStar className="empty-star" />}
                fullSymbol={<FaStar className="fill-star" />}
                direction={
                  localStorage.getItem("i18nextLng") === "ar" ? "rtl" : "ltr"
                }
              />
            </div>
            <span>{getDateOnly(review?.created_at)}</span>
          </div>
          <p>{review?.comment}</p>
        </div>
      </div>
      <hr />
    </Col>
  );
};

export default ReviewItem;
