import React from "react";
import { Link } from "react-router-dom";
import gmail_icon from "../../assets/social/gmail.png";
import facebook_icon from "../../assets/social/facebook.png";
import apple_icon from "../../assets/social/apple.png";
import { useTranslation } from "react-i18next";

const FormSocialMedia = ({
  navigatePath,
  navigateTitle,
  navigateSubTitle,
  showNavigate,
}) => {
  const { t } = useTranslation();
  return (
    <>
      {/* <p className="text-center my-3">{t("Or continue with")}</p>
      <div className="form-social mb-3">
        <img src={gmail_icon} alt="gmail_icon" />
        <img src={facebook_icon} alt="facebook_icon" />
        <img src={apple_icon} alt="apple_icon" />
      </div> */}
      {showNavigate ? (
        <div className="text-center have-account">
          <span className="mx-2">{navigateSubTitle}</span>
          <Link to={`/${navigatePath}`}>{navigateTitle}</Link>
        </div>
      ) : null}
    </>
  );
};

export default FormSocialMedia;
