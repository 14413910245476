import React from "react";
import check from "../../assets/payment/check.png";
import "./bankCardHeadingBox.css";
const BankCardHeadingBox = ({ bankCardImg, bankCardTitle }) => {
  return (
    <div className="bankCard-heading">
      <div className="bankCard-img">
        <img src={bankCardImg} alt="bankCardImg" />
        <span>{bankCardTitle}</span>
      </div>
      <img src={check} alt="check" />
    </div>
  );
};

export default BankCardHeadingBox;
