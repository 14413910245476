import React from "react";
import ScrollToTop from "../Components/ScrollToTop";
import MainNavbar from "../Components/Navbar/MainNavbar";
import { Outlet } from "react-router-dom";
import Footer from "../Components/Footer/Footer";

const PaymentLayOut = () => {
  return (
    <>
      <ScrollToTop />
      <MainNavbar />
      <Outlet />
      <Footer />
    </>
  );
};

export default PaymentLayOut;
