import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import AuthLayout from "../Layouts/AuthLayout/AuthLayout";
import SignIn from "../Components/SignIn/SignIn";
import NewPassword from "../Components/NewPassword/NewPassword";
import SignInSuccess from "../Components/SignInSuccess/SignInSuccess";
import Signup from "../Components/Signup/Signup";
import SignUpSuccess from "../Components/SignUpSuccess/SignUpSuccess";
import ResetPassword from "../Components/ResetPassword/ResetPassword";
import RootLayout from "../Layouts/RootLayout";
import HomePage from "../Pages/HomePage/HomePage";
import MerchantPage from "../Pages/MerchantPage/MerchantPage";
import ProductDetails from "../Pages/ProductDetails/ProductDetails";
import AllMerchants from "../Pages/AllMerchants/AllMerchants";
import PopularProducts from "../Pages/PopularProducts/PopularProducts";
import VerifyAccount from "../Components/VerifyAccount/VerifyAccount";
import VerifyMessage from "../Components/VerifyMessage/VerifyMessage";
import PaymentMethods from "../Pages/PaymentMethods/PaymentMethods";
import PaymentLayOut from "../Layouts/PaymentLayOut";
// import PaymentBank from "../Components/PaymentBank/PaymentBank";
import PaymentPos from "../Components/PaymentPos/PaymentPos";
import PaymentEwallet from "../Components/PaymentEwallet/PaymentEwallet";
import PaymentConfirmation from "../Components/PaymentConfirmation/PaymentConfirmation";
import SignUpFail from "../Components/SignUpFail/SignUpFail";
import PaymentInstructions from "../Components/PaymentInstructions/PaymentInstructions";
import UserTransactions from "../Components/UserTransactions/UserTransactions";
import UserInvoice from "../Components/UserTransactions/UserInvoice";
import UserLayout from "../Layouts/UserLayout";
import Wishlist from "../Components/Wishlist/Wishlist";
import UserProfile from "../Components/UserProfile/UserProfile";
import UserSecurity from "../Components/UserSecurity/UserSecurity";
import ExclusiveMerchants from "../Pages/ExclusiveMerchants/ExclusiveMerchants";
import CustomerSupport from "../Components/CustomerSupport/CustomerSupport";
import NewTicket from "../Components/NewTicket/NewTicket";
import Notifications from "../Components/Notifications/Notifications";
import FaqPage from "../Pages/FaqPage/FaqPage";
import UserGuide from "../Layouts/UserGuide";
import PrivacyPolicy from "../Components/PrivacyPolicy/PrivacyPolicy";
import TermsOfService from "../Components/TermsOfService/TermsOfService";
import ContactUs from "../Components/ContactUs/ContactUs";
import ProtectedRoute from "../Components/ProtectedRoute";
import ViewTicket from "../Components/ViewTicket/ViewTicket";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/" element={<AuthLayout />}>
        <Route index element={<SignIn />} />
        <Route path="signin" element={<SignIn />} />
        <Route path="resetPassword" element={<ResetPassword />} />
        <Route path="forgetPassword" element={<NewPassword />} />
        <Route path="successPassword" element={<SignInSuccess />} />
        <Route path="signup" element={<Signup />} />
        <Route path="verifyEmail" element={<VerifyAccount />} />
        <Route path="verifyMessage" element={<VerifyMessage />} />
        <Route path="successVerify" element={<SignUpSuccess />} />
        <Route path="failVerify" element={<SignUpFail />} />
      </Route>

      
      <Route path="/" element={<RootLayout />}>
        <Route path="home" element={<HomePage />} />
        <Route path="allMerchants" element={<AllMerchants />} />
        <Route path="allMerchants/:merchantName" element={<MerchantPage />} />
        <Route path="merchant/:merchantName" element={<MerchantPage />} />
        <Route path="productDetails/:productId" element={<ProductDetails />} />
        <Route path="popularGames" element={<PopularProducts />} />
        <Route path="exclusiveMerchants" element={<ExclusiveMerchants />} />
        <Route path="faq" element={<FaqPage />} />
      </Route>
      <Route path="/:id/partners" element={<PaymentLayOut />}>
        <Route index={true} element={<PaymentMethods />} />
        <Route path=":id/pertners" element={<PaymentMethods />} />
        <Route path="FAWRY_REFRENCE" element={<PaymentPos />} />
        {/* <Route path="FAWRY_CARD" element={<PaymentBank />} /> */}
        <Route path="FAWRY_WALLET" element={<PaymentEwallet />} />
        <Route path="paymentInstructions" element={<PaymentInstructions />} />
        <Route path="paymentConfirmation" element={<PaymentConfirmation />} />
      </Route>
      <Route path="/userProfile" element={<UserLayout />}>
        <Route
          path="transactions"
          element={<ProtectedRoute element={UserTransactions} />}
        />
        <Route
          path="wishlist"
          element={<ProtectedRoute element={Wishlist} />}
        />
        <Route
          path="accountDetails"
          element={<ProtectedRoute element={UserProfile} />}
        />
        <Route
          path="security"
          element={<ProtectedRoute element={UserSecurity} />}
        />
        <Route
          path="customerSupport"
          element={<ProtectedRoute element={CustomerSupport} />}
        />
        <Route
          path="customerSupport/addTicket"
          element={<ProtectedRoute element={NewTicket} />}
        />
        <Route
          path="customerSupport/ViewTicket/:id"
          element={<ProtectedRoute element={ViewTicket} />}
        />
        <Route
          path="notifications"
          element={<ProtectedRoute element={Notifications} />}
        />
      </Route>
      <Route
        path="/userInvoice/:id"
        element={<ProtectedRoute element={UserInvoice} />}
      />

      <Route path="/userGuide" element={<UserGuide />}>
        <Route path="privacyPolicy" element={<PrivacyPolicy />} />
        <Route path="termsOfService" element={<TermsOfService />} />
        <Route path="contactUs" element={<ContactUs />} />
      </Route>
    </Route>
  )
);

export default router;
