import React from "react";
import "./paymentConfirmation.css";
import { Col, Container, Row } from "react-bootstrap";
import PaymentProgress from "../PaymentProgress/PaymentProgress";
import progress_4 from "../../assets/payment/progress_4.png";
import dark_check from "../../assets/payment/dark_check.png";

const PaymentConfirmation = () => {
  return (
    <div className="payment-methods">
      <Container>
        <PaymentProgress progress_img={progress_4} />
        <Row>
          <Col xl={12}>
            <div className="pay-con">
              <img src={dark_check} alt="dark_check" />
              <div className="pay-con-details">
                <p>Your payment has been confirmed !</p>
                <p>
                  You will also receive an email with your confirmation details
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PaymentConfirmation;
