import React from "react";
import check from "../../assets/check.png";
import { Link } from "react-router-dom";
import "./signInSuccess.css";

const SignInSuccess = () => {
  return (
    <div className="signin-form text-center">
      <img className="check" src={check} alt="check" />
      <p className="success-text">
        Password has been changed <br /> successfully
      </p>
      <Link className="success-link" to={"/signin"}>
        Going to login
      </Link>
    </div>
  );
};

export default SignInSuccess;
