import React from "react";
import { FcNext, FcPrevious } from "react-icons/fc";

const PaginationControls = ({
  handleNextPage,
  handlePrevPage,
  currentPage,
  total,
  perPage,
}) => {
  return (
    <div className="d-flex align-items-center justify-content-center gap-3 mt-2 mb-5">
      {total > perPage ? (
        <>
          <FcPrevious className="pag-icon" onClick={handlePrevPage} />
          <span className="pag-text">Page {currentPage}</span>
          <FcNext className="pag-icon" onClick={handleNextPage} />
        </>
      ) : null}
    </div>
  );
};

export default PaginationControls;
