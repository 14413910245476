import React, { useEffect } from "react";
import "./verifyAccount.css";
import { useLocation, useNavigate } from "react-router-dom";
import { verifyEmail } from "../../utils/api";

const VerifyAccount = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const verifyUser = async (myQueryParam) => {
    try {
      await verifyEmail(myQueryParam);
      setTimeout(() => {
        navigate("/successVerify");
      }, 2500);
    } catch (error) {
      setTimeout(() => {
        navigate("/failVerify");
      }, 2500);
    }
  };
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const myQueryParam = params.get("hash");
    verifyUser(myQueryParam);
  }, [location]);
  return (
    <div className="signin-form text-center verify">
      <div className="success-text">
        Verifying
        <div className="lds-ellipsis">
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
    </div>
  );
};

export default VerifyAccount;
