import React, { useEffect, useState } from "react";
import question from "../../assets/navbar/question.png";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { addMerchantId } from "../../utils/api";
import { useParams } from "react-router-dom";

const MerchantModal = ({ show, handleClose }) => {
  const { merchantName } = useParams();
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  const isArabic = currentLang === "ar";
  const [errMsg, setErrMsg] = useState("");
  const token = localStorage.getItem("paymentHub_token");
  const [playerId, setPlayerId] = useState("");
  const [loading, setLoading] = useState(true);
  const addMerchantIdApi = async (productId, formData, token) => {
    try {
      await addMerchantId(productId, formData, token);
      setErrMsg("");
      setLoading(false);
      handleClose();
      window.location.reload();
    } catch (error) {
      setErrMsg(error.response.data.message);
    }
  };
  useEffect(() => {
    setErrMsg("");
  }, [loading]);
  return (
    <Modal
      show={show}
      onHide={handleClose}
      dir={isArabic ? "rtl" : "ltr"} //
    >
      <Modal.Header>
        <Modal.Title>{t("Enter Your Player ID")}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-3 py-0">
        <div className="mb-4">
          <span>{t("Player ID Verification")}</span>
          <img
            style={{ cursor: "pointer", margin: "0 10px" }}
            src={question}
            alt="question"
          />
        </div>
        <input
          type="text"
          placeholder={t("Please enter your ID")}
          value={playerId}
          onChange={(e) => setPlayerId(e.target.value)}
        />
        <span style={{ color: "red", fontSize: "15px", marginTop: "5px" }}>
          {errMsg}
        </span>
      </Modal.Body>
      <Modal.Footer>
        <button
          onClick={() =>
            addMerchantIdApi(merchantName, { player_id: playerId }, token)
          }
        >
          {t("ok")}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default MerchantModal;
