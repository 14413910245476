import React from "react";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { IoClose } from "react-icons/io5";
import FormsHeading from "../FormsHeading/FormsHeading";

const OtpPhone = ({ handleClose2 }) => {
  const { t } = useTranslation();
  return (
    <Container>
      <div className="signin-form">
        <span className="modal-close-btn" onClick={handleClose2}>
          <IoClose />
        </span>
        <FormsHeading
          heading={t("OTP Code")}
          subHeading={t(
            "A 6-digit verification code has been sent to the number"
          )}
        />
        {/* {successMsg ? (
        <div className="alert alert-success text-center">{successMsg}</div>
      ) : (
        ""
      )} */}
      </div>
    </Container>
  );
};

export default OtpPhone;
