import React, { useEffect, useState } from "react";
import "./paymentMethods.css";
import { Col, Row } from "react-bootstrap";
// import payment images
import progress_1 from "../../assets/payment/progress_1.png";
import pay_2 from "../../assets/payment/pay_2.png";
import pay_3 from "../../assets/payment/pay_3.png";
import pay_4 from "../../assets/payment/pay_4.png";
import PaymentCard from "../../Components/PaymentCard/PaymentCard";
import PaymentProgress from "../../Components/PaymentProgress/PaymentProgress";
import { getPaymentMethods } from "../../utils/api";
import PaymentProduct2 from "../../Components/PaymentProduct/PaymentProduct2";

const PaymentMethods = () => {
  const [payment, setpayment] = useState([]);
  const getPaymentMethodsApi = async () => {
    const { data } = await getPaymentMethods();
    setpayment(data);
  };
  const icons_arr = [pay_4, pay_2, pay_3];
  useEffect(() => {
    getPaymentMethodsApi();
  }, []);

  return (
    <div className="container-fluid">
      <div className="payment-methods">
        <PaymentProgress progress_img={progress_1} />
        <h1 className="payment-title text-capitalize mb-5">
          Choose following available Getways to accept payment
        </h1>
        <Row>
          {payment.map((item, index) => {
            return (
              <PaymentCard
                key={index}
                card_img={icons_arr[index]}
                card_title={item?.name}
                card_desc={
                  item?.description ||
                  "lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua"
                }
                redirectPath={item?.module}
                id={item?.id}
              />
            );
          })}
          <Col xs={12} md={6} lg={3} xl={3} xxl={3}>
            <PaymentProduct2 />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default PaymentMethods;
