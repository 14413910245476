import React, { useEffect, useState } from "react";
import { ToastContainer } from 'react-toastify';

// import HomeFaq from "../../Components/HomeFaq/HomeFaq";
import Heading from "../../Components/Heading/Heading";
import TopMerchant from "../../Components/TopMerchant/TopMerchant";
import ExclusivePaymentHub from "../../Components/ExclusivePaymentHub/ExclusivePaymentHub";
import Recomended from "../../Components/Recomended/Recomended";
import PopularGmaes from "../../Components/PopularGmaes/PopularGmaes";
import TopSales from "../../Components/TopSales/TopSales";
import HomeCarousel from "../../Components/HomeCarousel/HomeCarousel";
import { getHomeData } from "../../utils/api";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import 'react-toastify/dist/ReactToastify.css';
import { toast } from "react-toastify"; // Import toast for notification pop-up
const HomePage = () => {
  const { t } = useTranslation();
  const [homeBanners, setHomeBanners] = useState([]);
  console.log(homeBanners);
  const [topMerchant, setTopMerchant] = useState([]);
  const [topSales, setTopSales] = useState([]);
  const [popularProducts, setPopularProducts] = useState([]);
  const [exclusiveProducts, setExclusiveProducts] = useState([]);
  const [recommended, setRecommended] = useState([]);
  const storedCurrency = JSON.parse(localStorage.getItem("selectedCurrency"));
  const currencyCode = storedCurrency ? storedCurrency.code : "USD";
  let currentLanguage = localStorage.getItem("i18nextLng");
  const token = localStorage.getItem("paymentHub_token");

  const getHomeDataApi = async () => {
 
  


    try {
     
      const homeBanners = await getHomeData(
        "banner",
        "",
        currentLanguage,
        token
      );
      setHomeBanners(homeBanners.data);
      const topMerchant = await getHomeData(
        "top-merchants",
        "",
        currentLanguage,
        token
      );
      setTopMerchant(topMerchant.data);
      const topSales = await getHomeData(
        "top-sales",
        currencyCode,
        currentLanguage,
        token
      );
      setTopSales(topSales.data);
      const exclusiveProducts = await getHomeData(
        "payments-hub-only",
        currencyCode,
        currentLanguage,
        token
      );
      setExclusiveProducts(exclusiveProducts.data);
    } catch (error) {}
  };
  const getPopularProducts = async () => {
    try {
      const popularProducts = await getHomeData(
        "popular",
        currencyCode,
        currentLanguage,
        token
      );
      setPopularProducts(popularProducts.data);
      const recommended = await getHomeData(
        "recommended",
        currencyCode,
        currentLanguage,
        token
      );
      setRecommended(recommended.data);
    } catch (error) {}
  };
  useEffect(() => {
    getPopularProducts();
  }, []);
  useEffect(() => {
    getHomeDataApi();
  }, []);

  return (
 
    <div className="home">
      <ToastContainer />
      <Container fluid>
        <HomeCarousel homeBanners={homeBanners} />
        <Heading
          title={t("Top Merchant")}
          btn_title={t("View All")}
          navigatePath={"allMerchants"}
        />
        
   
        <TopMerchant topMerchant={topMerchant} />
        <Heading title={t("Top 10 Sales")} />
        <TopSales topSales={topSales} />
        <Heading
          title={t("Popular on Payment HUB")}
          btn_title={t("View All")}
          navigatePath={"popularGames"}
        />
        <PopularGmaes popularProducts={popularProducts} />
        <Heading
          title={t("Only on Payment HUB")}
          btn_title={t("View All")}
          navigatePath={"exclusiveMerchants"}
        />
        <ExclusivePaymentHub exclusiveProducts={exclusiveProducts} />
        <Heading title={t("Recommended for you")} />
        <Recomended similarProducts={recommended} />
      </Container>
    </div>
  );
};

export default HomePage;
