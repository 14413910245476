import React, { useEffect, useState } from "react";
import email_icon from "../../assets/email.png";
import password_icon from "../../assets/Password.png";
import hidden_icon from "../../assets/hidden_icon.svg";
import visible_icon from "../../assets/visible_icon.svg";

import "./signIn.css";
import { Link, useNavigate } from "react-router-dom";
import { AuthLogic } from "../../utils/api";
import FormSocialMedia from "../FormSocialMedia/FormSocialMedia";
import FormsHeading from "../FormsHeading/FormsHeading";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const SignIn = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [errMsg, setErrMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  // handle signin function
  const handleSignin = async (e) => {
    e.preventDefault();
    let formData = {
      email: email,
      password: password,
    };
    try {
      const { data } = await AuthLogic("login", formData);
      localStorage.setItem("paymentHub_token", data.access_token);
      localStorage.setItem("user_id", data.id);
      setSuccessMsg("Sign In Successfully");
      // Store username, password, and rememberMe state if rememberMe is checked
      if (rememberMe) {
        localStorage.setItem(
          "rememberedUser",
          JSON.stringify({ email, password })
        );
      } else {
        localStorage.removeItem("rememberedUser");
      }
      setErrMsg("");
      setEmail("");
      setPassword("");
      setTimeout(() => {
        navigate("/home");
      }, 1200);
    } catch (error) {
      setErrMsg("Invalid Email Or Password");
      setSuccessMsg("");
    }
  };
  useEffect(() => {
    // fill user inputs data if he check remember me
    const rememberedUser = localStorage.getItem("rememberedUser");
    if (rememberedUser) {
      const { email, password } = JSON.parse(rememberedUser);
      setEmail(email);
      setPassword(password);
      setRememberMe(true);
    }
  }, []);

  return (
    <Container>
      <Row>
        <Col sm={0} md={2} lg={3} xl={3} xxl={4}></Col>
        <Col sm={12} md={8} lg={6} xl={6} xxl={4}>
          <div
            className="signin-form"
            dir={localStorage.getItem("i18nextLng") === "ar" ? "rtl" : "ltr"}
          >
            <FormsHeading heading={t("Welcome To Payment HUB")} />
            {errMsg ? (
              <div className="alert alert-danger text-center">{errMsg}</div>
            ) : (
              ""
            )}
            {successMsg ? (
              <div className="alert alert-success text-center">
                {successMsg}
              </div>
            ) : (
              ""
            )}
            <form onSubmit={handleSignin}>
              <div className="input-group mb-3">
                <label htmlFor="email">{t("Email")}</label>
                <div className="form-input">
                  <img src={email_icon} alt="email_icon" />
                  <input
                    autoSave="false"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required={true}
                    id="email"
                    type="email"
                    placeholder={t("Enter Your Email")}
                  />
                </div>
              </div>
              <div className="input-group mb-2">
                <label htmlFor="password">{t("Password")}</label>
                <div className="form-input">
                  <img src={password_icon} alt="password_icon" />
                  <input
                    autoSave="false"
                    required={true}
                    id="password"
                    placeholder={t("Enter Your Password")}
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <img
                    onClick={togglePasswordVisibility}
                    className="pass_hidden"
                    src={showPassword ? visible_icon : hidden_icon}
                    alt="hidden_icon"
                  />
                </div>
              </div>
              <div className="check-forget mt-3">
                <div className="check-box">
                  <div className="checkbox-inp">
                    <input
                      type="checkbox"
                      id="remember-me"
                      checked={rememberMe}
                      onChange={(e) => setRememberMe(e.target.checked)}
                    />
                    <label htmlFor="remember-me">{t("Remember me")}</label>
                  </div>
                </div>
                <div className="forget-password">
                  <Link to="/resetPassword">{t("Forget Password?")}</Link>
                </div>
              </div>
              <button className="signin-btn my-3" type="submit">
                {t("Log In")}
              </button>
            </form>
            <FormSocialMedia
              navigatePath={"signup"}
              navigateTitle={t("Sign Up")}
              navigateSubTitle={t("Dont't Have An Account ?")}
              showNavigate={true}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default SignIn;
