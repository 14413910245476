import React, { useEffect, useState } from "react";
import "./userInvoice.css";
import { Col, Container, Row, Table } from "react-bootstrap";
import logo from "../../assets/profile/logo.png";
import { useParams } from "react-router-dom";
import { getuserTransactionInvoice } from "../../utils/api";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import LoaderSpinner from "../LoaderSpinner/LoaderSpinner";
import { getDate } from "../../utils/helpers";

const InvoiceHeader = ({ invoiceData }) => (
  <Row className="mb-5">
    <Col xs={12} md={5} lg={3}>
      <div className="invoice mb-3">
        <h6>Billed to</h6>
        <p>{invoiceData?.user?.name}</p>
        <span>{invoiceData?.user?.email}</span>
      </div>
    </Col>
    <Col xs={6} md={4} lg={3}>
      <div className="invoice mb-3">
        <h6>Invoice number</h6>
        <p className="mb-3">#{invoiceData?.invoice_id}</p>
        <h6>Reference</h6>
        <p>
          {invoiceData?.qr !== null ? (
            <p className="mb-3">
              <img src={invoiceData?.qr} alt="QR Code" width={80} height={80} />
            </p>
          ) : (
            <b className="mb-3">{invoiceData?.refrence}</b>
          )}
        </p>
      </div>
    </Col>
    <Col xs={6} md={4} lg={3}>
      <div className="invoice mb-3">
        <h6>Status</h6>
        <b
          className="status mb-3 d-block"
          style={
            invoiceData?.status === 1
              ? {
                  color: "#FFAA05",
                }
              : invoiceData?.status === 2
              ? { color: "#1BBE01" }
              : invoiceData?.status === 3
              ? { color: "#FF0000" }
              : { color: "#FF0000" }
          }
        >
          {invoiceData?.status === 1
            ? "Pending"
            : invoiceData?.status === 2
            ? "Completed"
            : invoiceData?.status === 3
            ? "Canceled"
            : "Failed"}
        </b>
        <h6>Payment Method</h6>
        <p className="mb-3">{invoiceData?.payment}</p>
      </div>
    </Col>
    <Col xs={6} md={3} lg={3}>
      <div className="invoice mb-3">
        <h6>Invoice of (USD)</h6>
        <h1 className="amount-num">${invoiceData?.amount}</h1>
      </div>
    </Col>
  </Row>
);

const InvoiceDetails = ({ invoiceData }) => (
  <Row className="mb-5">
    <Col xs={6} md={5} lg={3}>
      <div className="invoice mb-3">
        <h6>Merchant Name</h6>
        <p>{invoiceData?.merchant?.name}</p>
        <p>{invoiceData?.merchant?.email}</p>
      </div>
    </Col>
    <Col xs={6} md={4} lg={3}>
      <div className="invoice mb-3">
        <h6>Invoice date</h6>
        <p className="mb-3">{getDate(invoiceData?.created_at)}</p>
      </div>
    </Col>
  </Row>
);

const InvoiceTable = ({ invoiceData }) => (
  <Row>
    <Table responsive border={1}>
      <thead>
        <tr>
          <th>Item Detail</th>
          <th>Qty</th>
          <th>Amount</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <h4>{invoiceData?.product?.name}</h4>
            <p>{invoiceData?.product?.description?.slice(0, 50)}....</p>
          </td>
          <td>
            <span>1</span>
          </td>
          <td>
            <span>${invoiceData?.amount}</span>
          </td>
        </tr>
      </tbody>
    </Table>
  </Row>
);

const InvoiceTotal = ({ invoiceData }) => (
  <Row>
    <Col md={6} lg={8}></Col>
    <Col md={6} lg={4}>
      <div className="invoice-total mb-5">
        <div className="subtotal mb-3">
          <span>Subtotal</span>
          <span>${invoiceData?.amount}</span>
        </div>
        <div className="subtotal mb-2">
          <span>Fees</span>
          <span>${invoiceData?.fee_amount}</span>
        </div>
        <hr />
        <div className="subtotal mb-2">
          <span>Total</span>
          <span>
            $
            {parseFloat(invoiceData?.amount) +
              parseFloat(invoiceData?.fee_amount)}
          </span>
        </div>
      </div>
    </Col>
  </Row>
);

const UserInvoice = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();
  const [invoiceData, setInvoiceData] = useState({});
  const [error, setError] = useState(null);

  const getInvoice = async (id) => {
    try {
      const { data } = await getuserTransactionInvoice(id);
      setInvoiceData(data);
    } catch (error) {
      setError("Failed to load invoice data");
    } finally {
      setIsLoading(false);
    }
  };

  const downloadPDF = () => {
    const element = document.getElementById("pdf-content");
    const btn = document.getElementById("download");
    btn.classList.add("d-none");

    html2canvas(element)
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "mm", "a4");
        const width = pdf.internal.pageSize.getWidth();
        const height = pdf.internal.pageSize.getHeight();
        pdf.addImage(imgData, "PNG", 0, 0, width, height);
        pdf.save("filename.pdf");
      })
      .catch((error) => {
        console.error("Error converting to canvas:", error);
      });
  };

  useEffect(() => {
    getInvoice(id);
  }, [id]);

  if (isLoading) {
    return <LoaderSpinner />;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="user-invoice" id="pdf-content">
      <Container>
        <div className="logo mb-3">
          <img src={logo} alt="logo" />
          <div className="logo-info">
            <h1>Payment Hub</h1>
            <p>www.website.com</p>
            <p>hello@email.com</p>
            <p>+91 00000 00000</p>
          </div>
        </div>
        <div className="user-invoice-details mb-3">
          <InvoiceHeader invoiceData={invoiceData} />
          <InvoiceDetails invoiceData={invoiceData} />
          <InvoiceTable invoiceData={invoiceData} />
          <InvoiceTotal invoiceData={invoiceData} />
          <Row id="download">
            <div className="download-btn">
              <span>Thanks for the business.</span>
              <button type="submit" onClick={downloadPDF}>
                Download
              </button>
            </div>
          </Row>
        </div>
        <div className="terms">
          <p className="mb-0">Terms & Conditions</p>
          <p>Please pay within 15 days of receiving this invoice.</p>
        </div>
      </Container>
    </div>
  );
};

export default UserInvoice;
