import React, { useEffect, useState } from "react";
import "./paymentProduct.css";
import { useParams } from "react-router-dom";
import { getProducts } from "../../utils/api";

const PaymentProduct = ({
  showQr,
  setShowQr,
  payFun,
  guestName,
  guestEmail,
  guestPhone,
  setErrMsg,
}) => {
  const userToken = localStorage.getItem("paymentHub_token");
  const playerId = localStorage.getItem("playerId");
  const [product, setProduct] = useState([]);
  const guestFormData = {
    ...(playerId && { player_id: playerId }),
    ...(guestName && { name: guestName }),
    ...(guestEmail && { email: guestEmail }),
    ...(guestPhone && { phone: guestPhone }),
  };
  const userFormData = {
    ...(guestName && { name: guestName }),
    ...(guestEmail && { email: guestEmail }),
    ...(guestPhone && { phone: guestPhone }),
  };
  const { id } = useParams();
  const storedCurrency = JSON.parse(localStorage.getItem("selectedCurrency"));
  const currency = storedCurrency ? storedCurrency.symbol : "$";
  const currencyCode = storedCurrency ? storedCurrency.code : "USD";
  useEffect(() => {
    async function getProduct(id) {
      const { data } = await getProducts(id, currencyCode);
      setProduct(data);
    }
    getProduct(id);
  }, [id, currencyCode]);

  return (
    <div className="paymentCardProduct">
      <img className="w-100 mb-3" src={product?.image} alt="game_1" />
      <div className="paymentCardProductDetails">
        <div className="product-heading mb-3">
          <span>{product?.name}</span>
          <div className="d-flex flex-column">
            <span
              style={
                product?.discount !== 0
                  ? {
                      textDecoration: "line-through",
                      color: "rgb(45, 44, 121)",
                    }
                  : { color: "rgb(45, 44, 121)" }
              }
            >
              {parseFloat(product?.price).toFixed(2)} {currency}
            </span>
            {product.discount !== 0 ? (
              <span className="new-price" style={{ color: "red" }}>
                {product.discount_type !== 0
                  ? (
                      (product?.price * (100 - product?.discount)) /
                      100
                    ).toFixed(2)
                  : (product?.price - product?.discount).toFixed(2)}{" "}
                {currency}
              </span>
            ) : null}
          </div>
        </div>
        <p className="mb-3">{product?.description?.substring(0, 200)}...</p>
        <span className="mb-3 vip">
          By
          <img
            width={30}
            className="rounded-circle"
            style={{ height: "30px", margin: "0 5px" }}
            src={product?.merchant?.logo}
            alt="img"
          />
          {product?.merchant?.name}
        </span>
        <div className="product-heading mb-3">
          <span>Total Price</span>
          <span style={{ color: "#2D2C79" }}>
            {product.discount !== 0 ? (
              <span className="new-price" style={{ color: "#2D2C79" }}>
                {product.discount_type !== 0
                  ? (
                      (product?.price * (100 - product?.discount)) /
                      100
                    ).toFixed(2)
                  : (product?.price - product?.discount).toFixed(2)}{" "}
                {currency}
              </span>
            ) : (
              parseFloat(product?.price).toFixed(2) + currency
            )}
          </span>
        </div>
        {!showQr ? (
          <button
            className="mb-4 w-100"
            onClick={() => {
              if (userToken) {
                payFun(
                  product?.slug,
                  localStorage.getItem("partnerId"),
                  userFormData
                );
                setShowQr(true);
              } else if (
                guestName !== "" &&
                guestEmail !== "" &&
                guestPhone !== ""
              ) {
                payFun(
                  product?.slug,
                  localStorage.getItem("partnerId"),
                  guestFormData
                );
                setShowQr(true);
              } else {
                setErrMsg("Please fill in all the fields");
              }
            }}
          >
            Pay Now
          </button>
        ) : null}
      </div>
    </div>
  );
};

export default PaymentProduct;
