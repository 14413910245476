import React from "react";
import { Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const PaymentCard = ({ card_img, card_title, card_desc, redirectPath, id }) => {
  const navigate = useNavigate();
  return (
    <Col xs={12} md={6} lg={4} xl={4} xxl={4}>
      <div className="payment-process">
        <div className="payment-overlay">
          <div className="card-heading">
            <img src={card_img} alt="pay_1" />
            <span>{card_title}</span>
          </div>
          <p>{card_desc}</p>
          <button
            onClick={() => {
              navigate(redirectPath);
              localStorage.setItem("partnerId", id);
            }}
          >
            Proceed
          </button>
          <h5>Terms</h5>
        </div>
      </div>
    </Col>
  );
};

export default PaymentCard;
