import React, { useEffect, useState } from "react";
import { getCmsData } from "../../utils/api";

const TermsOfService = () => {
  const userToken = localStorage.getItem("paymentHub_token");
  const [faqData, setFaqData] = useState([]);
  const getFaqData = async (type, token) => {
    try {
      const { data } = await getCmsData(type, token);
      setFaqData(data);
    } catch (error) {}
  };
  useEffect(() => {
    getFaqData("3", userToken);
  }, [userToken]);
  return (
    <div className="privacy-policy mt-3">
      {faqData &&
        faqData?.map((faq, index) => (
          <div key={faq.id}>
            <span>
              {index + 1}-{faq.title}
            </span>
            <p>{faq.description}</p>
          </div>
        ))}
      {/* <div>
        <span>1. Introduction</span>
        <p>
          Welcome to Payment Hub. By using our website, you agree to follow
          these Terms of Service. If you don’t agree, please don’t use our
          services.
        </p>
      </div>
      <div>
        <span>2. Account Registration</span>
        <ul>
          <li>
            You need to create an account to use some features of our website.
          </li>
          <li>Provide accurate and current information.</li>
          <li>You’re responsible for keeping your account details secure.</li>
        </ul>
      </div>
      <div>
        <span>3. User Conduct</span>
        <ul>
          <li>Follow all laws and these terms when using our site.</li>
          <li>Don’t engage in fraudulent or harmful activities.</li>
        </ul>
      </div>
      <div>
        <span>4. Payments and Transactions</span>
        <ul>
          <li>
            You agree to pay for all services and products you buy through our
            platform.
          </li>
          <li>We use third-party services to process payments securely.</li>
        </ul>
      </div>
      <div>
        <span>5. Intellectual Property</span>
        <ul>
          <li>We own or license all content on the site.</li>
          <li>Don’t use our content without permission.</li>
        </ul>
      </div>
      <div>
        <span>6. Privacy</span>
        <ul>
          <li>Your use of our site is also governed by our Privacy Policy.</li>
          <li>
            We collect and use your information as described in the Privacy
            Policy.
          </li>
        </ul>
      </div>
      <div>
        <span>7. Limitation of Liability</span>
        <ul>
          <li>We are not responsible for indirect or accidental damages.</li>
          <li>
            Our total liability is limited to the amount you paid for the
            service or product.
          </li>
        </ul>
      </div>
      <div>
        <span>8. Termination</span>
        <ul>
          <li>
            We can suspend or terminate your account if you violate these terms.
          </li>
          <li>You can terminate your account at any time by contacting us.</li>
        </ul>
      </div>
      <div>
        <span>9. Changes to Terms</span>
        <ul>
          <li>We can update these terms and will notify you of changes.</li>
          <li>Continued use of our site means you accept the new terms.</li>
        </ul>
      </div> */}
    </div>
  );
};

export default TermsOfService;
