import React, { useEffect, useState } from "react";
import Select from "react-select";
import "./CurrencySelector.css";

const CurrencySelector = ({ currencies, onChangeCurrency }) => {
  const [selectedCurrency, setSelectedCurrency] = useState(null);

  useEffect(() => {
    const storedCurrency = localStorage.getItem("selectedCurrency");
    if (storedCurrency) {
      setSelectedCurrency(JSON.parse(storedCurrency));
    } else {
      setSelectedCurrency(currencies[0]);
    }
  }, [currencies]);

  const handleChange = (selectedOption) => {
    const newCurrency = currencies.find(
      (currency) => currency.code === selectedOption.value
    );
    setSelectedCurrency(newCurrency);
    localStorage.setItem("selectedCurrency", JSON.stringify(newCurrency));
    onChangeCurrency(newCurrency.code, newCurrency.symbol);
    window.location.reload();
  };

  const options = currencies.map((currency) => ({
    value: currency.code,
    label: (
      <div className="currency-option">
        <img
          src={currency.flag}
          alt={`${currency.code} flag`}
          className="currency-flag"
        />
        {currency.code}
      </div>
    ),
  }));

  const customSingleValue = (props) => (
    <div className="currency-single-value">
      <img
        src={props.data.flag}
        alt={`${props.data.value} flag`}
        className="currency-flag"
      />
      {props.data.value}
    </div>
  );

  const customStyles = {
    control: (provided) => ({
      ...provided,
      display: "flex",
      alignItems: "center",
    }),
    singleValue: (provided) => ({
      ...provided,
      display: "flex",
      alignItems: "center",
    }),
    option: (provided) => ({
      ...provided,
      display: "flex",
      alignItems: "center",
    }),
  };

  return (
    <Select
      options={options}
      value={
        selectedCurrency
          ? {
              value: selectedCurrency.code,
              label: selectedCurrency.code,
              flag: selectedCurrency.flag,
            }
          : null
      }
      onChange={handleChange}
      styles={customStyles}
      components={{ SingleValue: customSingleValue }}
      className="currency-selector"
    />
  );
};

export default CurrencySelector;
