import React from "react";
import { Row } from "react-bootstrap";
import InstructionComp from "../InstructionComp/InstructionComp";
import copy from "../../assets/payment/copy.png";
import "./paymentInstructions.css";
const PaymentInstructionsWallet = ({
  title,
  pay_title,
  pay_subTitle,
  qr_img,
  pay_code,
  paymentData,
}) => {
  return (
    <div className="payment-instructions">
      <h1>{title}</h1>
      <Row>
        <InstructionComp
          num={1}
          title={'Open Your E-Wallet App:'}
          desc={
            "Open the e-wallet application on your smartphone or device. Ensure your app is linked to a valid payment source with sufficient funds."
          }
        />
        <InstructionComp
          num={2}
          title={'Scan the QR Code:'}
          desc={
            "Within your e-wallet app, select the option to scan a QR code. Point your device’s camera at the QR code displayed on your screen."
          }
        />
        <InstructionComp
          num={3}
          title={'Confirm the Payment:'}
          desc={
            "Once the QR code is scanned, the payment details will automatically appear in your e-wallet app. Review the payment details, then confirm the payment."
          }
        />
        {/* <InstructionComp
          num={4}
          desc={"Follow the cashier’s instructions to complete the payment"}
        /> */}
        {/* <InstructionComp
          num={5}
          title="Step 3"
          desc={
            "The cashier will enter the code or scan the QR code and confirm the payment amount with you"
          }
        /> */}
      </Row>
      <div className="payment-result mt-5 text-center">
        <h2 className="mb-3">{pay_title}</h2>
        <p>{pay_subTitle}</p>
        {qr_img &&
          (paymentData?.data?.data?.walletQr ? (
            <img
              className="qr_img"
              src={paymentData?.data?.data?.walletQr}
              alt="qr code"
            />
          ) : (
            <h1>loading...</h1>
          ))}
        {pay_code && (
          <div className="pay-code">
            <span>Code</span>
            {paymentData?.data?.data?.referenceNumber ? (
              <span className="copy-code">
                <img style={{ cursor: "pointer" }} src={copy} alt="copy" />
                <span>{paymentData?.data?.data?.referenceNumber}</span>
              </span>
            ) : (
              <span>loading...</span>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default PaymentInstructionsWallet;
