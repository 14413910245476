import React, { useState } from "react";
import { getDate } from "../../utils/helpers";
import InvoiceModal from "../Modals/InvoiceModal";
import { Modal } from "react-bootstrap";
import eye from "../../assets/profile/eye.png";
import view_icon from "../../assets/profile/view.png";
import { Link } from "react-router-dom";

const TransactionRecord = ({ transaction }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <tr>
      <td>{getDate(transaction?.created_at)}</td>
      <td>{transaction?.merchant?.name}</td>
      <td>{transaction?.product?.name}</td>
      <td>
        <span
          className="status"
          style={
            transaction?.status === 1
              ? {
                  backgroundColor: "rgba(255, 221, 85, 0.36)",
                  color: "#FFAA05",
                }
              : transaction?.status === 2
              ? { backgroundColor: "#D0FFD5", color: "#1BBE01" }
              : transaction?.status === 3
              ? { backgroundColor: "#FFE2E5", color: "#FF0000" }
              : { backgroundColor: "#FFE2E5", color: "#FF0000" }
          }
        >
          {transaction?.status === 1
            ? "Pending"
            : transaction?.status === 2
            ? "Completed"
            : transaction?.status === 3
            ? "Canceled"
            : "Failed"}
        </span>
      </td>
      <td style={{ color: "#2D2C79" }}>
        $ {parseFloat(transaction?.amount).toFixed(2)}
      </td>
      <td > 
        <img
          onClick={() => handleShow(transaction?.id)}
          style={{ cursor: "pointer" }}
          src={eye}
          alt="eye"
        />
  {   transaction?.status === 2   && <Link

          to={`/userInvoice/${transaction?.id}`}
          target="_blank"
          className="download-btn"
        >
          <img style={{ cursor: "pointer" }} src={view_icon} alt="view_icon" />
        </Link>}

        <Modal className="invoice-modal" show={show}>
          <InvoiceModal
            transactionId={transaction?.id}
            handleClose={handleClose}
          />
        </Modal>
      </td>
    </tr>
  );
};

export default TransactionRecord;
