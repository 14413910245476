import React, { useEffect, useState, useCallback } from "react";
import {
  Container,
 
  Nav,
  NavDropdown,
  Navbar,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { getNotifications, getUserProfile, userLogout } from "../../utils/api";
import LanguageSelector from "../Language-selector";
import { useTranslation } from "react-i18next";
import logo from "../../assets/logo.png";
import avatar from "../../assets/Avatar.png";
import en_icon from "../../assets/en.svg";
import ar_icon from "../../assets/ar.png";
import profile_1 from "../../assets/navbar/profile.png";
import profile_2 from "../../assets/navbar/wishlist.png";
import profile_3 from "../../assets/navbar/data-transfer.png";
import profile_4 from "../../assets/navbar/help.png";
import logout from "../../assets/navbar/log_out.png";
import notify_icon from "../../assets/navbar/bell.svg";
import "./navbar.css";
import { currencies } from "../../utils/Currencies";
import CurrencySelector from "../CurrencySelector";
import SearchBar from "./SearchBar";
import SearchBarResults from "./SearchBarResults";

const MainNavbar = () => {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  const isArabic = currentLang === "ar";
  const [inputSearch, setInputSearch] = useState("");
  const [results, setResults] = useState([]);
  const languages = [
    { code: "en", flag: en_icon },
    { code: "ar", flag: ar_icon },
  ];
  const navigate = useNavigate();
  const token = localStorage.getItem("paymentHub_token");
  const [userData, setUserData] = useState(null);

  const [currentCurrency, setCurrentCurrency] = useState(() => {
    const storedCurrency = localStorage.getItem("selectedCurrency");
    return storedCurrency ? JSON.parse(storedCurrency) : currencies[0];
  });

  const handleCurrencyChange = (code, symbol) => {
    setCurrentCurrency({ code, symbol });
  };

  const handleLogout = useCallback(async () => {
    try {
      const response = await userLogout(token);
      if (response.message) {
        localStorage.removeItem("paymentHub_token");
        localStorage.removeItem("partnerId");
        localStorage.removeItem("merchantId");
        localStorage.removeItem("playerId");
        localStorage.removeItem("user_id");
        navigate("/signin");
      }
    } catch (error) {}
  }, [token, navigate]);
  const getUserData = useCallback(
    async (token) => {
      try {
        const { data } = await getUserProfile(token);
        setUserData(data);
        localStorage.setItem("user_id", data?.id);
      } catch (error) {
        handleLogout();
        localStorage.removeItem("paymentHub_token");
        window.location.reload();
      }
    },
    [handleLogout]
  );

  useEffect(() => {
    if (token) {
      getUserData(token);
    }
  }, [token, getUserData, currentCurrency]);


  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    const fetchNotifications = async () => {
   
      try {
        const response =await getNotifications(token) 
        console.log({response})
        // const data = await response.json();
        setNotifications(response?.data?.items);
      } catch (error) {
        console.error('Error fetching notifications:', error);
      }
    };
  
    fetchNotifications();
  }, []);
  
  
  return (
    <Navbar bg="light" expand="lg" fixed="top" dir={isArabic ? "rtl" : "ltr"}>
      <Container fluid>
        <Navbar.Brand>
          <Link to="/home">
            <img src={logo} alt="logo" />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbar-nav" />
        <Navbar.Collapse id="navbar-nav">
          <Nav className="mr-auto">
            {/* search box */}
            <div className="search-bar-container">
              <SearchBar
                inputSearch={inputSearch}
                setInputSearch={setInputSearch}
                setResults={setResults}
              />
              {inputSearch.length > 2 && (
                <SearchBarResults
                  results={results}
                  setInputSearch={setInputSearch}
                />
              )}
            </div>
            {/* notifications */}
            <div className="notify-container">
    <img src={notify_icon} alt="notify_icon" />
    <NavDropdown
      id="nav-dropdown-dark-example"
      menuVariant="dark"
    >
      {notifications?.length > 0 ? 
        notifications.map((notification, index) => 
          (

          <NavDropdown.Item key={index}>
            {notification?.message?.message} {/* Customize based on notification content */}
          </NavDropdown.Item>
        )
      )
  
    : (
   
        <NavDropdown.Item>No new notifications</NavDropdown.Item>
      )}
    </NavDropdown>
  </div>
            {token ? (
              <div className="notify-container">
                <img
                  width={48}
                  height={48}
                  className="user-avatar rounded-circle"
                  src={userData?.avatar || avatar}
                  alt="User Avatar"
                />
                <NavDropdown
                  id="nav-dropdown-dark-example"
                  title={`${userData?.first_name || ""} ${
                    userData?.last_name || ""
                  }`}
                  menuVariant="dark"
                  className="text-capitalize user-menu"
                >
                  <NavDropdown.Item
                    onClick={() => navigate("/userProfile/accountDetails")}
                  >
                    <img src={profile_1} alt="Profile" />
                    <span>{t("View Account")}</span>
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    onClick={() => navigate("/userProfile/wishlist")}
                  >
                    <img src={profile_2} alt="Wishlist" />
                    <span>{t("Wishlist")}</span>
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    onClick={() => navigate("/UserProfile/transactions")}
                  >
                    <img src={profile_3} alt="Transactions" />
                    <span>{t("Transaction Record")}</span>
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    onClick={() => navigate("/userProfile/customerSupport")}
                  >
                    <img src={profile_4} alt="Help" />
                    <span>{t("Help")}</span>
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item onClick={handleLogout}>
                    <img src={logout} alt="Log Out" />
                    <span style={{ color: "#AA3431" }}>{t("Log Out")}</span>
                  </NavDropdown.Item>
                </NavDropdown>
              </div>
            ) : (
              <div className="sign-btn">
                <Link to="/signup">{t("Sign Up")}</Link>
                <Link className="signin-btn" to="/signin">
                  {t("Log In")}
                </Link>
              </div>
            )}
            <CurrencySelector
              currencies={currencies}
              onChangeCurrency={handleCurrencyChange}
            />
            <LanguageSelector languages={languages} />
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default MainNavbar;
