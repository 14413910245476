import React, { useEffect, useState } from "react";
import "./merchantHeading.css";
import { Col, Row } from "react-bootstrap";
import MerchantModal from "../MerchantModal/MerchantModal";
import { useTranslation } from "react-i18next";
import { MdEdit } from "react-icons/md";
import { getMerchantPlayerId } from "../../utils/api";

const MerchantHeading = ({
  merchantDetails,
  merchantName,
  playerId,
  setPlayerId,
}) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const token = localStorage.getItem("paymentHub_token");
  useEffect(() => {
    const getMerchantIdApi = async (merchantId, token) => {
      try {
        const { data } = await getMerchantPlayerId(merchantId, token);
        setPlayerId(data?.player_id);
      } catch (error) {}
    };
    getMerchantIdApi(merchantName, token);
  }, [merchantName, token, setPlayerId]);
  return (
    <Row>
      <Col lg={12}>
        <div className="merchant-heading">
          <div className="product-merchant d-flex align-items-center gap-2 mb-3">
            <img
              width={45}
              height={45}
              className="border rounded-circle"
              src={merchantDetails?.logo}
              alt="logo"
            />
            <h1 className="mb-0">{merchantDetails?.name}</h1>
          </div>
          {/* <p className="mb-3 text-secondary">{merchantDetails?.description}</p> */}
          {playerId && playerId !== "null" ? (
            <p className="d-flex align-items-center mb-3">
              {t("Player ID")} <span style={{ color: "red" }}>*</span>
              <b className="mx-2">{playerId}</b>
              <MdEdit
                onClick={handleShow}
                width={35}
                height={35}
                cursor="pointer"
              />
              <MerchantModal show={show} handleClose={handleClose} />
            </p>
          ) : (
            <>
              <button className="player-btn" onClick={handleShow} type="button">
                {t("Enter Your Player ID")}
              </button>
              <MerchantModal show={show} handleClose={handleClose} />
            </>
          )}
        </div>
      </Col>
    </Row>
  );
};

export default MerchantHeading;
