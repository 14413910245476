import React from "react";
import "./faq.css";
import { Col } from "react-bootstrap";
import { FaQuestion } from "react-icons/fa";

const Faq = ({ title, desc }) => {
  return (
    <Col xs={12} md={6} lg={4} xl={4} xxl={4}>
      <div className="faq-item">
        <FaQuestion />
        <h2>{title}</h2>
        <p>{desc}</p>
      </div>
    </Col>
  );
};

export default Faq;
