import React, { useState } from "react";
import { Col, Container, Modal, Row, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "./newTicket.css";
import { updateTicket } from "../../utils/api";
import TicketSuccess from "../Modals/TicketSuccess";
import star from "../../assets/struss.svg";
import { useNavigate } from "react-router-dom";

const NewTicket = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isLoading] = useState(false);
  const [docs, setDocs] = useState(null); // Store file object
  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [phone, setphone] = useState("");
  const [email, setEmail] = useState("");
  const [reason, setReason] = useState("");
  const [errMsgs, setErrMsgs] = useState({});
  const token = localStorage.getItem("paymentHub_token");
  // show otp modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let formData = {
        title: title,
        description: desc,
        phone: phone,
        email: email,
        reason: reason,
        docs: docs,
      };
      await updateTicket(token, formData);
      handleShow();
      setTitle("");
      setDesc("");
      setphone("");
      setEmail("");
      setDocs(null);
      setErrMsgs({});
      setTimeout(() => {
        navigate("/userProfile/customerSupport");
      }, 2000);
    } catch (error) {
      setErrMsgs(error.response.data.errors);
    }
  };

  const handleImageChange = (event) => {
    setDocs([...event.target.files]);
  };

  const clearValues = () => {
    setTitle("");
    setDesc("");
    setphone("");
    setEmail("");
    setReason("");
    setDocs([]);
    setErrMsgs({});
  };

  return (
    <div className="user-transactions user-profile add-ticket">
      <Container fluid>
        <h1 className="mb-5">{t("Submit Ticket")}</h1>
        <Row className="mb-3">
          <Col lg={3}>
            <div className="d-flex align-items-start justify-content-start gap-1">
              <label>{t("Ticket Subject")}</label>
              <img src={star} alt="star" width={10} className="mx-1" />
            </div>
          </Col>
          <Col lg={8}>
            <input
              required
              type="text"
              className="mb-2"
              placeholder="Ticket Subject"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            {errMsgs.title && (
              <p className="error text-danger">{errMsgs.title}</p>
            )}
          </Col>
        </Row>
        <Row className="mb-3">
          <Col lg={3}>
            <label>{t("Email")}</label>
          </Col>
          <Col lg={8}>
            <input
              type="email"
              className="mb-2"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {errMsgs.email && (
              <p className="error text-danger">{errMsgs.email}</p>
            )}
          </Col>
        </Row>
        <Row className="mb-3">
          <Col lg={3}>
            <label>{t("Phone Number")}</label>
          </Col>
          <Col lg={8}>
            <input
              type="tel"
              className="mb-2"
              placeholder="Phone Number"
              value={phone}
              onChange={(e) => setphone(e.target.value)}
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col lg={3}>
            <div className="d-flex align-items-start justify-content-start gap-1">
              <label>{t("Ticket Reason")}</label>
              <img src={star} alt="star" width={10} className="mx-1" />
            </div>
          </Col>
          <Col lg={8}>
            <select
              className="form-select border-0 fw-bold text-capitalize"
              aria-label="Default select example"
              style={{
                fontSize: "16px",
                fontWeight: "bold",
                color: "#525352",
                backgroundColor: "rgba(217, 217, 217, 0.23)",
                padding: "15px",
              }}
              onChange={(e) => setReason(e.target.value)}
            >
              <option selected disabled value="">
                {t("Reason")}
              </option>
              <option
                style={{
                  color: "#2d2c79",
                  fontWeight: "bold",
                }}
                value={1}
              >
                payment issues
              </option>
              <option
                style={{
                  color: "#2d2c79",
                  fontWeight: "bold",
                }}
                value={2}
              >
                account issues
              </option>
              <option
                style={{
                  color: "#2d2c79",
                  fontWeight: "bold",
                }}
                value={3}
              >
                technical issues
              </option>
              <option
                style={{
                  color: "#2d2c79",
                  fontWeight: "bold",
                }}
                value={4}
              >
                product issues
              </option>
              <option
                style={{
                  color: "#2d2c79",
                  fontWeight: "bold",
                }}
                value={5}
              >
                suggestions
              </option>
            </select>
          </Col>
          <Row>
            <Col lg={3}></Col>
            <Col lg={8}>
              {errMsgs.reason && (
                <p className="error text-danger mx-2 mt-2">{errMsgs.reason}</p>
              )}
            </Col>
          </Row>
        </Row>
        <Row className="mb-3">
          <Col lg={3}>
            <div className="d-flex align-items-start justify-content-start gap-1">
              <label>{t("Description")}</label>
              <img src={star} alt="star" width={10} className="mx-1" />
            </div>
          </Col>
          <Col lg={8}>
            <textarea
              className="w-100"
              style={{ resize: "none" }}
              placeholder="Details"
              rows={7}
              value={desc}
              onChange={(e) => setDesc(e.target.value)}
            ></textarea>
            {errMsgs.description && (
              <p className="error text-danger">{errMsgs.description}</p>
            )}
          </Col>
        </Row>
        <Row className="mb-3">
          <Col lg={3}>
            <label>{t("Upload Files")}</label>
          </Col>
          <Col lg={8}>
            <Row>
              <Col lg={12}>
                <input type="file" multiple onChange={handleImageChange} />
                <p
                  style={{ fontSize: "12px", marginTop: "5px" }}
                  className="text-danger fw-bold"
                >
                  Kindly Upload any files related to your ticket to help us
                  solve your issue
                </p>
                {errMsgs.docs && (
                  <p className="error text-danger">{errMsgs.docs}</p>
                )}
                {/* {docs && docs.length > 0
                  ? docs.map((doc, index) => (
                      <div
                        className="d-flex align-items-center"
                        style={{ cursor: "pointer" }}
                        key={index}
                      >
                        <p>{doc.name}</p>
                        <i
                          className="fa fa-times"
                          onClick={() => setDocs(docs.filter((d) => d !== doc))}
                        ></i>
                      </div>
                    ))
                  : null} */}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12} lg={11}>
            <hr />
            <div className="security-btns">
              <button
                className="signin-btn discard"
                type="reset"
                onClick={clearValues}
              >
                {t("Discard")}
              </button>
              <button
                className="signin-btn"
                type="submit"
                disabled={isLoading}
                onClick={handleSubmit}
              >
                {isLoading ? (
                  <Spinner animation="border" role="status"></Spinner>
                ) : (
                  t("Submit")
                )}
              </button>
              <Modal show={show}>
                <TicketSuccess handleClose={handleClose} />
              </Modal>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default NewTicket;
