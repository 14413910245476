import React, { useState } from "react";
import email_icon from "../../assets/email.png";
import password_icon from "../../assets/Password.png";
import hidden_icon from "../../assets/hidden_icon.svg";
import visible_icon from "../../assets/visible_icon.svg";
import phone_icon from "../../assets/phone.png";
import person_icon from "../../assets/person.png";
import { AuthLogic } from "../../utils/api";
import FormSocialMedia from "../FormSocialMedia/FormSocialMedia";
import FormsHeading from "../FormsHeading/FormsHeading";
import MyVerticallyCenteredModal from "../MyVerticallyCenteredModal/MyVerticallyCenteredModal";
import { useTranslation } from "react-i18next";
import star from "../../assets/struss.svg";
import { Modal } from "react-bootstrap";
import TermsOfService from "../TermsOfService/TermsOfService";
import CountryCodeDropdown from "../CountryCodeDropdown";

const Signup = () => {
  const [selectedCountryCode, setSelectedCountryCode] = useState(null);
  const handleCountryCodeChange = (selectedOption) => {
    setSelectedCountryCode(selectedOption);
  };
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { t } = useTranslation();
  // show modal when user register successfully
  const [modalShow, setModalShow] = useState(false);
  // state to toggle password and confirm password
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  // toggle password function
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  // toggle confirm password function
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  // state register inputs values
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    password: "",
    password_confirmation: "",
  });
  const [agreement, setAgreement] = useState(false);
  const [passwordErrors, setPasswordErrors] = useState({});
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleSignUp = async (e) => {
    e.preventDefault();
    // Make API request to register user
    try {
      if (agreement) {
        const response = await AuthLogic("register", formData);
        setPasswordErrors({});
        if (response.status === 200) {
          setModalShow(true);
        }
      } else {
        alert("Please Agree With Our Terms And Conditions");
      }
    } catch (error) {
      setPasswordErrors(error.response.data.errors);
    }
  };

  return (
    <div
      className="signin-form signup-form"
      dir={localStorage.getItem("i18nextLng") === "ar" ? "rtl" : "ltr"}
    >
      <FormsHeading heading={t("Sign Up")} />
      <form onSubmit={handleSignUp}>
        <div className="user-info-group">
          <div className="input-group mb-2">
            <div className="d-flex align-items-center gap-0 mb-2">
              <label className="mb-0" htmlFor="firstName">
                {t("First Name")}
              </label>
              <img src={star} alt="star" width={10} className="mx-1" />
            </div>
            <span></span>
            <div className="form-input">
              <img src={person_icon} alt="person_icon" />
              <input
                autoSave="false"
                required={true}
                id="firstName"
                type="text"
                placeholder={t("First Name")}
                name="first_name"
                value={formData.first_name}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="input-group mb-2">
            <div className="d-flex align-items-center gap-0 mb-2">
              <label className="mb-0" htmlFor="lastName">
                {t("Last Name")}
              </label>
              <img src={star} alt="star" width={10} className="mx-1" />
            </div>
            <div className="form-input">
              <img src={person_icon} alt="email_icon" />
              <input
                autoSave="false"
                required={true}
                id="lastName"
                type="text"
                placeholder={t("Last Name")}
                name="last_name"
                value={formData.last_name}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
        <div className="input-group mb-2">
          <div className="d-flex align-items-center gap-0 mb-2">
            <label className="mb-0" htmlFor="email">
              {t("Email")}
            </label>
            <img src={star} alt="star" width={10} className="mx-1" />
          </div>
          <div
            className="form-input"
            style={passwordErrors?.email ? { border: "2px solid #EB0000" } : {}}
          >
            <img src={email_icon} alt="email_icon" />
            <input
              style={passwordErrors?.email ? { color: "#EB0000" } : {}}
              autoSave="false"
              required={true}
              id="email"
              type="email"
              placeholder={t("Enter Your Email")}
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
          </div>
          <span className="text-danger">{passwordErrors?.email}</span>
        </div>
        <div className="input-group mb-2">
          <div className="d-flex align-items-center gap-0 mb-2">
            <label className="mb-0" htmlFor="email">
              {t("Phone Number")}
            </label>
            <img src={star} alt="star" width={10} className="mx-1" />
          </div>
          <div className="d-flex align-items-center gap-2">
            <div
              className="form-input"
              style={
                passwordErrors?.phone
                  ? { border: "2px solid #EB0000", width: "80%" }
                  : { width: "80%" }
              }
            >
              <img src={phone_icon} alt="email_icon" className="mx-2" />
              <input
                style={passwordErrors?.phone ? { color: "#EB0000" } : {}}
                autoSave="false"
                required={true}
                id="phone"
                placeholder={t("Enter Your Phone Number")}
                name="phone"
                value={formData.phone}
                onChange={handleChange}
              />
            </div>
            <CountryCodeDropdown
              selectedCountryCode={selectedCountryCode}
              handleCountryCodeChange={handleCountryCodeChange}
            />
          </div>
          <span className="text-danger">{passwordErrors?.phone}</span>
        </div>
        <div className="input-group mb-2">
          <label htmlFor="password">{t("Password")}</label>
          <div
            className="form-input"
            style={
              passwordErrors?.password ? { border: "2px solid #EB0000" } : {}
            }
          >
            <img src={password_icon} alt="password_icon" />
            <input
              autoSave="false"
              style={passwordErrors?.password ? { color: "#EB0000" } : {}}
              required={true}
              id="password"
              type={showPassword ? "text" : "password"}
              name="password"
              value={formData.password}
              onChange={handleChange}
              placeholder={t("Enter Your Password")}
            />
            <img
              onClick={togglePasswordVisibility}
              className="pass_hidden"
              src={showPassword ? visible_icon : hidden_icon}
              alt="hidden_icon"
            />
          </div>
          {passwordErrors?.password ? (
            <div className="alert alert-danger mt-2">
              {passwordErrors?.password.map((error, index) => (
                <ul>
                  <li style={{ color: "#EB0000" }} key={index}>
                    {error}
                  </li>
                </ul>
              ))}
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="input-group mb-2">
          <label htmlFor="cpassword">{t("Confirm Password")}</label>
          <div className="form-input">
            <img src={password_icon} alt="password_icon" />
            <input
              autoSave="false"
              required={true}
              id="confirmPassword"
              type={showConfirmPassword ? "text" : "password"}
              name="password_confirmation"
              value={formData.password_confirmation}
              onChange={handleChange}
              placeholder={t("Retype Your Password")}
            />
            <img
              onClick={toggleConfirmPasswordVisibility}
              className="pass_hidden"
              src={showConfirmPassword ? visible_icon : hidden_icon}
              alt="hidden_icon"
            />
          </div>
        </div>
        <div className="check-forget my-3 mb-0">
          <div className="check-box">
            <div className="checkbox-inp">
              <input
                type="checkbox"
                id="remember-me"
                checked={agreement}
                onChange={(e) => setAgreement(e.target.checked)}
              />
              <label htmlFor="remember-me">{t("I Agree")}</label>
            </div>
            <span onClick={() => handleShow()}>
              {t("Terms and conditions")}
            </span>
            <Modal show={show} onHide={handleClose} style={{ zIndex: "9999" }}>
              <Modal.Body
                style={{
                  height: "400px",
                  overflowY: "scroll",
                  borderRadius: "10px",
                  padding: "0 15px",
                }}
              >
                <TermsOfService />
              </Modal.Body>
            </Modal>
          </div>
        </div>
        <button className="signin-btn my-3" type="submit">
          {t("Sign Up")}
        </button>
      </form>
      <MyVerticallyCenteredModal
        show={modalShow}
        setshow={setModalShow}
        verifyemail={formData.email}
      />
      <FormSocialMedia
        navigatePath={"signin"}
        navigateTitle={t("Log In")}
        navigateSubTitle={t("Already Have An Account ?")}
        showNavigate={true}
      />
    </div>
  );
};

export default Signup;
