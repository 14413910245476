import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { IoClose } from "react-icons/io5";
import { useParams } from "react-router-dom";
import { addReview } from "../../utils/api";
import Rating from "react-rating";
import { FaStar } from "react-icons/fa";
import { CiStar } from "react-icons/ci";

const AddReview = ({ handleClose, reload, setReload }) => {
  const { t } = useTranslation();
  const { productId } = useParams();
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");
  // const [anon, setAnon] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const userToken = localStorage.getItem("paymentHub_token");
  const handleRatingChange = (rate) => {
    setRating(rate);
  };
  const addReviewFun = async () => {
    try {
      let formData = {
        rating,
        comment,
      };
      if (formData.rating !== 0) {
        await addReview(productId, formData, userToken);
        handleClose();
        setReload(!reload);
      } else {
        setErrMsg(t("Please select rating"));
      }
    } catch (error) {
      setErrMsg(error.response.data.message);
      setTimeout(() => {
        handleClose();
      }, [1000]);
      setReload(!reload);
    }
  };

  return (
    <div
      className="signin-form"
      style={
        localStorage.getItem("i18nextLng") === "ar"
          ? {
              direction: "ltr",
            }
          : { direction: "rtl" }
      }
    >
      <div className="modal-heading">
        <span onClick={handleClose}>
          <IoClose
            width={37}
            height={37}
            style={{ fontSize: "34px", color: "#2D2C79", cursor: "pointer" }}
          />
        </span>
        <h1>{t("Reviews")}</h1>
      </div>
      <Rating
        initialRating={rating}
        onChange={handleRatingChange}
        emptySymbol={<CiStar className="empty-star" />}
        fullSymbol={<FaStar className="fill-star" />}
        direction={localStorage.getItem("i18nextLng") === "ar" ? "rtl" : "ltr"}
        className="d-block mb-4"
      />
      <textarea
        className="modal-textarea mb-0"
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        required={true}
      ></textarea>
      {errMsg && (
        <p style={{ color: "red", fontSize: "15px", textAlign: "end" }}>
          {errMsg}
        </p>
      )}
      {/* <div
        className="checkbox-inp mb-3"
        style={
          localStorage.getItem("i18nextLng") === "ar"
            ? {
                direction: "rtl",
              }
            : { direction: "ltr" }
        }
      >
        <input
          required={true}
          value={anon}
          onChange={() => setAnon(!anon)}
          type="checkbox"
          id="anon"
          className="mx-2"
        />
        <label
          htmlFor="anon"
          style={{
            fontSize: "15px",
            fontWeight: "bold",
            color: "#2D2C79",
          }}
        >
          {t("Anonymous")}
        </label>
      </div> */}
      <button className="signin-btn mt-3" type="submit" onClick={addReviewFun}>
        {t("Post")}
      </button>
    </div>
  );
};

export default AddReview;
