import React from "react";
import Select from "react-select";
import countryCodes from "./CountryCodeDropdownData";

const CountryCodeDropdown = ({
  selectedCountryCode,
  handleCountryCodeChange,
}) => {
  return (
    <Select
      value={selectedCountryCode}
      onChange={handleCountryCodeChange}
      options={countryCodes}
      isSearchable
      placeholder="code"
      className="country-code-dropdown"
      classNamePrefix="select"
    />
  );
};

export default CountryCodeDropdown;
