import React, { useState } from "react";
import "./verifyMessage.css";
import { AuthLogic } from "../../utils/api";
import { Alert } from "react-bootstrap";
import { IoClose } from "react-icons/io5";

const VerifyMessage = (props) => {
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  // resend email verification
  const resendEmail = async () => {
    try {
      const response = await AuthLogic("resend-verification-email", {
        email: props.verifyemail,
      });
      setErrorMsg("");
      setSuccessMsg(response.data.message);
    } catch (error) {
      setErrorMsg(error.response.data.message);
      setSuccessMsg("");
    }
  };
  return (
    <div className="signin-form resend-email">
      <span className="close" onClick={() => props.setshow(!props.show)}>
        <IoClose />
      </span>
      <p className="success-text mb-4">Verify your Email</p>
      {errorMsg ? (
        <Alert variant={errorMsg ? "danger" : successMsg ? "success" : ""}>
          {errorMsg ? errorMsg : successMsg}
        </Alert>
      ) : (
        ""
      )}
      <p className="verify-text mb-2">
        We have sent an email to <b>{props.verifyemail}</b>
      </p>
      <p className="verify-text">
        You need to verify your email to continue
        <br /> if you have not received the verification email
        <br /> enter resend email button .
      </p>
      <span className="resend-email" onClick={resendEmail}>
        Resend Email
      </span>
    </div>
  );
};

export default VerifyMessage;
