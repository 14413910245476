const countryCodes = [
  { value: "+1", label: "ARE +1" },
  { value: "+44", label: "UAE +44" },
  { value: "+91", label: "KSA +91" },
  { value: "+61", label: "IRL +61" },
  { value: "+81", label: "UK +81" },
  // Add more country codes as needed
];

export default countryCodes;
