import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Modal, Row, Spinner } from "react-bootstrap";
import "./userProfile.css";
import avatar from "../../assets/Avatar.png";
import icon_edit from "../../assets/profile/Icon_Edit.png";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LoaderSpinner from "../LoaderSpinner/LoaderSpinner";
import { getUserProfile, updateUserProfile } from "../../utils/api";
import ChangePhone from "../Modals/ChangePhone";
import ChangeEmail from "../Modals/ChangeEmail";
import OtpPhone from "../Modals/OtpPhone";
import UpdateProfileSuccess from "../Modals/UpdateProfileSuccess";
import { maskString } from "../../utils/helpers";

const UserProfile = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("paymentHub_token");
  const inputref = useRef(null);
  // show change phone modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  //show change email modal
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  // show otp modal
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  // show update profile success modal
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const [isLoading, setIsLoading] = useState(true);
  const [isLoading1, setIsLoading1] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const { t } = useTranslation();
  const [photo, setPhoto] = useState(null); // Store file object
  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [dob, setDob] = useState("");
  const [gender, setGender] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (isDisabled) {
        setIsLoading1(true);
        const formData = {
          avatar: photo,
          first_name: fName,
          last_name: lName,
          age: dob,
          gender: gender,
        };
        if (typeof photo === "string") {
          delete formData.avatar;
        }
        await updateUserProfile(formData);
        setIsLoading1(false);
        handleShow3();
        setIsDisabled(false);
      }
      // Handle success
    } catch (error) {
      // Handle error
      setIsLoading1(false);
      setErrMsg(error.response.data.errors);
    }
  };
  const getUserData = async (token) => {
    try {
      const { data } = await getUserProfile(token);
      setIsLoading(false);
      setPhoto(data.avatar);
      setFName(data.first_name);
      setLName(data.last_name);
      setDob(data.age);
      setGender(data.gender);
      setEmail(data.email);
      setPhone(data.phone);
    } catch (error) {
      setIsLoading(false);
      if (error.response.status === 401) {
        localStorage.removeItem("paymentHub_token");
        navigate("/signin");
      }
    }
  };
  useEffect(() => {
    if (token) {
      getUserData(token);
    }
  }, [isLoading, token]);
  const handleImgClick = () => {
    inputref?.current?.click();
  };
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setPhoto(file);
  };

  return (
    <div className="user-transactions user-profile">
      <Container fluid>
        <h1 className="mb-3">{t("Account Details")}</h1>
        {isLoading ? (
          <LoaderSpinner />
        ) : (
          <>
            <Row className="mb-3">
              <Col lg={3}>
                <label>{t("Photo")}</label>
              </Col>
              <Col lg={8}>
                <div className="profile-img">
                  <img
                    src={
                      typeof photo == "string"
                        ? photo
                        : typeof photo == "object"
                        ? URL.createObjectURL(photo)
                        : avatar
                    }
                    alt="avatar"
                    className="img"
                  />
                  {errMsg.avatar && (
                    <div className="mt-2 text-danger">
                      {errMsg.avatar.map((error) => (
                        <p className="mb-2" key={error}>
                          {error}
                        </p>
                      ))}
                    </div>
                  )}
                  <div
                    className="img-edit"
                    style={
                      localStorage.getItem("i18nextLng") === "en-US" || "en"
                        ? { right: "-30px" }
                        : { left: "-30px" }
                    }
                  >
                    <img
                      onClick={handleImgClick}
                      ref={inputref}
                      src={icon_edit}
                      alt="edit"
                    />
                    {/* <span>{t("Remove")}</span> */}
                  </div>
                  {/* Hidden file input */}
                  <input
                    ref={inputref}
                    onChange={(e) => {
                      handleImageChange(e);
                      setIsDisabled(true);
                    }}
                    type="file"
                    accept="image/*"
                    id="fileInput"
                    style={{ display: "none" }}
                  />
                </div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col lg={3}>
                <label>{t("Name")}</label>
              </Col>
              <Col lg={8}>
                <Row>
                  <Col md={6} lg={6}>
                    <input
                      required
                      value={fName}
                      onChange={(e) => {
                        setFName(e.target.value);
                        setIsDisabled(true);
                      }}
                      type="text"
                      className="mb-2 form-input"
                    />
                    {errMsg.first_name && errMsg.first_name ? (
                      <p className="text-danger">{errMsg.first_name[0]}</p>
                    ) : null}
                  </Col>
                  <Col md={6} lg={6}>
                    <input
                      required
                      value={lName}
                      onChange={(e) => {
                        setLName(e.target.value);
                        setIsDisabled(true);
                      }}
                      type="text"
                      className="mb-2 form-input"
                    />
                    {errMsg.last_name && errMsg.last_name ? (
                      <p className="text-danger">{errMsg.last_name[0]}</p>
                    ) : null}
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col lg={3}>
                <label>{t("Date of birth")}</label>
              </Col>
              <Col lg={8}>
                <Row>
                  <Col lg={12}>
                    <input
                      required
                      value={dob}
                      onChange={(e) => {
                        setDob(e.target.value);
                        setIsDisabled(true);
                      }}
                      type="date"
                      style={{ backgroundColor: "rgba(217, 217, 217, 0.23)" }}
                    />
                    {errMsg.age && errMsg.age ? (
                      <p className="text-danger">{errMsg.age[0]}</p>
                    ) : null}
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col lg={3}>
                <label>{t("Gender")}</label>
              </Col>
              <Col lg={8}>
                <Row>
                  <Col lg={12}>
                    <div className="gap-3 d-flex">
                      <div className="form-check">
                        <input
                          required
                          checked={gender === 1 ? true : false}
                          onChange={(e) => {
                            setGender(1);
                            setIsDisabled(true);
                          }}
                          className="form-check-input"
                          type="radio"
                          name="exampleRadios"
                          id="exampleRadios1"
                          defaultValue="option1"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="exampleRadios1"
                        >
                          {t("Male")}
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          required
                          checked={gender === 0 ? true : false}
                          onChange={(e) => {
                            setGender(0);
                            setIsDisabled(true);
                          }}
                          className="form-check-input"
                          type="radio"
                          name="exampleRadios"
                          id="exampleRadios2"
                          defaultValue="option2"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="exampleRadios2"
                        >
                          {t("Female")}
                        </label>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col lg={3}>
                <label>{t("Email")}</label>
              </Col>
              <Col lg={8}>
                <Row>
                  <Col lg={12}>
                    <input
                      readOnly={true}
                      value={maskString(email, 10)}
                      onChange={(e) => setEmail(e.target.value)}
                      type="email"
                      placeholder="Info@gmail.com"
                      className="mb-2 form-input"
                    />
                    <span>{t("Want to change E-Mail?")}</span>
                    <Link onClick={handleShow1} className="mx-2">
                      {t("Click Here")}
                    </Link>
                    <Modal show={show1}>
                      <ChangeEmail handleClose1={handleClose1} />
                    </Modal>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col lg={3}>
                <label>{t("Phone Number")}</label>
              </Col>
              <Col lg={8}>
                <Row>
                  <Col lg={12}>
                    <input
                      readOnly={true}
                      value={maskString(phone, 2)}
                      onChange={(e) => setPhone(e.target.value)}
                      type="text"
                      placeholder="09003233255"
                      className="mb-2 form-input"
                    />
                    <span>{t("Want to change Phone Number?")}</span>
                    <Link onClick={handleShow} className="mx-2">
                      {t("Click Here")}
                    </Link>
                    <Modal show={show}>
                      <ChangePhone
                        handleClose={handleClose}
                        handleShow2={handleShow2}
                      />
                    </Modal>
                    <Modal show={show2}>
                      <OtpPhone
                        phoneNumber={phone}
                        handleClose2={handleClose2}
                      />
                    </Modal>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12} lg={11}>
                <hr />
                <div className="security-btns">
                  <button
                    className="signin-btn"
                    type="submit"
                    disabled={isDisabled ? false : true}
                    onClick={handleSubmit}
                  >
                    {isLoading1 ? (
                      <Spinner animation="border" role="status"></Spinner>
                    ) : (
                      t("Save")
                    )}
                  </button>
                </div>
              </Col>
            </Row>
            <Modal show={show3}>
              <UpdateProfileSuccess
                handleClose3={handleClose3}
                setIsLoading={setIsLoading}
                isLoading={isLoading}
              />
            </Modal>
          </>
        )}
      </Container>
    </div>
  );
};

export default UserProfile;
