import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import PlayerModal from "../PlayerModal/PlayerModal";
import { useTranslation } from "react-i18next";
import { FaHeart, FaRegHeart } from "react-icons/fa";
import { addToWishlist } from "../../utils/api";
import Rating from "react-rating";
import { FaStar } from "react-icons/fa";
import { CiStar } from "react-icons/ci";

const RecommendedItem = ({ product }) => {
  const navigate = useNavigate();
  const [wishlisted, setWishlisted] = useState(product.wishlisted_by_user);
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const userToken = localStorage.getItem("paymentHub_token");
  const storedCurrency = JSON.parse(localStorage.getItem("selectedCurrency"));
  const currency = storedCurrency ? storedCurrency.symbol : "$";
  const wishlistFun = async (productId) => {
    try {
      if (userToken) {
        await addToWishlist(productId, userToken);
        setWishlisted(!wishlisted);
      }
    } catch (error) {
      console.error("Error adding to wishlist:", error);
    }
  };
  return (
    <div
      key={product?.slug}
      className="recomended-box mb-3"
      dir={localStorage.getItem("i18nextLng") === "ar" ? "rtl" : "ltr"}
    >
      {wishlisted ? (
        <FaHeart
          className="heart"
          color="#D21813"
          onClick={() => wishlistFun(product?.slug)}
          style={
            localStorage.getItem("i18nextLng") === "ar"
              ? { left: "10px" }
              : { right: "10px" }
          }
        />
      ) : (
        <FaRegHeart
          className="heart"
          onClick={() => wishlistFun(product?.slug)}
          style={
            localStorage.getItem("i18nextLng") === "ar"
              ? { left: "10px" }
              : { right: "10px" }
          }
        />
      )}
      <img
        className="recommend-img"
        src={product?.image}
        alt="recomended-img"
      />
      <div className="recomended-details">
        <Link to={`/productDetails/${product.slug}`} className="mb-3">
          {product?.name || product?.slug}
        </Link>
        <Rating
          className="rating mb-3 d-block"
          initialRating={product?.reviews_average}
          readonly
          emptySymbol={<CiStar className="empty-star" />}
          fullSymbol={<FaStar className="fill-star" />}
          direction={
            localStorage.getItem("i18nextLng") === "ar" ? "rtl" : "ltr"
          }
        />
        <div className="recomended-btn">
          <div className="d-flex flex-row gap-3 align-items-center">
            <span
              style={
                product?.discount !== 0
                  ? {
                      textDecoration: "line-through",
                    }
                  : {}
              }
            >
              {parseFloat(product?.price).toFixed(1)} {currency}
            </span>
            {product.discount !== 0 ? (
              <span className="new-price" style={{ color: "red" }}>
                {product.discount_type !== 0
                  ? (
                      (product?.price * (100 - product?.discount)) /
                      100
                    ).toFixed(1)
                  : (product?.price - product?.discount).toFixed(1)}{" "}
                {currency}
              </span>
            ) : null}
          </div>
          <button
            onClick={() => {
              if (userToken) {
                if (product?.player_id === null) {
                  handleShow();
                } else {
                  navigate(`/${product?.slug}/partners`);
                }
              } else {
                handleShow();
              }
            }}
            type="submit"
            className="buy-now"
            style={
              localStorage.getItem("i18nextLng") === "ar"
                ? { left: "10px" }
                : { right: "10px" }
            }
          >
            {t("Buy Now")}
          </button>
        </div>
      </div>
      <PlayerModal show={show} handleClose={handleClose} item={product} />
    </div>
  );
};

export default RecommendedItem;
