import React from "react";
import { Link } from "react-router-dom";

const SignUpFail = () => {
  return (
    <div className="signin-form text-center">
      <p className="success-text">
        Thank you <br />
        Your Account Already verified
      </p>
      <Link to="/signIn" className="signin-btn" type="submit">
        go to login
      </Link>
    </div>
  );
};

export default SignUpFail;
