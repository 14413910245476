import React, { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import MerchantGameItem from "../MerchantGameItem/MerchantGameItem";
import { useTranslation } from "react-i18next";
import { getUserWishlist } from "../../utils/api";
import LoaderSpinner from "../LoaderSpinner/LoaderSpinner";
import { useNavigate } from "react-router-dom";
import PaginationControls from "../PaginationControls";

const Wishlist = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userToken = localStorage.getItem("paymentHub_token");
  const [isLoading, setIsLoading] = useState(true);
  const [reload, setReload] = useState(true);
  const [wishlistItems, setWishlistItems] = useState([]);
  const storedCurrency = JSON.parse(localStorage.getItem("selectedCurrency"));
  const currencyCode = storedCurrency ? storedCurrency.code : "USD";
  const lang = localStorage.getItem("i18nextLng");
  // pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(15);
  const [total, setTotal] = useState(0);
  const [nextPageUrl, setNextPageUrl] = useState(null);
  const [prevPageUrl, setPrevPageUrl] = useState(null);

  const getWishlistItems = async (token, page) => {
    try {
      const { data } = await getUserWishlist(lang, token, currencyCode, page);
      setWishlistItems(data.items);
      setCurrentPage(data.current);
      setPerPage(data.per);
      setTotal(data.total);
      setNextPageUrl(data.next_page_url);
      setPrevPageUrl(data.prev_page_url);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (error.response.status === 401) {
        localStorage.removeItem("paymentHub_token");
        navigate("/signin");
      }
    }
  };

  const handleNextPage = () => {
    if (nextPageUrl) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (prevPageUrl) {
      setCurrentPage(currentPage - 1);
    }
  };
  useEffect(() => {
    getWishlistItems(userToken, currentPage);
  }, [isLoading, reload, userToken, currentPage]);

  return (
    <div className="user-transactions">
      <Container fluid>
        <h1 className="mb-4">{t("Wishlist")}</h1>
        {isLoading ? (
          <LoaderSpinner />
        ) : (
          <Row>
            {wishlistItems.length > 0 ? (
              wishlistItems.map((item) => (
                <MerchantGameItem
                  key={item.id}
                  item={item}
                  xxlWidth={4}
                  setReload={setReload}
                  reload={reload}
                />
              ))
            ) : (
              <p className="empty-products">{t("No items in wishlist")}</p>
            )}
            <PaginationControls
              total={total}
              perPage={perPage}
              currentPage={currentPage}
              handlePrevPage={handlePrevPage}
              handleNextPage={handleNextPage}
            />
          </Row>
        )}
      </Container>
    </div>
  );
};

export default Wishlist;
