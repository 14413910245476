import { BehaviorSubject } from 'rxjs';

// Create a BehaviorSubject to hold the current language
const languageSubject = new BehaviorSubject(localStorage.getItem('language') || 'en');

// Function to change the language
const setLanguage = (lang) => {
    localStorage.setItem('language', lang);
    languageSubject.next(lang);
};

// Observable to subscribe to language changes
const languageObservable = languageSubject.asObservable();

export { setLanguage, languageObservable };
