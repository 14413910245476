import axios from "axios";
import { languageObservable } from "./languageService";

// Create an instance of axios
const axiosInstance = axios.create();

// Current language state
let currentLanguage = localStorage.getItem("i18nextLng") || "en";

// Subscribe to language changes
languageObservable.subscribe((newLanguage) => {
  currentLanguage = newLanguage;
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    if (config.url) {
      const url = new URL(config.url, window.location.origin);
      url.searchParams.set("language", currentLanguage);
      config.url = url.toString();
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
