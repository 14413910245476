import React, { useEffect, useState } from "react";
import "./privacyPolicay.css";
import { getCmsData } from "../../utils/api";

const PrivacyPolicy = () => {
  const userToken = localStorage.getItem("paymentHub_token");
  const [faqData, setFaqData] = useState([]);
  const getFaqData = async (type, token) => {
    try {
      const { data } = await getCmsData(type, token);
      setFaqData(data);
    } catch (error) {}
  };
  useEffect(() => {
    getFaqData("2", userToken);
  }, [userToken]);
  return (
    <div className="privacy-policy mt-3">
      {faqData &&
        faqData?.map((faq, index) => (
          <div key={faq.id}>
            <span>
              {index + 1}-{faq.title}
            </span>
            <p>{faq.description}</p>
          </div>
        ))}
      {/* <div>
        <span>1. Introduction</span>
        <p>
          Welcome to Payment Hub. Your privacy is important to us, and we are
          committed to protecting your personal information. This Privacy Policy
          explains how we collect, use, disclose, and safeguard your information
          when you use our platform.
        </p>
      </div>
      <div>
        <span>2. Information We Collect</span>
        <p>
          <b>Personal Information</b>: When you register on our platform, we may
          collect information such as your name, email address, phone number,
          and payment information.
        </p>
        <p>
          <b>Payment Information</b>: We collect payment details such as
          credit/debit card numbers, bank account information, and other
          payment-related information to process transactions.
        </p>
        <p>
          <b>Usage Data</b>: We collect information on how you interact with our
          platform, including your IP address, browser type, operating system,
          referring URLs, pages viewed, and the time spent on our site.
        </p>
      </div>
      <div>
        <span>3. How We Use Your Information</span>
        <p>
          <b>To Provide Services</b>: We use your personal and payment
          information to process transactions, deliver services, and manage your
          account.
        </p>
        <p>
          <b>To Improve Services</b>: We analyze usage data to enhance our
          platform, develop new features, and improve user experience.
        </p>
        <p>
          <b>Communication</b>: We use your contact information to send
          account-related messages, updates, and promotional materials.
        </p>
        <p>
          <b>Legal Compliance</b>: We use your information to comply with legal
          obligations, prevent fraud, and protect the rights and safety of our
          users.
        </p>
      </div>
      <div>
        <span>4. How We Share Your Information</span>
        <p>
          <b>With Service Providers</b>: We may share your information with
          third-party vendors and service providers who assist us in processing
          payments and delivering services.
        </p>
        <p>
          <b>For Legal Reasons</b>: We may disclose your information to comply
          with legal requirements, enforce our terms and conditions, and protect
          our rights and property.
        </p>
        <p>
          <b>With Consent</b>: We may share your information with other parties
          with your explicit consent.
        </p>
      </div>
      <div>
        <span>5. Data Security</span>
        <p>
          We implement security measures such as encryption, secure servers, and
          access controls to protect your data. However, please note that no
          security measures are perfect or impenetrable.
        </p>
      </div>
      <div>
        <span>6. Data Retention</span>
        <p>
          retain your personal information for as long as necessary to provide
          our services, comply with legal obligations, and resolve disputes. You
          can request the deletion of your data by contacting us.
        </p>
      </div>
      <div>
        <span>7. How We Share Your Information</span>
        <p>
          <b>Access and Correction</b>: You have the right to access and correct
          your personal information.
        </p>
        <p>
          <b>Opt-Out</b>: You can opt-out of receiving promotional
          communications from us at any time.
        </p>
        <p>
          <b>Data Portability</b>: You have the right to request a copy of your
          personal data.
        </p>
        <p>
          <b>Erasure</b>: You can request the deletion of your personal
          information under certain conditions.
        </p>
      </div>
      <div>
        <span>8. Changes to This Privacy Policy</span>
        <p>
          We may update this Privacy Policy from time to time. We will notify
          you of any changes by posting the new policy on our platform. The
          effective date of the current policy is [insert date].
        </p>
      </div> */}
    </div>
  );
};

export default PrivacyPolicy;
