import React, { useEffect, useState } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LoaderSpinner from "../LoaderSpinner/LoaderSpinner";
import eye from "../../assets/profile/eye.png";
import { getuserCustomerSupport } from "../../utils/api";
import { getDateAndTime } from "../../utils/helpers";
import PaginationControls from "../PaginationControls";

const CustomerSupport = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const token = localStorage.getItem("paymentHub_token");
  const [userTickets, setUserTickets] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [reason, setReason] = useState("");
  const [status, setStatus] = useState("");
  // pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(15);
  const [total, setTotal] = useState(0);
  const [nextPageUrl, setNextPageUrl] = useState(null);
  const [prevPageUrl, setPrevPageUrl] = useState(null);

  const getTickets = async (token, search, status, reason, page) => {
    try {
      const { data } = await getuserCustomerSupport(
        token,
        search,
        status,
        reason,
        page
      );
      setUserTickets(data.items);
      setCurrentPage(data.current);
      setPerPage(data.per);
      setTotal(data.total);
      setNextPageUrl(data.next_page_url);
      setPrevPageUrl(data.prev_page_url);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleNextPage = () => {
    if (nextPageUrl) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (prevPageUrl) {
      setCurrentPage(currentPage - 1);
    }
  };
  useEffect(() => {
    getTickets(token, search, status, reason, currentPage);
  }, [token, search, status, reason, currentPage]);

  return (
    <div className="user-transactions">
      <Container fluid>
        <h1 className="mb-4">{t("Customer Support")}</h1>
        <Row className="mb-4 w-100 m-auto">
          <Col md={4} xl={7}></Col>
          <Col xs={12} md={4} xl={3} className="mb-3">
            <form className="DocSearch-Form">
              <label
                className="DocSearch-MagnifierLabel mb-0"
                htmlFor="docsearch-input"
                id="docsearch-label"
              >
                <svg
                  width={20}
                  height={20}
                  className="DocSearch-Search-Icon"
                  viewBox="0 0 20 20"
                  aria-hidden="true"
                >
                  <path
                    d="M14.386 14.386l4.0877 4.0877-4.0877-4.0877c-2.9418 2.9419-7.7115 2.9419-10.6533 0-2.9419-2.9418-2.9419-7.7115 0-10.6533 2.9418-2.9419 7.7115-2.9419 10.6533 0 2.9419 2.9418 2.9419 7.7115 0 10.6533z"
                    stroke="currentColor"
                    fill="none"
                    fillRule="evenodd"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </label>
              <input
                className="DocSearch-Input mx-2"
                aria-autocomplete="both"
                aria-labelledby="docsearch-label"
                id="docsearch-input"
                placeholder={t("Search By Ticket Ref")}
                maxLength={64}
                type="search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </form>
          </Col>
          <Col md={4} xl={2}>
            <button
              className="signin-btn"
              onClick={() => navigate("addTicket")}
            >
              Submit Ticket
            </button>
          </Col>
        </Row>
        {isLoading ? (
          <LoaderSpinner />
        ) : (
          <>
            <Table responsive className="text-nowrap">
              <thead>
                <tr>
                  <th>{t("ID")}</th>
                  <th>{t("Ticket Ref")}</th>
                  <th>{t("Date and time")}</th>
                  <th>{t("Subject")}</th>
                  <th>{t("Description")}</th>
                  <th>
                    <select
                      className="form-select border-0 p-0 fw-bold outline-0 text-capitalize"
                      aria-label="Default select example"
                      style={{
                        width: "fit-content",
                        margin: "auto",
                        fontSize: "16px",
                        color: "#3f4254",
                      }}
                      onChange={(e) => setReason(e.target.value)}
                    >
                      <option selected value="">
                        {t("Reason")}
                      </option>
                      <option value={1}>payment issues</option>
                      <option value={2}>account issues</option>
                      <option value={3}>technical issues</option>
                      <option value={4}>product issues</option>
                      <option value={5}>suggestions</option>
                    </select>
                  </th>
                  <th>
                    <select
                      className="form-select border-0 p-0 fw-bold outline-0 text-capitalize"
                      aria-label="Default select example"
                      style={{
                        width: "fit-content",
                        margin: "auto",
                        fontSize: "16px",
                        color: "#3f4254",
                      }}
                      onChange={(e) => setStatus(e.target.value)}
                    >
                      <option selected value="">
                        {t("Status")}
                      </option>
                      <option value={1}>pending</option>
                      <option value={2}>in progress</option>
                      <option value={3}>dispatched</option>
                      <option value={4}>resolved</option>
                      <option value={5}>completed</option>
                      <option value={0}>cancelled</option>
                    </select>
                  </th>
                  <th>{t("Actions")}</th>
                </tr>
              </thead>
              <tbody>
                {userTickets.length > 0 ? (
                  userTickets.map((userTicket) => (
                    <tr key={userTicket?.id}>
                      <td>{userTicket?.id}</td>
                      <td>{userTicket?.reference}</td>
                      <td>{getDateAndTime(userTicket?.created_at)}</td>
                      <td>{userTicket?.title}</td>
                      <td>{(userTicket?.description).slice(0, 35)}</td>
                      <td>
                        <span
                          className="fw-bold text-center text-capitalize"
                          style={{ fontSize: "16px" }}
                        >
                          {userTicket?.reason === 1
                            ? "payment issues"
                            : userTicket?.reason === 2
                            ? "account issues"
                            : userTicket?.reason === 3
                            ? "technical issues"
                            : userTicket?.reason === 4
                            ? "product issues"
                            : userTicket?.reason === 5
                            ? "suggestions"
                            : "none"}
                        </span>
                      </td>
                      <td>
                        <span
                          className="status"
                          style={
                            userTicket?.status === 0
                              ? {
                                  backgroundColor: "rgba(255, 221, 85, 0.36)",
                                  color: "rgba(255, 170, 5, 1)",
                                }
                              : userTicket?.status === 2
                              ? { backgroundColor: "#D0FFD5", color: "#1BBE01" }
                              : userTicket?.status === 3
                              ? {
                                  backgroundColor: "rgba(208, 255, 213, 1)",
                                  color: "rgba(27, 190, 1, 1)",
                                }
                              : userTicket?.status === 4
                              ? {
                                  backgroundColor: "rgba(208, 255, 213, 1)",
                                  color: "rgba(27, 190, 1, 1)",
                                }
                              : {
                                  backgroundColor: "rgba(255, 226, 229, 1)",
                                  color: "rgba(255, 0, 0, 1)",
                                }
                          }
                        >
                          {userTicket?.status === 1
                            ? "pending"
                            : userTicket?.status === 2
                            ? "in progress"
                            : userTicket?.status === 3
                            ? "dispatched"
                            : userTicket?.status === 4
                            ? "resolved"
                            : userTicket?.status === 5
                            ? "completed"
                            : "cancelled"}
                        </span>
                      </td>
                      <td>
                        <Link
                          to={`ViewTicket/${userTicket?.id}`}
                          className="download-btn"
                        >
                          <img src={eye} alt="eye" />
                        </Link>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={12}>No Transactions Found</td>
                  </tr>
                )}
              </tbody>
            </Table>
            <PaginationControls
              total={total}
              perPage={perPage}
              currentPage={currentPage}
              handlePrevPage={handlePrevPage}
              handleNextPage={handleNextPage}
            />
          </>
        )}
      </Container>
    </div>
  );
};

export default CustomerSupport;
