import React from "react";
import "./formsHeading.css";

const FormsHeading = ({ heading, subHeading }) => {
  return (
    <>
      <h1 className="mb-3">{heading}</h1>
      {subHeading ? (
        <h6
          className="mb-4"
          style={{ textAlign: "start", lineHeight: "1.7", color: "#696973" }}
        >
          {subHeading}
        </h6>
      ) : null}
    </>
  );
};

export default FormsHeading;
