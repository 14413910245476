import React from "react";
import "./profileSideBar.css";
import { NavLink } from "react-router-dom";
import img_1 from "../../assets/profile/icon_1.png";
import img_2 from "../../assets/profile/icon_2.png";
// import img_3 from "../assets/profile/icon_3.png";
import img_4 from "../../assets/profile/icon_4.png";
import img_5 from "../../assets/profile/icon_5.png";
// import img_6 from "../../assets/profile/icon_6.png";
// import img_7 from "../assets/profile/icon_7.png";
import img_8 from "../../assets/profile/security.png";
import { useTranslation } from "react-i18next";

const ProfileSideBar = () => {
  const { t } = useTranslation();
  return (
    <div className="profile-side-bar">
      <ul>
        <NavLink
          to={"/userProfile/accountDetails"}
          className="d-flex align-items-start gap-3"
        >
          <img src={img_1} alt="icon" />
          <div className="li-title">
            <span className="title">{t("Account Details")}</span>
            <span>{t("Setup Your Account Details")}</span>
          </div>
        </NavLink>
        <NavLink
          to={"/userProfile/wishlist"}
          className="d-flex align-items-start gap-3"
        >
          <img src={img_2} alt="icon" />
          <div className="li-title">
            <span className="title">{t("Wishlist")}</span>
            <span>{t("Check your wishlist products")}</span>
          </div>
        </NavLink>
        <NavLink
          to={"/userProfile/security"}
          className="d-flex align-items-start gap-3"
        >
          <img src={img_8} alt="icon" />
          <div className="li-title">
            <span className="title">{t("Security and Privacy")}</span>
            <span>{t("Setup Your Account Security")}</span>
          </div>
        </NavLink>
        <NavLink
          to={"/userProfile/transactions"}
          className="d-flex align-items-start gap-3"
        >
          <img src={img_4} alt="icon" />
          <div className="li-title">
            <span className="title">{t("Transaction Record")}</span>
            <span>{t("Check Your Transactions history")}</span>
          </div>
        </NavLink>
        <NavLink
          to={"/userProfile/customerSupport"}
          className="d-flex align-items-start gap-3"
        >
          <img src={img_5} alt="icon" />
          <div className="li-title">
            <span className="title">{t("Customer Support")}</span>
            <span>{t("Need Help ?")}</span>
          </div>
        </NavLink>
        {/* <NavLink
          to={"/userProfile/notifications"}
          className="d-flex align-items-start gap-3 mb-5"
        >
          <img src={img_6} alt="icon" />
          <div className="li-title">
            <span className="title">{t("Notification")}</span>
            <span>{t("You have unread notifications")}</span>
          </div>
        </NavLink> */}
      </ul>
    </div>
  );
};

export default ProfileSideBar;
