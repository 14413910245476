import React from "react";
import ScrollToTop from "../Components/ScrollToTop";
import MainNavbar from "../Components/Navbar/MainNavbar";
import { NavLink, Outlet } from "react-router-dom";
import Footer from "../Components/Footer/Footer";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const UserGuide = () => {
  const { i18n } = useTranslation();
  const currentLang = i18n.language;
  const isArabic = currentLang === "ar";
  return (
    <>
      <ScrollToTop />
      <MainNavbar />
      <Container fluid dir={isArabic ? "rtl" : "ltr"}>
        <div className="faq-background">
          <div className="faq-overlay"></div>
        </div>
        <div className="nav-btns">
          <NavLink to="/userGuide/privacyPolicy">Privacy Policy</NavLink>
          <NavLink to="/userGuide/termsOfService">Terms And Conditions</NavLink>
          <NavLink to="/userGuide/contactUs">Contact Us</NavLink>
        </div>
        <Outlet />
      </Container>
      <Footer />
    </>
  );
};

export default UserGuide;
