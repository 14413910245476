import React from "react";
import { Outlet } from "react-router-dom";
import "./authLayout.css";
import ScrollToTop from "../../Components/ScrollToTop";
import LanguageSelector from "../../Components/Language-selector";
import en_icon from "../../assets/en.png";
import ar_icon from "../../assets/ar.png";

const AuthLayout = () => {
  const languages = [
    { code: "en", flag: en_icon },
    { code: "ar", flag: ar_icon },
    // Add more languages as needed
  ];
  return (
    <>
      <ScrollToTop />
      <div className="auth-layout">
        <LanguageSelector languages={languages} />
        <div className="auth-overlay">
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default AuthLayout;
