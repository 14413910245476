import React, { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import "./faqPage.css";
import Faq from "../../Components/Faq/Faq";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getCmsData } from "../../utils/api";

const FaqPage = () => {
  const { i18n } = useTranslation();
  const currentLang = i18n.language;
  const isArabic = currentLang === "ar";
  const userToken = localStorage.getItem("paymentHub_token");
  const [faqData, setFaqData] = useState([]);
  const getFaqData = async (type, token) => {
    try {
      const { data } = await getCmsData(type, token);
      setFaqData(data);
    } catch (error) {}
  };
  useEffect(() => {
    getFaqData("1", userToken);
  }, [userToken]);
  return (
    <>
      <Container fluid dir={isArabic ? "rtl" : "ltr"}>
        <div className="faq-background">
          <div className="faq-overlay"></div>
        </div>
        <div className="faq-section">
          <Row>
            {faqData?.map((faq, index) => (
              <Faq key={index} title={faq.title} desc={faq.description} />
            ))}
            {/* <Faq
              title={"What is Payment Hub?"}
              desc={
                "Payment Hub is a platform that allows you to pay for products and services from various merchants using different payment methods such as e-wallets, Direct Carrier Billing (DCB), bank cards, and POS"
              }
            />
            <Faq
              title={"What payment methods are available on Payment Hub?"}
              desc={
                "Payment Hub supports various payment methods including e-wallets, Direct Carrier Billing (DCB), bank cards, and Point of Sales systems (POS)."
              }
            />
            <Faq
              title={"How do I buy a product or service on Payment Hub?"}
              desc={
                "To buy a product or service, log in to your account, browse the available products, select the one you want, and follow the checkout process. You can choose your preferred payment method during checkout."
              }
            />
            <Faq
              title={"How do I track my purchase history?"}
              desc={
                "You can track your purchase history by logging into your account and navigating to the 'Transaction Record' section. Here, you will find details of all your past transactions."
              }
            />
            <Faq
              title={
                "What should I do if I encounter an issue with my payment?"
              }
              desc={
                "If you have any issues when you pay, please contact our customer support team at support@paymenthub.com. Provide them with the details of your transaction, and they will assist you in resolving the issue."
              }
            />
            <Faq
              title={"How secure is my payment information?"}
              desc={
                "Payment Hub takes security very seriously. We use advanced encryption and security protocols to protect your payment information. Your data is securely stored and processed in compliance with industry standards."
              }
            />
            <Faq
              title={"How do I know if my purchase was successful?"}
              desc={
                "After completing a purchase, you will receive a confirmation email with the details of your transaction. Additionally, you can check the 'Transaction Record' section in your account for a record of your transaction."
              }
            />
            <Faq
              title={"Can I save my favorite products?"}
              desc={
                "Yes, you can save your favorite products by clicking the 'Add to Wishlist' button on the product page. You can view your wishlist by navigating to the 'Wishlist' section in your account."
              }
            />
            <Faq
              title={"How do I change my account information?"}
              desc={
                "You can change your account information by logging into your account and navigating to the 'View Account' section. Here, you can update your personal details, password, and other account information."
              }
            /> */}
          </Row>
        </div>
        <div className="contact-section py-5">
          <div>
            <span>If you need customer services , please contact us at </span>{" "}
            <Link>support@paymenthub.com</Link>
          </div>
          {/* <p>or click “ feedback” to get in touch with us</p> */}
        </div>
      </Container>
    </>
  );
};

export default FaqPage;
