import React, { useEffect, useState } from "react";
import {
  Accordion,
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
} from "react-bootstrap";
import GeneralCarousel from "../../Components/GeneralCarousel/GeneralCarousel";
import Heading from "../../Components/Heading/Heading";
import MerchantGameItem from "../../Components/MerchantGameItem/MerchantGameItem";
import { getFilterCategories, getFilteredProducts } from "../../utils/api";
import { useTranslation } from "react-i18next";
import LoaderSpinner from "../../Components/LoaderSpinner/LoaderSpinner";
import "./filterBox.css";
import filter_delete from "../../assets/filter-delete.png";
import { IoSearch } from "react-icons/io5";
import PaginationControls from "../../Components/PaginationControls";

const PopularProducts = () => {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  const isArabic = currentLang === "ar";
  const [isLoading, setIsLoading] = useState(false);
  // ==============================================
  const [popularProducts, setPopularProducts] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [filteredMerchants, setFilteredMerchants] = useState([]);
  const [merchantIds, setMerchantIds] = useState([]);
  const [categoryIds, setCategoryIds] = useState([]);
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const storedCurrency = JSON.parse(localStorage.getItem("selectedCurrency"));
  const currencyCode = storedCurrency ? storedCurrency.code : "USD";
  // pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(15);
  const [total, setTotal] = useState(0);
  const [nextPageUrl, setNextPageUrl] = useState(null);
  const [prevPageUrl, setPrevPageUrl] = useState(null);

  // fetch merchants and categories in filter box
  const fetchMerchants = async () => {
    try {
      const { data } = await getFilterCategories("select-merchant");
      setFilteredMerchants(data);
    } catch (error) {
      console.error("Error fetching merchants:", error);
    }
  };
  const fetchCategories = async () => {
    try {
      const { data } = await getFilterCategories("select-category");
      setFilteredCategories(data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };
  useEffect(() => {
    fetchMerchants();
    fetchCategories();
  }, [isLoading]);

  // fetch popular products with search query and handle search input change
  const handleInputChange = (e, page, searchQuery) => {
    setSearchQuery(e.target.value);
    if (searchQuery.length >= 2) {
      setSearchQuery(e.target.value);
      fetchFilteredProducts(page);
    }
  };

  const fetchFilteredProducts = async (page) => {
    try {
      const { data } = await getFilteredProducts(
        merchantIds.join(","),
        categoryIds.join(","),
        minPrice,
        maxPrice,
        searchQuery,
        currencyCode,
        page
      );
      setPopularProducts(data.items);
      setCurrentPage(data.current);
      setPerPage(data.per);
      setTotal(data.total);
      setNextPageUrl(data.next_page_url);
      setPrevPageUrl(data.prev_page_url);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (searchQuery.length <= 0) {
      fetchFilteredProducts(currentPage);
    }
  }, [currentPage, searchQuery]);

  const handleNextPage = () => {
    if (nextPageUrl) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (prevPageUrl) {
      setCurrentPage(currentPage - 1);
    }
  };

  const clearFilters = () => {
    setMerchantIds([]);
    setCategoryIds([]);
    setMinPrice(0);
    setMaxPrice(0);
    window.location.reload();
  };

  return (
    <div className="popular-games" dir={isArabic ? "rtl" : "ltr"}>
      <Container fluid>
        <GeneralCarousel />
        <Row>
          <Col lg={12}>
            <div className="d-flex align-items-center gap-4 my-3">
              <Heading title={t("Popular Products")} />
              <InputGroup className="w-50">
                <InputGroup.Text id="btnGroupAddon2" className="rounded-0">
                  <IoSearch />
                </InputGroup.Text>
                <Form.Control
                  className="rounded-0"
                  type="text"
                  placeholder="Search"
                  aria-label="Search"
                  aria-describedby="btnGroupAddon2"
                  style={{ backgroundColor: "#f8f9fa" }}
                  value={searchQuery}
                  onChange={(e) =>
                    handleInputChange(e, currentPage, searchQuery)
                  }
                />
              </InputGroup>
            </div>
          </Col>
          {isLoading ? (
            <LoaderSpinner />
          ) : (
            <>
              <Col lg={9}>
                <Row>
                  {popularProducts.length > 0 ? (
                    popularProducts.map((item, index) => (
                      <MerchantGameItem
                        key={index}
                        lgWidth={6}
                        xxlWidth={4}
                        item={item}
                        setReload={() => {}}
                        reload={false}
                      />
                    ))
                  ) : (
                    <p className="empty-products">No Products Found</p>
                  )}
                  <PaginationControls
                    total={total}
                    perPage={perPage}
                    currentPage={currentPage}
                    handlePrevPage={handlePrevPage}
                    handleNextPage={handleNextPage}
                  />
                </Row>
              </Col>
              <Col lg={3}>
                <div className="filter-box">
                  <h1>{t("Filter By")}</h1>
                  <Accordion alwaysOpen>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>{t("Categories")}</Accordion.Header>
                      <Accordion.Body className="p-2">
                        {filteredCategories &&
                          filteredCategories.map((category) => (
                            <div
                              className="d-flex align-items-center gap-2"
                              key={category.id}
                            >
                              <input
                                checked={categoryIds.includes(category.id)}
                                onChange={() =>
                                  setCategoryIds((prevIds) =>
                                    prevIds.includes(category.id)
                                      ? prevIds.filter(
                                          (id) => id !== category.id
                                        )
                                      : [...prevIds, category.id]
                                  )
                                }
                                id={category.name}
                                type="checkbox"
                              />
                              <label htmlFor={category.name}>
                                {category.name}
                              </label>
                            </div>
                          ))}
                      </Accordion.Body>
                    </Accordion.Item>
                    <hr />
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>{t("Merchant")}</Accordion.Header>
                      <Accordion.Body className="p-2">
                        {filteredMerchants &&
                          filteredMerchants.map((merchant) => (
                            <div
                              className="d-flex align-items-center gap-2"
                              key={merchant.id}
                            >
                              <input
                                type="checkbox"
                                id={merchant.name}
                                checked={merchantIds.includes(merchant.id)}
                                onChange={() =>
                                  setMerchantIds((prevIds) =>
                                    prevIds.includes(merchant.id)
                                      ? prevIds.filter(
                                          (id) => id !== merchant.id
                                        )
                                      : [...prevIds, merchant.id]
                                  )
                                }
                              />
                              <label htmlFor={merchant.name}>
                                {merchant.name}
                              </label>
                            </div>
                          ))}
                      </Accordion.Body>
                    </Accordion.Item>
                    <hr />
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>{t("Price")}</Accordion.Header>
                      <Accordion.Body>
                        <div className="d-flex gap-2">
                          <div className="d-flex align-items-center gap-2">
                            <label htmlFor="from">{t("From")}</label>
                            <input
                              value={minPrice}
                              onChange={(e) => setMinPrice(e.target.value)}
                              id="from"
                              type="number"
                              className="w-100"
                            />
                          </div>
                          <div className="d-flex align-items-center gap-2">
                            <label htmlFor="to">{t("To")}</label>
                            <input
                              value={maxPrice}
                              onChange={(e) => setMaxPrice(e.target.value)}
                              id="to"
                              type="number"
                              className="w-100"
                            />
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                  <button
                    className="signin-btn my-3"
                    type="button"
                    onClick={() => {
                      setCurrentPage(1);
                      fetchFilteredProducts(currentPage);
                    }}
                  >
                    {t("Filter")}
                  </button>
                  <button
                    onClick={() => clearFilters()}
                    style={{ backgroundColor: "#F3F3F3", color: "#2D2C79" }}
                    className="signin-btn"
                    type="button"
                  >
                    <img src={filter_delete} alt="filter_delete" />
                    {t("Clear")}
                  </button>
                </div>
              </Col>
            </>
          )}
        </Row>
      </Container>
    </div>
  );
};

export default PopularProducts;
