import React from "react";
import { Link } from "react-router-dom";

const ContactUs = () => {
  return (
    <div className="contact-section my-3 mb-5">
      <p className="mb-0 fw-bold fs-5 text-capitalize font-monospace text-secondary">
        If you have any questions, concerns, or need assistance, our team is
        here to help.
        <br />
        Please reach out to us if you havu any general inquiries, support, or
        feedback .<br />
        you can email us at: <Link>support@paymenthub.com</Link>
      </p>
    </div>
  );
};

export default ContactUs;
