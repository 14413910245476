import React, { useEffect, useState } from "react";
import { Col, Container, Modal, Row, Table } from "react-bootstrap";
import "./userTransactions.css";
import { getuserMerchants, getuserTransactions } from "../../utils/api";
import LoaderSpinner from "../LoaderSpinner/LoaderSpinner";
import { useTranslation } from "react-i18next";
import TransactionRecord from "./TransactionRecord";
import { useNavigate } from "react-router-dom";
import PaginationControls from "../PaginationControls";

const UserTransactions = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const token = localStorage.getItem("paymentHub_token");
  const [isLoading, setIsLoading] = useState(true);
  const [transactions, setTransactions] = useState([]);
  const [merchantId, setTMerchantId] = useState("");
  const [fromAmount, setFromAmount] = useState("");
  const [toAmount, setToAmount] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [status, setStatus] = useState(0);
  const [merchants, setMerchants] = useState([]);
  // pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [nextPageUrl, setNextPageUrl] = useState(null);
  const [prevPageUrl, setPrevPageUrl] = useState(null);

  const getMerchants = async (token) => {
    try {
      if (token) {
        const { data } = await getuserMerchants(token);
        setMerchants(data);
      }
    } catch (error) {
      // if (error.response.status === 401) {
      //   refreshTokenFun(token);
      // }
    }
  };
  const getTransactions = async (
    merchantId,
    fromAmount,
    toAmount,
    fromDate,
    status,
    token,
    page
  ) => {
    try {
      if (token) {
        const { data } = await getuserTransactions(
          merchantId,
          fromAmount,
          toAmount,
          fromDate,
          status,
          token,
          page
        );
        setTransactions(data.items);
        setIsLoading(false);
        setCurrentPage(data.current);
        setPerPage(data.per);
        setTotal(data.total);
        setNextPageUrl(data.next_page_url);
        setPrevPageUrl(data.prev_page_url);
      }
    } catch (error) {
      setIsLoading(false);
      if (error.response.status === 401) {
        localStorage.removeItem("paymentHub_token");
        navigate("/signin");
      }
    }
  };
  const handleNextPage = () => {
    if (nextPageUrl) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (prevPageUrl) {
      setCurrentPage(currentPage - 1);
    }
  };
  useEffect(() => {
    getMerchants(token);
  }, [token]);
  useEffect(() => {
    getTransactions(
      merchantId,
      fromAmount,
      toAmount,
      fromDate,
      status,
      token,
      currentPage
    );
  }, [merchantId, fromAmount, toAmount, fromDate, status, token, currentPage]);

  return (
    <div className="user-transactions">
      <Container fluid>
        <h1 className="mb-4">{t("Transactions")}</h1>
        <Row className="mb-4">
          <Col xs={6} md={3} xl={1} className="mb-3 p-0">
            <h3>{t("Filter By")}</h3>
          </Col>
          <Col xs={6} md={3} xl={2} className="mb-3">
            <select
              className="form-select"
              defaultValue={t("Merchant")}
              onChange={(e) => setTMerchantId(e.target.value)}
            >
              <option disabled>{t("Merchant")}</option>
              <option value={""}>{t("All")}</option>
              {merchants.map((merchant) => (
                <option key={merchant.id} value={merchant.id}>
                  {merchant.name}
                </option>
              ))}
            </select>
          </Col>
          <Col xs={6} md={3} xl={2} className="mb-3">
            <input
              className="input"
              type="number"
              placeholder={t("From Amount")}
              min={0}
              onChange={(e) => setFromAmount(e.target.value)}
            />
          </Col>
          <Col xs={6} md={3} xl={2} className="mb-3">
            <input
              className="input"
              type="number"
              placeholder={t("To Amount")}
              min={0}
              onChange={(e) => setToAmount(e.target.value)}
            />
          </Col>
          <Col xs={12} md={4} xl={2} className="mb-3">
            <select
              className="form-select"
              defaultValue={t("Status")}
              onChange={(e) => setStatus(e.target.value)}
            >
              <option disabled>{t("Status")}</option>
              <option value={""}>{t("All")}</option>
              <option value="1">Pending</option>
              <option value="2">Done</option>
              <option value="3">Cancelled</option>
              <option value="4">Failed</option>
            </select>
          </Col>
          <Col xs={12} md={4} xl={3} className="mb-3">
            <input
              className="input"
              type="date"
              onChange={(e) => setFromDate(e.target.value)}
            />
          </Col>
        </Row>
        {isLoading ? (
          <LoaderSpinner />
        ) : (
          <>
            <Table responsive>
              <thead>
                <tr>
                  <th>{t("Date")}</th>
                  <th>{t("Merchant")}</th>
                  <th>{t("Details")}</th>
                  <th>{t("Status")}</th>
                  <th>{t("Amount")}</th>
                  <th>{t("Invoice")}</th>
                </tr>
              </thead>
              <tbody>
                {transactions.length > 0 ? (
                  transactions.map((transaction) => (
                    <TransactionRecord
                      key={transaction.id}
                      transaction={transaction}
                    />
                  ))
                ) : (
                  <tr>
                    <td colSpan={6}>No Transactions Found</td>
                  </tr>
                )}
              </tbody>
            </Table>
            <PaginationControls
              total={total}
              perPage={perPage}
              currentPage={currentPage}
              handlePrevPage={handlePrevPage}
              handleNextPage={handleNextPage}
            />
          </>
        )}
      </Container>
    </div>
  );
};

export default UserTransactions;
