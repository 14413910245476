import React from "react";

const PaymentProgress = ({ progress_img }) => {
  return (
    <div className="payment-progress">
      <img width={"90%"} src={progress_img} alt="progress_1" />
    </div>
  );
};

export default PaymentProgress;
