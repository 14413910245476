import React, { useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import PaymentProgress from "../PaymentProgress/PaymentProgress";
import PaymentProduct from "../PaymentProduct/PaymentProduct";
import BankCardHeadingBox from "../BankCardHeadingBox/BankCardHeadingBox";
import progress_2 from "../../assets/payment/progress_2.png";
import pay_4 from "../../assets/payment/pay_4.png";
import qr from "../../assets/payment/qr_code_barcode.png";
import { payment } from "../../utils/api";
import PaymentInstructionsWallet from "../PaymentInstructions/PaymentInstructionsWallet";

const PaymentEwallet = () => {
  const userToken = localStorage.getItem("paymentHub_token");
  const [disabled, setDisabled] = useState(false);
  const [showQr, setShowQr] = useState(false);
  const [paymentData, setPaymentData] = useState([]);
  const [guestName, setGuestName] = useState("");
  const [guestEmail, setGuestEmail] = useState("");
  const [guestPhone, setGuestPhone] = useState("");
  const [errMsg, setErrMsg] = useState("");

  const payWithWallet = async (itemId, paymentId, playerId) => {
    try {
      const result = await payment(itemId, paymentId, playerId, userToken);
      setPaymentData(result);
    } catch (error) {}
  };
  return (
    <div className="payment-methods">
      <Container>
        <PaymentProgress progress_img={progress_2} />
        <h1 className="payment-title">Payment Method</h1>
        <Row>
          <Col md={12} lg={8} xl={9}>
            <BankCardHeadingBox
              bankCardImg={pay_4}
              bankCardTitle={"E-Wallet"}
            />
            {showQr ? (
              <PaymentInstructionsWallet
                title={"How to pay with E-wallet"}
                setShowQr={setShowQr}
                pay_title={"Scan QR Code"}
                pay_subTitle={"Scan this QR code to verify your payment"}
                qr_img={qr}
                paymentData={paymentData}
              />
            ) : (
              <>
                {disabled && userToken ? (
                  <h1 className="payment-title">Details</h1>
                ) : (
                  <h1 className="payment-title">Details</h1>
                )}
                <Row>
                  <Col xs={12} md={12} lg={10} xl={6}>
                    {disabled && userToken ? (
                      <>
                        <Form.Label htmlFor="Name">Name</Form.Label>
                        <Form.Control
                          type="text"
                          id="Name"
                          aria-describedby="passwordHelpBlock"
                          placeholder="Name"
                          value={guestName}
                          onChange={(e) => setGuestName(e.target.value)}
                        />
                        <br />
                        <Form.Label htmlFor="Email">Email</Form.Label>
                        <Form.Control
                          type="email"
                          id="Email"
                          aria-describedby="passwordHelpBlock"
                          placeholder="Email"
                          value={guestEmail}
                          onChange={(e) => setGuestEmail(e.target.value)}
                        />
                        <br />
                        <Form.Label htmlFor="phone">Phone</Form.Label>
                        <Form.Control
                          type="phone"
                          id="phone"
                          aria-describedby="passwordHelpBlock"
                          placeholder="phone"
                          value={guestPhone}
                          onChange={(e) => setGuestPhone(e.target.value)}
                        />
                        <br />
                      </>
                    ) : !userToken ? (
                      <>
                        <Form.Label htmlFor="Name">Name</Form.Label>
                        <Form.Control
                          type="text"
                          id="Name"
                          aria-describedby="passwordHelpBlock"
                          placeholder="Name"
                          value={guestName}
                          onChange={(e) => setGuestName(e.target.value)}
                        />
                        <br />
                        <Form.Label htmlFor="Email">Email</Form.Label>
                        <Form.Control
                          type="email"
                          id="Email"
                          aria-describedby="passwordHelpBlock"
                          placeholder="Email"
                          value={guestEmail}
                          onChange={(e) => setGuestEmail(e.target.value)}
                        />
                        <br />
                        <Form.Label htmlFor="phone">Phone</Form.Label>
                        <Form.Control
                          type="phone"
                          id="phone"
                          aria-describedby="passwordHelpBlock"
                          placeholder="phone"
                          value={guestPhone}
                          onChange={(e) => setGuestPhone(e.target.value)}
                        />
                        <br />
                        {errMsg ? (
                          <p style={{ color: "red" }} className="error-msg">
                            {errMsg}
                          </p>
                        ) : null}
                      </>
                    ) : null}
                    <form>
                      {userToken ? (
                        <Col xs={12}>
                          <Form.Check // prettier-ignore
                            type="checkbox"
                            id="invoice"
                            label="I Want to receive invoice"
                            onChange={() => setDisabled(!disabled)}
                          />
                        </Col>
                      ) : null}
                    </form>
                  </Col>
                </Row>
              </>
            )}
          </Col>
          <Col md={6} lg={4} xl={3}>
            {!showQr ? (
              <PaymentProduct
                setShowQr={setShowQr}
                payFun={payWithWallet}
                setErrMsg={setErrMsg}
                guestName={guestName}
                guestEmail={guestEmail}
                guestPhone={guestPhone}
              />
            ) : null}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PaymentEwallet;
