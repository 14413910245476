import React from "react";
import { useNavigate } from "react-router-dom";

const SearchBarResults = ({ results, setInputSearch }) => {
  const navigate = useNavigate();
  return (
    <div
      style={results.length > 4 ? { overflowY: "scroll" } : {}}
      className="results-list"
    >
      {results.length > 0 ? (
        results.map((result, id) => (
          <div
            key={id}
            className="d-flex align-items-center gap-3 p-3"
            onClick={() => {
              setInputSearch("");
              navigate(`/productDetails/${result.slug}`);
            }}
          >
            <img
              src={result.image}
              alt="product-logo"
              className="rounded border border-dark"
              style={{ width: "fit-content", height: "60px" }}
            />
            <div className="card-info">
              <h3 style={{ fontSize: "16px" }} className="fw-bold">
                {result.name}
              </h3>
              <p
                style={{ fontSize: "14px" }}
                className="mb-0 text-capitalize text-secondary"
              >
                {result.description.substring(0, 50)}...
              </p>
            </div>
          </div>
        ))
      ) : (
        <p className="d-flex justify-content-center align-items-center mb-0 py-3 fw-bold text-capitalize text-danger fs-5">
          No results found
        </p>
      )}
    </div>
  );
};

export default SearchBarResults;
