import React from "react";
import "./heading.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
const Heading = ({ title, btn_title, navigatePath }) => {
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const currentLang = i18n.language;
  const isArabic = currentLang === "ar";
  return (
    <div className="heading mb-3" dir={isArabic ? "rtl" : "ltr"}>
      <h1 className="heading-text mb-0">{title}</h1>
      {btn_title && (
        <p
          className="heading-subtext"
          onClick={() => navigate(`/${navigatePath}`)}
        >
          {btn_title}
        </p>
      )}
    </div>
  );
};
export default Heading;
