import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Heading from "../../Components/Heading/Heading";
import merchant_img from "../../assets/games/game_img.png";
import GeneralCarousel from "../../Components/GeneralCarousel/GeneralCarousel";
import { getMerchants } from "../../utils/api";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PaginationControls from "../../Components/PaginationControls";

const AllMerchants = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [merchants, setMerchants] = useState([]);
  // pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(15);
  const [total, setTotal] = useState(0);
  const [nextPageUrl, setNextPageUrl] = useState(null);
  const [prevPageUrl, setPrevPageUrl] = useState(null);

  const fetchMerchants = async (page) => {
    try {
      const { data } = await getMerchants(page);
      setMerchants(data.items);
      setCurrentPage(data.current);
      setPerPage(data.per);
      setTotal(data.total);
      setNextPageUrl(data.next_page_url);
      setPrevPageUrl(data.prev_page_url);
    } catch (error) {}
  };
  const handleNextPage = () => {
    if (nextPageUrl) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (prevPageUrl) {
      setCurrentPage(currentPage - 1);
    }
  };
  useEffect(() => {
    fetchMerchants(currentPage);
  }, [currentPage]);

  return (
    <div className="container-fluid">
      <div className="all-merchant">
        <GeneralCarousel />
        <Row>
          <Col md={12} lg={12}>
            <div className="my-3">
              <Heading title={t("All Merchants")} />
            </div>
            <Row
              dir={localStorage.getItem("i18nextLng") === "ar" ? "rtl" : "ltr"}
            >
              {merchants.map((merchant) => (
                <Col
                  md={4}
                  lg={3}
                  key={merchant.id}
                  onClick={() => navigate(`${merchant.id}`)}
                >
                  <div className="merchant-overlay">
                    <img
                      className="w-100 mb-4"
                      src={merchant.logo ? merchant.logo : merchant_img}
                      alt="merchant_img"
                    />
                    <h5>{merchant.name}</h5>
                  </div>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
        <PaginationControls
          total={total}
          perPage={perPage}
          currentPage={currentPage}
          handlePrevPage={handlePrevPage}
          handleNextPage={handleNextPage}
        />
      </div>
    </div>
  );
};

export default AllMerchants;
