import React from "react";
import check from "../../assets/check.png";
import { Link } from "react-router-dom";

const PasswordSuccess = () => {
  return (
    <div className="signin-form text-center">
      <img className="check" src={check} alt="check" />
      <p className="success-text">
        Password has been <br />
        Changed successfully
      </p>
      <Link
        onClick={() => localStorage.clear()}
        to="/signIn"
        style={{ color: "#54A99F", textDecoration: "none", fontWeight: "bold" }}
        type="submit"
      >
        Going to login
      </Link>
    </div>
  );
};

export default PasswordSuccess;
