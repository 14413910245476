import React, { useEffect, useState } from "react";
import "./viewTicket.css";
import { Col, Container, Row } from "react-bootstrap";
import LoaderSpinner from "../LoaderSpinner/LoaderSpinner";
import { useTranslation } from "react-i18next";
import avatar from "../../assets/Avatar.png";
import { getTicketDetails, getuserTicketComments } from "../../utils/api";
import { useParams } from "react-router-dom";
import { formatDateToRelativeTime } from "../../utils/helpers";

const ViewTicket = () => {
  const { id } = useParams();
  const token = localStorage.getItem("paymentHub_token");
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [ticket, setTicket] = useState({});
  const [comments, setComments] = useState([]);
  const getTicketFun = async (token, id) => {
    try {
      const { data } = await getTicketDetails(token, id);
      setTicket(data);
      setIsLoading(false);
      const response = await getuserTicketComments(token, id);
      setComments(response.data.items);
    } catch (error) {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getTicketFun(token, id);
  }, [token, id]);
  return (
    <div className="user-transactions user-profile">
      <Container>
        <h1 className="mb-5">{t("View Ticket")}</h1>
        {isLoading ? (
          <LoaderSpinner />
        ) : (
          <>
            <Row className="mb-4 w-100 m-auto">
              <Col lg={3}>
                <label>{t("User Name")}</label>
              </Col>
              <Col lg={8}>
                <b className="text-capitalize">{ticket?.name}</b>
              </Col>
            </Row>
            <Row className="mb-4 w-100 m-auto">
              <Col lg={3}>
                <label>{t("Email")}</label>
              </Col>
              <Col lg={8}>
                <b className="text-capitalize">{ticket?.email}</b>
              </Col>
            </Row>
            <Row className="mb-4 w-100 m-auto">
              <Col lg={3}>
                <label>{t("Phone Number")}</label>
              </Col>
              <Col lg={8}>
                <b className="text-capitalize">{ticket?.phone}</b>
              </Col>
            </Row>
            <Row className="mb-4 w-100 m-auto">
              <Col lg={3}>
                <label>{t("Ticket Description")}</label>
              </Col>
              <Col lg={8}>
                <b>{ticket?.description}</b>
              </Col>
            </Row>
            <Row className="mb-4 w-100 m-auto">
              <Col lg={3}>
                <label>{t("Ticket Reason")}</label>
              </Col>
              <Col lg={8}>
                <b>
                  {ticket?.reason === 1
                    ? "payment issues"
                    : ticket?.status === 2
                    ? "account issues"
                    : ticket?.status === 3
                    ? "technical issues"
                    : ticket?.status === 4
                    ? "product issues"
                    : "suggestions"}
                </b>
              </Col>
            </Row>
            <Row className="mb-4 w-100 m-auto">
              <Col lg={3}>
                <label>{t("Status")}</label>
              </Col>
              <Col lg={8}>
                <b
                  style={
                    ticket?.status === 0
                      ? {
                          color: "rgba(255, 170, 5, 1)",
                        }
                      : ticket?.status === 2
                      ? { color: "#1BBE01" }
                      : ticket?.status === 3
                      ? {
                          color: "rgba(27, 190, 1, 1)",
                        }
                      : ticket?.status === 4
                      ? {
                          color: "rgba(27, 190, 1, 1)",
                        }
                      : {
                          color: "rgba(255, 0, 0, 1)",
                        }
                  }
                >
                  {ticket?.status === 1
                    ? "pending"
                    : ticket?.status === 2
                    ? "in progress"
                    : ticket?.status === 3
                    ? "dispatched"
                    : ticket?.status === 4
                    ? "resolved"
                    : ticket?.status === 0
                    ? "completed"
                    : "cancelled"}
                </b>
              </Col>
            </Row>
            <Row className="w-100 m-auto">
              <Col lg={12}>
                <h4
                  style={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    marginTop: "25px",
                    marginBottom: "15px",
                  }}
                >
                  Comments
                </h4>
                {comments?.length > 0 ? (
                  comments?.map((item) => {
                    return (
                      <div className="ticket-comment my-4">
                        <div className="comment-heading">
                          <img
                            width={40}
                            height={40}
                            className="rounded-circle"
                            src={avatar}
                            alt="admin"
                          />
                          <b className="mx-2">
                            Customer Support Representative
                          </b>
                        </div>
                        <p
                          style={{
                            width: "90%",
                            fontSize: "16px",
                            color: "rgba(0, 0, 0, 0.6)",
                            fontWeight: "500",
                            margin: "15px 0",
                            lineHeight: "1.7",
                            textTransform: "capitalize",
                          }}
                        >
                          {item?.comment}
                        </p>
                        <span
                          style={{
                            fontWeight: "bold",
                            fontSize: "16px",
                            color: "rgba(0, 0, 0, 0.4)",
                          }}
                        >
                          {formatDateToRelativeTime(item?.created_at)}
                        </span>
                      </div>
                    );
                  })
                ) : (
                  <p className="text-danger fw-bold fs-5">
                    No comments Added yet
                  </p>
                )}
              </Col>
            </Row>
          </>
        )}
      </Container>
    </div>
  );
};

export default ViewTicket;
