import React, { useEffect, useState } from "react";
import { IoClose } from "react-icons/io5";
import { getuserTransactionInvoice } from "../../utils/api";
import { Col, Row, Spinner, Table } from "react-bootstrap";
import { getDate } from "../../utils/helpers";
import LoaderSpinner from "../LoaderSpinner/LoaderSpinner";

const InvoiceModal = ({ handleClose, transactionId }) => {
  const [invoiceData, setInvoiceData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const getInvoice = async (id) => {
    try {
      const { data } = await getuserTransactionInvoice(id);
      setInvoiceData(data);
      setIsLoading(false);
    } catch (error) {
      // setError("Failed to load invoice data");
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getInvoice(transactionId);
  }, [transactionId]);

  return (
    <div className="signin-form px-2">
      <div className="mb-4 d-flex align-items-center justify-content-between">
        <span className="modal-close-btn" onClick={handleClose}>
          <IoClose />
        </span>
        <h3 className="m-0 text-capitalize">Transaction details</h3>
      </div>
      <div
        className="user-invoice-details mb-3"
        style={{
          border: "1px solid #D7DAE0",
          borderRadius: "12px",
          background: "#fff",
          padding: "25px 10px",
        }}
      >
        <div className="mb-3 d-flex gap-5 align-items-flex-start">
          <div className="invoice mb-3">
            <h6>Reference ID</h6>
            {invoiceData?.qr !== null ? (
              invoiceData.qr ? (
                <img
                  src={invoiceData?.qr}
                  alt="QR Code"
                  width={80}
                  height={80}
                />
              ) : (
                <Spinner />
              )
            ) : (
              <b className="mb-3">{invoiceData?.refrence}</b>
            )}
          </div>
          <div className="invoice mb-3">
            <h6>Transaction ID</h6>
            <b className="mb-3">{invoiceData?.invoice_id}</b>
          </div>
          <div className="invoice mb-3">
            <h6>Payment Method</h6>
            <b className="mb-3">{invoiceData?.payment}</b>
          </div>
          <div className="invoice mb-3">
            <h6>Date - time</h6>
            <b className="mb-3">{getDate(invoiceData?.created_at)}</b>
          </div>
          <div className="invoice mb-3">
            <h6>Status</h6>
            <b
              className="status"
              style={
                invoiceData?.status === 1
                  ? {
                      color: "#FFAA05",
                    }
                  : invoiceData?.status === 2
                  ? { color: "#1BBE01" }
                  : invoiceData?.status === 3
                  ? { color: "#FF0000" }
                  : { color: "#FF0000" }
              }
            >
              {invoiceData?.status === 1
                ? "Pending"
                : invoiceData?.status === 2
                ? "Completed"
                : invoiceData?.status === 3
                ? "Canceled"
                : "Failed"}
            </b>
          </div>
        </div>
        <Row>
          <Table responsive>
            <thead>
              <tr>
                <th>Item Detail</th>
                <th>Qty</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <h4>{invoiceData?.product?.name}</h4>
                  <p>{invoiceData?.product?.description?.slice(0, 50)}....</p>
                </td>
                <td>
                  <span>1</span>
                </td>
                <td>
                  <span>$ {parseFloat(invoiceData?.amount).toFixed(2)}</span>
                </td>
              </tr>
            </tbody>
          </Table>
        </Row>
        <Row>
          <Col md={6} lg={8}></Col>
          <Col md={6} lg={4}>
            <div className="invoice-total mb-5">
              <div className="subtotal mb-3">
                <span>Subtotal</span>
                <span>${parseFloat(invoiceData?.amount).toFixed(2)}</span>
              </div>
              <div className="subtotal mb-2">
                <span>
                  Tax ({parseFloat(invoiceData?.fee_amount).toFixed(2)})
                </span>
                <span>${parseFloat(invoiceData?.fee_amount).toFixed(2)}</span>
              </div>
              <hr />
              <div className="subtotal mb-2">
                <span>Total</span>
                <span>
                  $
                  {(
                    parseFloat(invoiceData?.amount) +
                    parseFloat(invoiceData?.fee_amount)
                  ).toFixed(2)}
                </span>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};
{
  /* <LoaderSpinner /> */
}

export default InvoiceModal;
