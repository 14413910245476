import React from "react";
import "./footer.css";
import { Col, Container, Row } from "react-bootstrap";
import logo from "../../assets/logo.png";
// import tiktok from "../../assets/footer_social/tiktok.png";
// import instgram from "../../assets/footer_social/instagram.png";
// import facebook from "../../assets/footer_social/facebook.png";
import copy from "../../assets/footer_social/copy.png";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  const isArabic = currentLang === "ar";
  return (
    <div className="footer-section" dir={isArabic ? "rtl" : "ltr"}>
      <Container fluid>
        <Row className="w-100 m-auto">
          <Col lg={3} md={12} sm={6}>
            <img src={logo} alt="logo" />
            <h5 className="mt-2">Explore Payment Hub</h5>
            {/* <h5 className="mb-3">{t("Follow Us On")}</h5> */}
            {/* <ul className="footer-social list-unstyled d-flex align-items-center justify-content-start gap-2 flex-row">
              <li>
                <img src={tiktok} alt="tiktok" />
              </li>
              <li>
                <img src={instgram} alt="instgram" />
              </li>
              <li>
                <img src={facebook} alt="facebook" />
              </li>
            </ul> */}
          </Col>
          <Col lg={3} md={4} sm={6}>
            <ul>
              <li onClick={() => navigate("/home")}>{t("Home")}</li>
              <li onClick={() => navigate("/userProfile/customerSupport")}>
                {t("Help Center")}
              </li>
            </ul>
          </Col>
          <Col lg={3} md={4} sm={6}>
            <ul>
              <li onClick={() => navigate("/userGuide/privacyPolicy")}>
                {t("Privacy Policy")}
              </li>
              <li onClick={() => navigate("/userGuide/termsOfService")}>
                {t("Terms And Conditions")}
              </li>
              {/* <li>{t("Terms Of Use")}</li> */}
            </ul>
          </Col>
          <Col lg={3} md={4} sm={6}>
            <ul>
              <li onClick={() => navigate("/userGuide/contactUs")}>
                {t("Contact Us")}
              </li>
              <li onClick={() => navigate("/faq")}>{t("FAQ")}</li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col md={12} sm={12}>
            <div className="footer-copy">
              <img src={copy} alt="copy" />{" "}
              <span>2023-2024 Payment HUB - Powered By - Grand Technology</span>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Footer;
