import React, { useState } from "react";
import { Col, Container, Modal, Row, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { updateUserPassword } from "../../utils/api";
import PasswordSuccess from "../Modals/PasswordSuccess";
import hidden_icon from "../../assets/hidden_icon.svg";
import visible_icon from "../../assets/visible_icon.svg";

const UserSecurity = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const togglePasswordVisibility1 = () => {
    setShowPassword1(!showPassword1);
  };
  const togglePasswordVisibility2 = () => {
    setShowPassword2(!showPassword2);
  };
  const [show, setShow] = useState(false);
  // const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { t } = useTranslation();
  // state to store password and confirm password
  const [currentPassword, setCurrentPassword] = useState("");
  const [NewPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordErrors, setPasswordErrors] = useState({});
  const [passwordError, setPasswordError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const updatePassword = async () => {
    try {
      setIsLoading(true);
      const { data } = await updateUserPassword({
        old_password: currentPassword,
        password: NewPassword,
        password_confirmation: confirmPassword,
      });
      if (data.success === true) {
        setIsLoading(false);
        setPasswordErrors({});
        setPasswordError("");
        handleShow();
      }
    } catch (error) {
      setPasswordErrors(error.response.data.errors);
      setPasswordError(error.response.data);
      setIsLoading(false);
    }
  };

  return (
    <div className="user-transactions user-profile">
      <Container fluid>
        <h1 className="mb-5">{t("Change Password")}</h1>
        <Row>
          <Col sm={12} md={12} lg={12} xl={10} xxl={10}>
            <div className="signin-form">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  updatePassword();
                }}
                onReset={() => {
                  setCurrentPassword("");
                  setNewPassword("");
                  setConfirmPassword("");
                  setPasswordErrors({});
                }}
              >
                <Row className="mb-3">
                  <Col lg={3}>
                    <label>{t("Current Password")}</label>
                  </Col>
                  <Col lg={9}>
                    <div className="form-input p-0 mb-3">
                      <input
                        className="m-0"
                        autoSave="false"
                        required={true}
                        placeholder={t("Enter Your Password")}
                        type={showPassword ? "text" : "password"}
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                      />
                      <img
                        onClick={togglePasswordVisibility}
                        className="pass_hidden"
                        src={showPassword ? visible_icon : hidden_icon}
                        alt="hidden_icon"
                      />
                    </div>
                    {passwordError?.error ? (
                      <div className="alert alert-danger m-0 mb-0">
                        <ul className="px-2 mb-0">
                          <li style={{ color: "#EB0000" }}>
                            {passwordError.error}
                          </li>
                        </ul>
                      </div>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col lg={3}>
                    <label>{t("New Password")}</label>
                  </Col>
                  <Col lg={9}>
                    <div className="form-input p-0 mb-3">
                      <input
                        className="m-0"
                        autoSave="false"
                        required={true}
                        placeholder={t("Enter Your New Password")}
                        type={showPassword1 ? "text" : "password"}
                        onChange={(e) => setNewPassword(e.target.value)}
                        value={NewPassword}
                        style={
                          passwordErrors?.password ? { color: "#EB0000" } : {}
                        }
                      />
                      <img
                        onClick={togglePasswordVisibility1}
                        className="pass_hidden"
                        src={showPassword1 ? visible_icon : hidden_icon}
                        alt="hidden_icon"
                      />
                    </div>
                    {passwordErrors?.password ? (
                      <div className="alert alert-danger mt-2">
                        {passwordErrors?.password.map((error, index) => (
                          <ul className="px-3">
                            <li style={{ color: "#EB0000" }} key={index}>
                              {error}
                            </li>
                          </ul>
                        ))}
                      </div>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col lg={3}>
                    <label>{t("Confirm Password")}</label>
                  </Col>
                  <Col lg={9}>
                    <div className="form-input p-0">
                      <input
                        className="m-0"
                        autoSave="false"
                        required={true}
                        placeholder={t("Retype Your New Password")}
                        type={showPassword2 ? "text" : "password"}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        value={confirmPassword}
                        style={
                          passwordErrors?.password ? { color: "#EB0000" } : {}
                        }
                      />
                      <img
                        onClick={togglePasswordVisibility2}
                        className="pass_hidden"
                        src={showPassword2 ? visible_icon : hidden_icon}
                        alt="hidden_icon"
                      />
                    </div>
                  </Col>
                </Row>
                <hr />
                <div className="security-btns">
                  <button className="signin-btn discard" type="reset">
                    {t("Discard")}
                  </button>
                  <button className="signin-btn" type="submit">
                    {isLoading ? (
                      <Spinner animation="border" role="status"></Spinner>
                    ) : (
                      t("Save")
                    )}
                  </button>
                </div>
              </form>
            </div>
          </Col>
        </Row>
        <Modal show={show}>
          <PasswordSuccess />
        </Modal>
      </Container>
    </div>
  );
};

export default UserSecurity;
