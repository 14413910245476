import React, { useEffect, useState } from "react";
import { Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import GeneralCarousel from "../../Components/GeneralCarousel/GeneralCarousel";
import Heading from "../../Components/Heading/Heading";
import merchant_img from "../../assets/netflix.png";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getPopularAndOnlyOnPaymenthub } from "../../utils/api";
import LoaderSpinner from "../../Components/LoaderSpinner/LoaderSpinner";
import { IoSearch } from "react-icons/io5";
import PaginationControls from "../../Components/PaginationControls";

const ExclusiveMerchants = () => {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  const isArabic = currentLang === "ar";
  const navigate = useNavigate();
  const [exclusiveMerchants, setExclusiveMerchants] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [query, setQuery] = useState("");
  // pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(15);
  const [total, setTotal] = useState(0);
  const [nextPageUrl, setNextPageUrl] = useState(null);
  const [prevPageUrl, setPrevPageUrl] = useState(null);

  const fetchMerchants = async (searchQuery, page) => {
    try {
      const { data } = await getPopularAndOnlyOnPaymenthub(
        "only-payments-hub",
        searchQuery,
        page
      );
      setExclusiveMerchants(data.items);
      setCurrentPage(data.current);
      setPerPage(data.per);
      setTotal(data.total);
      setNextPageUrl(data.next_page_url);
      setPrevPageUrl(data.prev_page_url);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };
  const handleNextPage = () => {
    if (nextPageUrl) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (prevPageUrl) {
      setCurrentPage(currentPage - 1);
    }
  };
  useEffect(() => {
    fetchMerchants(query, currentPage);
  }, [query, currentPage]);
  const handleInputChange = (value) => {
    setQuery(value);
    if (value.length >= 3) {
      fetchMerchants(value, currentPage);
    }
  };

  return (
    <div className="popular-games" dir={isArabic ? "rtl" : "ltr"}>
      <Container fluid>
        <GeneralCarousel />
        <Row>
          <Col lg={12}>
            <div className="d-flex align-items-center gap-4 my-4">
              <Heading title={t("Merchants only on payment HUB")} />
              <InputGroup className="w-50">
                <InputGroup.Text id="btnGroupAddon2" className="rounded-0">
                  <IoSearch />
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  className="rounded-0"
                  placeholder="Search Merchants"
                  aria-label="Search Merchants"
                  aria-describedby="btnGroupAddon2"
                  style={{ backgroundColor: "#f8f9fa" }}
                  value={query}
                  onChange={(e) => handleInputChange(e.target.value)}
                />
              </InputGroup>
            </div>
            <Row className="mt-3">
              {isLoading ? (
                <LoaderSpinner />
              ) : exclusiveMerchants.length > 0 ? (
                exclusiveMerchants.map((merchant) => (
                  <Col md={6} lg={3} key={merchant.id}>
                    <div
                      className="merchant-overlay"
                      onClick={() => navigate(`/merchant/${merchant.id}`)}
                    >
                      <img
                        className="w-100 mb-4 merchant-img"
                        src={merchant.logo ? merchant.logo : merchant_img}
                        alt="merchant_img"
                      />
                      <h5>{merchant.name}</h5>
                    </div>
                  </Col>
                ))
              ) : (
                <p className="empty-products">No Products Found</p>
              )}
            </Row>
            <PaginationControls
              total={total}
              perPage={perPage}
              currentPage={currentPage}
              handlePrevPage={handlePrevPage}
              handleNextPage={handleNextPage}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ExclusiveMerchants;
