// import { initializeApp } from "firebase/app";
// import { getMessaging, getToken, onMessage } from "firebase/messaging";
// import { getFirestore } from 'firebase/firestore';
// import { getAuth } from 'firebase/auth';
// import { getStorage } from 'firebase/storage';




// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const messaging = getMessaging(app);
// // Initialize Firebase


// export { messaging, getToken, onMessage };
// // Initialize Firebase services
// const db = getFirestore(app);
// const auth = getAuth(app);
// const storage = getStorage(app);



// export const onMessageListner = () => {
//   console.log('messaging fun')
//   return new Promise((resolve) => {
//     onMessage(messaging, (payload) => {
//       console.log("onMessage payload" , payload);
//       resolve(payload);
//     });
//   });
// };
// export { db, auth, storage};

// firebase.js
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { toast } from "react-toastify"; // Import toast for notification pop-up
import 'react-toastify/dist/ReactToastify.css';

const firebaseConfig = {
  apiKey: "AIzaSyBFJWqjDqrX7ZIBD5zkj4oXCaNBT-6yS1w",
  authDomain: "funtasy-96aab.firebaseapp.com",
  projectId: "funtasy-96aab",
  storageBucket: "funtasy-96aab.appspot.com",
  messagingSenderId: "626032712152",
  appId: "1:626032712152:web:ceb83589b9975b8978faad",
  measurementId: "G-3N2HDVVZF0",

};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export const requestForToken = async () => {
  console.log('firebase.js')
  try {
    const currentToken = await getToken(messaging, {
      vapidKey:
        "BCLchPmyK9pCpuoN4GdWJsbZ3ZdfXGpDDC3GU-w-0AF6VTVFEXfwZpFXxWBOIGkBsFpyd7xqBoXAPxHyPgrq_G0",
    });
    const token = localStorage.getItem("paymentHub_token");

    if (currentToken) {
      const fcmToken = currentToken;
      console.log("client token : ", fcmToken);
      fetch('https://api.paymenthub.me/api/enduser/fcm', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}` // Send token in Authorization header
        },
        body: JSON.stringify({
          "fcmtoken": fcmToken // Include the token in the request body if necessary
        })
      })

   
    } else {
      console.log("no registeration token available");
    }
  } catch (err) {
    console.log("error while register token", err);
  }
};

onMessage(messaging, (payload) => {
  console.log('Message received. ', payload);
  // Show pop-up when a notification is received
  toast.info(`${payload.notification.title}\n ${payload.notification.body}`, {
    position: "bottom-right",
    autoClose: 50000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

});

requestForToken();
export { messaging , onMessage};