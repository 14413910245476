import React from "react";
import ScrollToTop from "../Components/ScrollToTop";
import MainNavbar from "../Components/Navbar/MainNavbar";
import { Outlet } from "react-router-dom";
import Footer from "../Components/Footer/Footer";
import ProfileSideBar from "../Components/ProfileSideBar/ProfileSideBar";
import { useTranslation } from "react-i18next";

const UserLayout = () => {
  const { i18n } = useTranslation();
  const currentLang = i18n.language;
  const isArabic = currentLang === "ar";
  return (
    <>
      <ScrollToTop />
      <MainNavbar />
      <div className="user-layout d-flex" dir={isArabic ? "rtl" : "ltr"}>
        <ProfileSideBar />
        <div style={{ width: "80%", margin: "0 auto" }}>
          <Outlet />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default UserLayout;
