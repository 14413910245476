import React from "react";
import Modal from "react-bootstrap/Modal";
import "./verticalModal.css";
import VerifyMessage from "../VerifyMessage/VerifyMessage";

const MyVerticallyCenteredModal = (props) => {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="vertical-modal"
    >
      <VerifyMessage {...props} />
    </Modal>
  );
};

export default MyVerticallyCenteredModal;
