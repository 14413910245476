import React from "react";
import "./homeCarousel.css";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
// import carousel_photo from "../../assets/carousel.png";
// import carousel_photo_1 from "../../assets/carousel_1.png";
import { useTranslation } from "react-i18next";

const HomeCarousel = ({ homeBanners }) => {
  const { i18n, t } = useTranslation();
  const currentLang = i18n.language;
  // const isArabic = currentLang === "ar";
  // const backgroundImage = isArabic
  //   ? `url(${carousel_photo_1})`
  //   : `url(${carousel_photo})`;

  return (
    <Swiper
      pagination={{ clickable: true }}
      modules={[Pagination]}
      className="mySwiper mb-3"
    >
      {homeBanners.map((banner, index) => (
        //   <SwiperSlide
        //   key={index}
        //   style={{ backgroundImage: `url(${banner.image})` }}
        // ></SwiperSlide>
        <SwiperSlide key={index}>
          <img src={banner.image} alt="cover" />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default HomeCarousel;
