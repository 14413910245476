import QueryString from "qs";
import axiosInstance from "./axiosConfig";
import axios from "axios";

const token = localStorage.getItem("paymentHub_token");
const url = "https://api.paymenthub.me";
// Signin & reset Password & forget Password & Sign Up dynamic api function
export const AuthLogic = (urlType, formData) => {
  const data = axios.post(`${url}/api/enduser/${urlType}`, formData);
  return data;
};
// logout api function
export let userLogout = async (token) => {
  let { data } = await axios.post(`${url}/api/enduser/logout`, null, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  });
  return data;
};
// verify email api function
export const verifyEmail = (token) => {
  const data = axios.get(`${url}/api/enduser/verify-email/${token}`);
  return data;
};
// get product details api function
export const getProducts = (productName, currency, lang) => {
  const data = axios.get(`${url}/api/enduser/products/${productName}`, {
    params: {
      currency: currency,
      language: lang,
    },
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
  return data;
};
// get similar products api function
export const getSimilarProducts = (productName, currency, lang, token) => {
  const data = axios.get(`${url}/api/enduser/products/${productName}/similar`, {
    params: {
      currency: currency,
      language: lang,
    },
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
  return data;
};
// get all merchants api function
export const getMerchants = (page) => {
  const data = axiosInstance.get(`${url}/api/enduser/merchants`, {
    params: {
      page: page,
    },
    headers: {
      "Content-Type": "application/json",
    },
  });
  return data;
};
// get merchant details api function
export const getMerchantDetails = (merchantId) => {
  const data = axiosInstance.get(`${url}/api/enduser/merchants/${merchantId}`);
  return data;
};
// get all merchant products api function
export const getMerchantsProducts = (merchantId, page, token, currency) => {
  const data = axiosInstance.get(
    `${url}/api/enduser/merchants/${merchantId}/products`,
    {
      params: {
        page: page,
        currency: currency,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return data;
};
// get home api function
export const getHomeData = (homeType, currency, lang, token) => {
  const data = axios.get(`${url}/api/enduser/home/${homeType}`, {
    params: {
      currency: currency,
      language: lang,
    },
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  });
  return data;
};
// get payment methods
export const getPaymentMethods = () => {
  const data = axiosInstance.get(`${url}/api/payments/partnars`);
  return data;
};
// payment api function
export const payment = (productId, paymentRefId, formData, token) => {
  const data = axios.post(
    `${url}/api/payments/${productId}/partnars/${paymentRefId}`,
    formData,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
  return data;
};
// using token in headers
// get user transactions merchants api function
export const getuserMerchants = (token) => {
  const data = axios.get(`${url}/api/enduser/profile/select-merchants`, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  });
  return data;
};
// get user transactions api function
// user query url to send search param
export const getuserTransactions = (
  id,
  amountFrom,
  amountTo,
  creationDate,
  status,
  token,
  page
) => {
  const data = axios.get(`${url}/api/enduser/profile/transactions`, {
    params: {
      merchant_id: id || undefined,
      from_amount: amountFrom || undefined,
      to_amount: amountTo || undefined,
      from: creationDate || undefined,
      status: status || undefined,
      page: page || undefined,
    },
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  });
  return data;
};
// get user transaction invoice
export const getuserTransactionInvoice = (id) => {
  const data = axiosInstance.get(
    `${url}/api/enduser/profile/transactions/${id}`,
    token && {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    }
  );
  return data;
};
// get user customer support tickets
export const getuserCustomerSupport = (
  token,
  searchQuery,
  status,
  reason,
  page
) => {
  const data = axiosInstance.get(`${url}/api/enduser/profile/tickets`, {
    params: {
      search: searchQuery || undefined,
      status: status || undefined,
      reason: reason || undefined,
      page: page || undefined,
    },
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  });
  return data;
};

// get user  ticket comments
export const getuserTicketComments = (token, id) => {
  const data = axiosInstance.get(
    `${url}/api/enduser/profile/tickets/${id}/comments`,
    {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    }
  );
  return data;
};
// get user ticket details
export const getTicketDetails = (token, id) => {
  const data = axiosInstance.get(`${url}/api/enduser/profile/tickets/${id}`, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  });
  return data;
};
// submit ticket api function
export const updateTicket = (token, formData) => {
  const data = axios.post(`${url}/api/enduser/profile/tickets`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + token,
    },
  });
  return data;
};
// get user ticket comments
export const getTicketComments = (token, id) => {
  const data = axiosInstance.get(`${url}/api/enduser/profile/tickets/${id}`, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  });
  return data;
};
// change user password api function
export const updateUserPassword = (formData) => {
  const data = axios.put(
    `${url}/api/enduser/profile/change-password`,
    formData,
    token && {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    }
  );
  return data;
};
// get user profile data api function
export const getUserProfile = (token) => {
  const data = axios.get(`${url}/api/enduser/profile`, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  });
  return data;
};
// update user profile api function
export const updateUserProfile = (formData) => {
 let  token = localStorage.getItem("paymentHub_token");

  const data = axios.post(
    `${url}/api/enduser/profile/update`,
    formData,
    token && {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + token,
      },
    }
  );
  return data;
};
// change user phone number api function
export const updateUserPhone = (updateKind, formData) => {
  const data = axios.put(
    `${url}/api/enduser/profile/${updateKind}`,
    formData,
    token && {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    }
  );
  return data;
};
// send user otp code api function
export const sendUserOtp = (formData) => {
  const data = axios.post(
    `${url}/api/enduser/profile/resend-otp`,
    formData,
    token && {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    }
  );
  return data;
};
// get user wishlist data api function
export const getUserWishlist = (lang, token, currency, page) => {
  const data = axios.get(
    `${url}/api/enduser/profile/wishlist?language=${lang}`,
    {
      params: {
        currency: currency,
        page: page || undefined,
      },
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    }
  );
  return data;
};
// get popular and only on paymenthub api function
export const getPopularAndOnlyOnPaymenthub = (apiEndPoint, search, page) => {
  if (search) {
    const newUrl = `${url}/api/enduser/${apiEndPoint}`;
    const data = axiosInstance.get(`${newUrl}`, {
      params: {
        search: search,
        page: page,
      },
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    });
    return data;
  } else {
    const newUrl = `${url}/api/enduser/${apiEndPoint}`;
    const data = axiosInstance.get(`${newUrl}`, {
      params: {
        search: search,
        page: page,
      },
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    });
    return data;
  }
};
// get popular and only on paymenthub api function
export const getProductReviews = (productId, lang, page) => {
  const data = axios.get(`${url}/api/enduser/products/${productId}/reviews`, {
    params: {
      language: lang,
      page: page,
    },
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  });
  return data;
};
// send user otp code api function
export const addToWishlist = (productId, token) => {
  const data = axios.post(
    `${url}/api/enduser/products/${productId}/wishlist`,
    null,
    {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    }
  );
  return data;
};
// add review api function
export const addReview = (productId, formData, token) => {
  const data = axios.post(
    `${url}/api/enduser/products/${productId}/reviews`,
    formData,
    {
      headers: {
        Authorization: "Bearer" + token,
        "Content-Type": "application/json",
      },
    }
  );
  return data;
};
// delete review api function
export const deleteReview = (productId, reviewId) => {
  const data = axios.delete(
    `${url}/api/enduser/products/${productId}/reviews/${reviewId}`,
    token && {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    }
  );
  return data;
};
// edit review item
export const editReview = (productId, reviewId, formData) => {
  const data = axios.put(
    `${url}/api/enduser/products/${productId}/reviews/${reviewId}`,
    formData,
    token && {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    }
  );
  return data;
};
// get review item api function
export const getReviewItem = (productId, reviewId) => {
  const data = axiosInstance.get(
    `${url}/api/enduser/products/${productId}/reviews/${reviewId}`,
    token && {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    }
  );
  return data;
};

// get popular products page filters
// get categories filter
export const getFilterCategories = (endPointType) => {
  const data = axiosInstance.get(
    `${url}/api/enduser/${endPointType}`,
    token && {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    }
  );
  return data;
};

// get popular products with filters
export const getFilteredProducts = (
  merchantId,
  catId,
  from,
  to,
  searchKey,
  currency,
  page
) => {
  const params = {
    merchant_id: merchantId || undefined,
    category_id: catId || undefined,
    from_price: from || undefined,
    to_price: to || undefined,
    search: searchKey || undefined,
    currency: currency || undefined,
    page: page || undefined,
  };
  const queryParams = QueryString.stringify(params);
  const data = axiosInstance.get(
    `${url}/api/enduser/popular?${queryParams}`,
    token && {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    }
  );
  return data;
};

// get merchant id api function
export const getMerchantPlayerId = (merchantId, token) => {
  const data = axios.get(
    `${url}/api/enduser/merchants/${merchantId}/player-id`,
    {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    }
  );
  return data;
};

// add player id api function
export const addMerchantId = (productId, formData, token) => {
  const data = axios.post(
    `${url}/api/enduser/merchants/${productId}/player-id`,
    formData,
    {
      headers: {
        Authorization: "Bearer" + token,
        "Content-Type": "application/json",
      },
    }
  );
  return data;
};

// add product player_id api function
export const addProductPlayerId = (productId, formData, token) => {
  const data = axios.post(
    `${url}/api/enduser/products/${productId}/player-id`,
    formData,
    token && {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    }
  );
  return data;
};

export const refreshToken = (token) => {
  const data = axios.post(`${url}/api/enduser/refresh`, null, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  });
  return data;
};
// get cmd data api function
export const getCmsData = (type, token) => {
  const data = axios.get(`${url}/api/enduser/site-settings/${type}`, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  });
  return data;
};

export const getNotifications =async (token) => {
  const data = axios.get(`${url}/api/enduser/notifications`, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  });
  return data;
};
export const sendFcmToken = (token) => {
  const data = axios.post(`${url}/api/enduser/fcm`, null, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  });
  return data;
};