import usd_icon from "../assets/USD.png";
export const currencies = [
  {
    code: "USD",
    name: "United States Dollar",
    symbol: "$",
    flag: usd_icon,
  },
  {
    code: "ARS",
    name: "Argentine Peso",
    symbol: "$",
    flag: "https://flagcdn.com/ar.svg",
  },
  {
    code: "AED",
    name: "UAE Dirham",
    symbol: "AED",
    flag: "https://flagcdn.com/ae.svg",
  },
  {
    code: "AFN",
    name: "Afghan Afghani",
    symbol: "AFN",
    flag: "https://flagcdn.com/af.svg",
  },
  {
    code: "ALL",
    name: "Albanian Lek",
    symbol: "L",
    flag: "https://flagcdn.com/al.svg",
  },
  {
    code: "AMD",
    name: "Armenian Dram",
    symbol: "AMD",
    flag: "https://flagcdn.com/am.svg",
  },
  {
    code: "ANG",
    name: "Netherlands Antillian Guilder",
    symbol: "ƒ",
    flag: "https://flagcdn.com/nl.svg",
  },
  {
    code: "AOA",
    name: "Angolan Kwanza",
    symbol: "Kz",
    flag: "https://flagcdn.com/ao.svg",
  },
  {
    code: "AUD",
    name: "Australian Dollar",
    symbol: "$",
    flag: "https://flagcdn.com/au.svg",
  },
  {
    code: "AWG",
    name: "Aruban Florin",
    symbol: "ƒ",
    flag: "https://flagcdn.com/aw.svg",
  },
  {
    code: "AZN",
    name: "Azerbaijani Manat",
    symbol: "₼",
    flag: "https://flagcdn.com/az.svg",
  },
  {
    code: "BAM",
    name: "Bosnia and Herzegovina Convertible Mark",
    symbol: "KM",
    flag: "https://flagcdn.com/ba.svg",
  },
  {
    code: "BBD",
    name: "Barbados Dollar",
    symbol: "$",
    flag: "https://flagcdn.com/bb.svg",
  },
  {
    code: "BDT",
    name: "Bangladeshi Taka",
    symbol: "৳",
    flag: "https://flagcdn.com/bd.svg",
  },
  {
    code: "BGN",
    name: "Bulgarian Lev",
    symbol: "лв",
    flag: "https://flagcdn.com/bg.svg",
  },
  {
    code: "BHD",
    name: "Bahraini Dinar",
    symbol: "BHD",
    flag: "https://flagcdn.com/bh.svg",
  },
  {
    code: "BIF",
    name: "Burundian Franc",
    symbol: "FBu",
    flag: "https://flagcdn.com/bi.svg",
  },
  {
    code: "BMD",
    name: "Bermudian Dollar",
    symbol: "$",
    flag: "https://flagcdn.com/bm.svg",
  },
  {
    code: "BND",
    name: "Brunei Dollar",
    symbol: "$",
    flag: "https://flagcdn.com/bn.svg",
  },
  {
    code: "BOB",
    name: "Bolivian Boliviano",
    symbol: "Bs.",
    flag: "https://flagcdn.com/bo.svg",
  },
  {
    code: "BRL",
    name: "Brazilian Real",
    symbol: "R$",
    flag: "https://flagcdn.com/br.svg",
  },
  {
    code: "BSD",
    name: "Bahamian Dollar",
    symbol: "$",
    flag: "https://flagcdn.com/bs.svg",
  },
  {
    code: "BTN",
    name: "Bhutanese Ngultrum",
    symbol: "Nu.",
    flag: "https://flagcdn.com/bt.svg",
  },
  {
    code: "BWP",
    name: "Botswana Pula",
    symbol: "P",
    flag: "https://flagcdn.com/bw.svg",
  },
  {
    code: "BYN",
    name: "Belarusian Ruble",
    symbol: "Br",
    flag: "https://flagcdn.com/by.svg",
  },
  {
    code: "BZD",
    name: "Belize Dollar",
    symbol: "$",
    flag: "https://flagcdn.com/bz.svg",
  },
  {
    code: "CAD",
    name: "Canadian Dollar",
    symbol: "$",
    flag: "https://flagcdn.com/ca.svg",
  },
  {
    code: "CDF",
    name: "Congolese Franc",
    symbol: "FC",
    flag: "https://flagcdn.com/cd.svg",
  },
  {
    code: "CHF",
    name: "Swiss Franc",
    symbol: "CHF",
    flag: "https://flagcdn.com/ch.svg",
  },
  {
    code: "CLP",
    name: "Chilean Peso",
    symbol: "$",
    flag: "https://flagcdn.com/cl.svg",
  },
  {
    code: "CNY",
    name: "Chinese Renminbi",
    symbol: "¥",
    flag: "https://flagcdn.com/cn.svg",
  },
  {
    code: "COP",
    name: "Colombian Peso",
    symbol: "$",
    flag: "https://flagcdn.com/co.svg",
  },
  {
    code: "CRC",
    name: "Costa Rican Colon",
    symbol: "₡",
    flag: "https://flagcdn.com/cr.svg",
  },
  {
    code: "CUP",
    name: "Cuban Peso",
    symbol: "$",
    flag: "https://flagcdn.com/cu.svg",
  },
  {
    code: "CVE",
    name: "Cape Verdean Escudo",
    symbol: "$",
    flag: "https://flagcdn.com/cv.svg",
  },
  {
    code: "CZK",
    name: "Czech Koruna",
    symbol: "Kč",
    flag: "https://flagcdn.com/cz.svg",
  },
  {
    code: "DJF",
    name: "Djiboutian Franc",
    symbol: "Fdj",
    flag: "https://flagcdn.com/dj.svg",
  },
  {
    code: "DKK",
    name: "Danish Krone",
    symbol: "kr",
    flag: "https://flagcdn.com/dk.svg",
  },
  {
    code: "DOP",
    name: "Dominican Peso",
    symbol: "$",
    flag: "https://flagcdn.com/do.svg",
  },
  {
    code: "DZD",
    name: "Algerian Dinar",
    symbol: "DZD",
    flag: "https://flagcdn.com/dz.svg",
  },
  {
    code: "EGP",
    name: "Egyptian Pound",
    symbol: "EGP",
    flag: "https://flagcdn.com/eg.svg",
  },
  {
    code: "ERN",
    name: "Eritrean Nakfa",
    symbol: "Nfk",
    flag: "https://flagcdn.com/er.svg",
  },
  {
    code: "ETB",
    name: "Ethiopian Birr",
    symbol: "Br",
    flag: "https://flagcdn.com/et.svg",
  },
  {
    code: "EUR",
    name: "Euro",
    symbol: "€",
    flag: "https://flagcdn.com/eu.svg",
  },
  {
    code: "FJD",
    name: "Fiji Dollar",
    symbol: "$",
    flag: "https://flagcdn.com/fj.svg",
  },
  {
    code: "FKP",
    name: "Falkland Islands Pound",
    symbol: "£",
    flag: "https://flagcdn.com/fk.svg",
  },
  {
    code: "FOK",
    name: "Faroese Króna",
    symbol: "kr",
    flag: "https://flagcdn.com/fo.svg",
  },
  {
    code: "GBP",
    name: "Pound Sterling",
    symbol: "£",
    flag: "https://flagcdn.com/gb.svg",
  },
  {
    code: "GEL",
    name: "Georgian Lari",
    symbol: "₾",
    flag: "https://flagcdn.com/ge.svg",
  },
  {
    code: "GGP",
    name: "Guernsey Pound",
    symbol: "£",
    flag: "https://flagcdn.com/gg.svg",
  },
  {
    code: "GHS",
    name: "Ghanaian Cedi",
    symbol: "₵",
    flag: "https://flagcdn.com/gh.svg",
  },
  {
    code: "GIP",
    name: "Gibraltar Pound",
    symbol: "£",
    flag: "https://flagcdn.com/gi.svg",
  },
  {
    code: "GMD",
    name: "Gambian Dalasi",
    symbol: "D",
    flag: "https://flagcdn.com/gm.svg",
  },
  {
    code: "GNF",
    name: "Guinean Franc",
    symbol: "FG",
    flag: "https://flagcdn.com/gn.svg",
  },
  {
    code: "GTQ",
    name: "Guatemalan Quetzal",
    symbol: "Q",
    flag: "https://flagcdn.com/gt.svg",
  },
  {
    code: "GYD",
    name: "Guyanese Dollar",
    symbol: "$",
    flag: "https://flagcdn.com/gy.svg",
  },
  {
    code: "HKD",
    name: "Hong Kong Dollar",
    symbol: "$",
    flag: "https://flagcdn.com/hk.svg",
  },
  {
    code: "HNL",
    name: "Honduran Lempira",
    symbol: "L",
    flag: "https://flagcdn.com/hn.svg",
  },
  {
    code: "HRK",
    name: "Croatian Kuna",
    symbol: "kn",
    flag: "https://flagcdn.com/hr.svg",
  },
  {
    code: "HTG",
    name: "Haitian Gourde",
    symbol: "G",
    flag: "https://flagcdn.com/ht.svg",
  },
  {
    code: "HUF",
    name: "Hungarian Forint",
    symbol: "Ft",
    flag: "https://flagcdn.com/hu.svg",
  },
  {
    code: "IDR",
    name: "Indonesian Rupiah",
    symbol: "Rp",
    flag: "https://flagcdn.com/id.svg",
  },
  {
    code: "IMP",
    name: "Isle of Man Pound",
    symbol: "£",
    flag: "https://flagcdn.com/im.svg",
  },
  {
    code: "INR",
    name: "Indian Rupee",
    symbol: "₹",
    flag: "https://flagcdn.com/in.svg",
  },
  {
    code: "IQD",
    name: "Iraqi Dinar",
    symbol: "IQD",
    flag: "https://flagcdn.com/iq.svg",
  },
  {
    code: "IRR",
    name: "Iranian Rial",
    symbol: "IRR",
    flag: "https://flagcdn.com/ir.svg",
  },
  {
    code: "ISK",
    name: "Icelandic Króna",
    symbol: "kr",
    flag: "https://flagcdn.com/is.svg",
  },
  {
    code: "JEP",
    name: "Jersey Pound",
    symbol: "£",
    flag: "https://flagcdn.com/je.svg",
  },
  {
    code: "JMD",
    name: "Jamaican Dollar",
    symbol: "$",
    flag: "https://flagcdn.com/jm.svg",
  },
  {
    code: "JOD",
    name: "Jordanian Dinar",
    symbol: "JOD",
    flag: "https://flagcdn.com/jo.svg",
  },
  {
    code: "JPY",
    name: "Japanese Yen",
    symbol: "¥",
    flag: "https://flagcdn.com/jp.svg",
  },
  {
    code: "KES",
    name: "Kenyan Shilling",
    symbol: "Sh",
    flag: "https://flagcdn.com/ke.svg",
  },
  {
    code: "KGS",
    name: "Kyrgyzstani Som",
    symbol: "с",
    flag: "https://flagcdn.com/kg.svg",
  },
  {
    code: "KHR",
    name: "Cambodian Riel",
    symbol: "៛",
    flag: "https://flagcdn.com/kh.svg",
  },
  {
    code: "KID",
    name: "Kiribati Dollar",
    symbol: "$",
    flag: "https://flagcdn.com/ki.svg",
  },
  {
    code: "KMF",
    name: "Comorian Franc",
    symbol: "CF",
    flag: "https://flagcdn.com/km.svg",
  },
  {
    code: "KRW",
    name: "South Korean Won",
    symbol: "₩",
    flag: "https://flagcdn.com/kr.svg",
  },
  {
    code: "KWD",
    name: "Kuwaiti Dinar",
    symbol: "KWD",
    flag: "https://flagcdn.com/kw.svg",
  },
  {
    code: "KYD",
    name: "Cayman Islands Dollar",
    symbol: "$",
    flag: "https://flagcdn.com/ky.svg",
  },
  {
    code: "KZT",
    name: "Kazakhstani Tenge",
    symbol: "₸",
    flag: "https://flagcdn.com/kz.svg",
  },
  {
    code: "LAK",
    name: "Lao Kip",
    symbol: "₭",
    flag: "https://flagcdn.com/la.svg",
  },
  {
    code: "LBP",
    name: "Lebanese Pound",
    symbol: "LBP",
    flag: "https://flagcdn.com/lb.svg",
  },
  {
    code: "LKR",
    name: "Sri Lankan Rupee",
    symbol: "Rs",
    flag: "https://flagcdn.com/lk.svg",
  },
  {
    code: "LRD",
    name: "Liberian Dollar",
    symbol: "$",
    flag: "https://flagcdn.com/lr.svg",
  },
  {
    code: "LSL",
    name: "Lesotho Loti",
    symbol: "L",
    flag: "https://flagcdn.com/ls.svg",
  },
  {
    code: "LYD",
    name: "Libyan Dinar",
    symbol: "LYD",
    flag: "https://flagcdn.com/ly.svg",
  },
  {
    code: "MAD",
    name: "Moroccan Dirham",
    symbol: "MAD",
    flag: "https://flagcdn.com/ma.svg",
  },
  {
    code: "SHP",
    name: "Saint Helena Pound",
    symbol: "£",
    flag: "https://flagcdn.com/sh.svg",
  },
  {
    code: "SLL",
    name: "Sierra Leonean Leone",
    symbol: "Le",
    flag: "https://flagcdn.com/sl.svg",
  },
  {
    code: "SOS",
    name: "Somali Shilling",
    symbol: "Sh",
    flag: "https://flagcdn.com/so.svg",
  },
  {
    code: "SRD",
    name: "Surinamese Dollar",
    symbol: "$",
    flag: "https://flagcdn.com/sr.svg",
  },
  {
    code: "SSP",
    name: "South Sudanese Pound",
    symbol: "£",
    flag: "https://flagcdn.com/ss.svg",
  },
  {
    code: "STN",
    name: "São Tomé and Príncipe Dobra",
    symbol: "Db",
    flag: "https://flagcdn.com/st.svg",
  },
  {
    code: "SYP",
    name: "Syrian Pound",
    symbol: "£",
    flag: "https://flagcdn.com/sy.svg",
  },
  {
    code: "SZL",
    name: "Swazi Lilangeni",
    symbol: "E",
    flag: "https://flagcdn.com/sz.svg",
  },
  {
    code: "THB",
    name: "Thai Baht",
    symbol: "฿",
    flag: "https://flagcdn.com/th.svg",
  },
  {
    code: "TJS",
    name: "Tajikistani Somoni",
    symbol: "ЅМ",
    flag: "https://flagcdn.com/tj.svg",
  },
  {
    code: "TMT",
    name: "Turkmenistan Manat",
    symbol: "T",
    flag: "https://flagcdn.com/tm.svg",
  },
  {
    code: "TND",
    name: "Tunisian Dinar",
    symbol: "TND",
    flag: "https://flagcdn.com/tn.svg",
  },
  {
    code: "TOP",
    name: "Tongan Paʻanga",
    symbol: "T$",
    flag: "https://flagcdn.com/to.svg",
  },
  {
    code: "TRY",
    name: "Turkish Lira",
    symbol: "₺",
    flag: "https://flagcdn.com/tr.svg",
  },
  {
    code: "TTD",
    name: "Trinidad and Tobago Dollar",
    symbol: "$",
    flag: "https://flagcdn.com/tt.svg",
  },
  {
    code: "TVD",
    name: "Tuvaluan Dollar",
    symbol: "$",
    flag: "https://flagcdn.com/tv.svg",
  },
  {
    code: "TWD",
    name: "New Taiwan Dollar",
    symbol: "$",
    flag: "https://flagcdn.com/tw.svg",
  },
  {
    code: "TZS",
    name: "Tanzanian Shilling",
    symbol: "Sh",
    flag: "https://flagcdn.com/tz.svg",
  },
  {
    code: "UAH",
    name: "Ukrainian Hryvnia",
    symbol: "₴",
    flag: "https://flagcdn.com/ua.svg",
  },
  {
    code: "UGX",
    name: "Ugandan Shilling",
    symbol: "Sh",
    flag: "https://flagcdn.com/ug.svg",
  },
  {
    code: "UYU",
    name: "Uruguayan Peso",
    symbol: "$",
    flag: "https://flagcdn.com/uy.svg",
  },
  {
    code: "UZS",
    name: "Uzbekistani Som",
    symbol: "лв",
    flag: "https://flagcdn.com/uz.svg",
  },
  {
    code: "VES",
    name: "Venezuelan Bolívar Soberano",
    symbol: "Bs.",
    flag: "https://flagcdn.com/ve.svg",
  },
  {
    code: "VND",
    name: "Vietnamese Đồng",
    symbol: "₫",
    flag: "https://flagcdn.com/vn.svg",
  },
  {
    code: "VUV",
    name: "Vanuatu Vatu",
    symbol: "Vt",
    flag: "https://flagcdn.com/vu.svg",
  },
  {
    code: "WST",
    name: "Samoan Tālā",
    symbol: "T",
    flag: "https://flagcdn.com/ws.svg",
  },
  {
    code: "XAF",
    name: "Central African CFA Franc",
    symbol: "Fr",
    flag: "https://flagcdn.com/ax.svg",
  },
  {
    code: "XCD",
    name: "East Caribbean Dollar",
    symbol: "$",
    flag: "https://flagcdn.com/e2.svg",
  },
  {
    code: "XOF",
    name: "West African CFA Franc",
    symbol: "Fr",
    flag: "https://flagcdn.com/cf.svg",
  },
  {
    code: "XPF",
    name: "CFP Franc",
    symbol: "Fr",
    flag: "https://flagcdn.com/pf.svg",
  },
  {
    code: "YER",
    name: "Yemeni Rial",
    symbol: "YER",
    flag: "https://flagcdn.com/ye.svg",
  },
  {
    code: "ZAR",
    name: "South African Rand",
    symbol: "R",
    flag: "https://flagcdn.com/za.svg",
  },
  {
    code: "ZMW",
    name: "Zambian Kwacha",
    symbol: "ZK",
    flag: "https://flagcdn.com/zm.svg",
  },
  {
    code: "ZWL",
    name: "Zimbabwean Dollar",
    symbol: "$",
    flag: "https://flagcdn.com/zw.svg",
  },
];
