import React, { useRef } from "react";
import "./topMerchant.css";
import { useTranslation } from "react-i18next";
import { FcNext, FcPrevious } from "react-icons/fc";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";

const TopMerchant = ({ topMerchant }) => {
  const { i18n } = useTranslation();
  const currentLang = i18n.language;
  const isArabic = currentLang === "ar";
  const navigate = useNavigate();
  const sliderRef = useRef(null);

  const next = () => {
    sliderRef.current.slickNext();
  };
  var settings = {
    infinite: true,
    speed: 1500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const prev = () => {
    sliderRef.current.slickPrev();
  };
  return (
    <div className="slider-container mb-5" dir={isArabic ? "rtl" : "ltr"}>
      <div>
        <Slider ref={sliderRef} {...settings}>
          {topMerchant.map((product) => (
            <div
              className="merchant-overlay"
              onClick={() => navigate(`/merchant/${product.id}`)}
              key={product.id}
            >
              <img key={product.id} src={product.logo} alt="payment" />
              <h5>{product.name}</h5>
            </div>
          ))}
        </Slider>
        <div className="text-center d-flex align-items-center justify-content-center gap-3">
          {isArabic ? (
            <FcNext
              onClick={next}
              style={{ cursor: "pointer", fontSize: "25px" }}
            />
          ) : (
            <FcPrevious
              onClick={prev}
              style={{ cursor: "pointer", fontSize: "25px" }}
            />
          )}
          {isArabic ? (
            <FcPrevious
              onClick={prev}
              style={{ cursor: "pointer", fontSize: "25px" }}
            />
          ) : (
            <FcNext
              onClick={next}
              style={{ cursor: "pointer", fontSize: "25px" }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default TopMerchant;
