import React, { useState } from "react";
import email_icon from "../../assets/email.png";
import { AuthLogic } from "../../utils/api";
import FormSocialMedia from "../FormSocialMedia/FormSocialMedia";
import FormsHeading from "../FormsHeading/FormsHeading";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const ResetPassword = () => {
  const { t } = useTranslation();
  const [errMsg, setErrMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [email, setEmail] = useState("");
  const handleResetPassword = async (e) => {
    e.preventDefault();
    let formData = {
      email: email,
    };
    try {
      await AuthLogic("forgot-password", formData);
      setSuccessMsg("Email Has Sent Successfully");
      setErrMsg("");
      setEmail("");
    } catch (error) {
      setErrMsg(
        "The Email You Entered Is Invalid Please Enter The Correct Email"
      );
      setSuccessMsg("");
    }
  };
  return (
    <Container>
      <Row>
        <Col sm={0} md={2} lg={3} xxl={4}></Col>
        <Col sm={12} md={8} lg={6} xl={5} xxl={4}>
          <div
            className="signin-form"
            dir={localStorage.getItem("i18nextLng") === "ar" ? "rtl" : "ltr"}
          >
            <FormsHeading
              heading={t("Reset Password")}
              subHeading={t(
                "Enter Your Registration Email To Receive The Verification Link , Then Follow The Link To Change Your Password ."
              )}
            />
            {successMsg ? (
              <div className="alert alert-success text-center">
                {successMsg}
              </div>
            ) : (
              ""
            )}
            <form onSubmit={handleResetPassword}>
              <div className="input-group mb-2">
                <label htmlFor="email">{t("Email")}</label>
                <div
                  className="form-input"
                  style={
                    errMsg
                      ? { border: "2px solid #FF2D2D" }
                      : { border: "2px solid #898990" }
                  }
                >
                  <img src={email_icon} alt="email_icon" />
                  <input
                    style={errMsg ? { color: "#FF2D2D" } : { color: "#898990" }}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required={true}
                    id="email"
                    type="email"
                    placeholder={t("Enter Your Email")}
                  />
                </div>
                <span className="text-danger mt-2">{errMsg}</span>
              </div>
              <button className="signin-btn" type="submit">
                {t("Send Code")}
              </button>
            </form>
            <FormSocialMedia showNavigate={false} />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ResetPassword;
